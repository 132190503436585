import {GlobalActionTypes, GlobalActions, ServerError} from './actionTypes';
import {Props as PromptModalProps} from 'src/Components/ModalPrompt';

export const serverErrors = ({config, errors, combinedMessage, read = false}: ServerError): GlobalActions => ({
    type: GlobalActionTypes.SERVER_ERRORS,
    payload: {
        config,
        errors,
        combinedMessage,
        read,
    },
});

export const clearServerErrors = (key?: string): GlobalActions => {
    return {
        type: GlobalActionTypes.CLEAR_SERVER_ERRORS,
        payload: {key},
    };
};

export const markServerErrorRead = (key?: string): GlobalActions => {
    return {
        type: GlobalActionTypes.CLEAR_SERVER_ERRORS,
        payload: {key},
    };
};


export const promptModalOptions = (promptModalOptions: PromptModalProps): GlobalActions => ({
    type: GlobalActionTypes.PROMPT_MODAL_OPTIONS,
    payload: {promptModalOptions},
});
