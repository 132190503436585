import {useFormik, FormikHelpers} from 'formik';
import React from 'react';
import * as Yup from 'yup';
import {useDispatch} from 'react-redux';
import {updateStore, validateKeepmail} from 'src/api/Store/api-store';
import {updateStore as updateStoreAdmin} from 'src/api/Admin/Store/api-admin-store';
import Button from 'src/Components/Button';
import {Switch} from 'antd';
import Input from 'src/Components/Input';
import Modal from 'src/Components/Modal';
import {getStores} from 'src/redux/stores/actions';
import {getStoreStatus} from 'src/redux/storeStatus/actions';
import {Store} from 'src/redux/stores/actionTypes';
import {ID, genericFn} from 'src/Types/CommonTypes';
import _ from 'lodash';
import tipIcon from 'src/assets/icons/tip-hex.svg';
import PopIcon from 'src/Components/PopIcon';
import './styles.sass';
import Loading from 'src/Components/Loading';

interface Props {
    isModalOpen: boolean
    onModalClose: genericFn
    store: Store
    canUseKeepMail: boolean
    isAdmin?: boolean
}

const EditStoreModal: React.FC<Props> = ({isModalOpen, onModalClose, store, canUseKeepMail = false, isAdmin = false}) => {
    const dispatch = useDispatch();

    const checkKeepmailAddressInUse = async(storeId: ID, email: string) => {
        const {mailboxUsed} = await validateKeepmail(storeId, email);
        return mailboxUsed;
    };

    const validateSchema = () => Yup.object().shape({
        title: Yup.string().required(),
    });

    const defaultSchema = validateSchema();

    const {
        handleSubmit,
        errors,
        touched,
        isSubmitting,
        getFieldProps,
        resetForm,
        setFieldValue,
        values,
    } = useFormik({
        initialValues: {
            title: store && store.title,
            assetMailbox: store && (_.get(store, 'assetMailbox') || _.kebabCase(store.title)),
            useKeepMail: _.get(store, 'useKeepMail', false),
        },
        onSubmit: async(values, actions: FormikHelpers<any>) => {
            try {
                if (values.useKeepMail
                    && await checkKeepmailAddressInUse(_.get(store, 'id'), values.assetMailbox)
                ) {
                    actions.setSubmitting(false);
                    throw {errors: [{message: 'This KeepMail address is already in use, please try another address.', field: 'assetMailbox'}]};
                } else {
                    if (!values.useKeepMail && _.isEmpty(values.assetMailbox)) {
                        values.assetMailbox = null;
                    }
                    if (isAdmin) {
                        await updateStoreAdmin(_.get(store, 'id'), values);
                    } else {
                        await updateStore(_.get(store, 'id'), values);
                    }
                    actions.setSubmitting(false);
                    dispatch(getStores());
                    dispatch(getStoreStatus());
                }
                onModalClose(true);
            } catch (e: any) {
                for (const error of e.errors) {
                    //<RootError> does most of the heavy lifting, but we need to catch the error from checkKeepmailAddressInUse
                    actions.setFieldError(_.camelCase(error.field) || 'title', error.message);
                }
            }
        },
        validationSchema: defaultSchema,
    });

    return store ? (
        <form onSubmit={handleSubmit} id='edit-store'>
            <Modal
                className='edit-store-modal'
                open={isModalOpen}
                title='Edit Store'
                width={600}
                footer={false}
                onCancel={() => {
                    onModalClose();
                    resetForm();
                }}>
                <div className='modal-body'>
                    <label htmlFor='title' className='form-label'>
                        What&apos;s the name of your company or team?
                    </label>
                    <Input
                        errors={errors.title && touched.title && errors.title}
                        className='input'
                        required
                        autoFocus
                        placeholder='Store Name'
                        {...getFieldProps('title')} />
                    {canUseKeepMail && (
                        <>
                            <label htmlFor='useKeepMail' className='form-label use-keepmail-label'>
                                KeepMail
                            </label>
                            <PopIcon
                                className='keepmail-info btn-tip'
                                popClassName='keepmail-info-pop'
                                type='tip'
                                content='KeepMail gives your store an email address to have files sent directly into BrandKeep. Once you have set up your KeepMail address, use it by having emails sent to it and BrandKeep will automatically take any files 25 MB or less and place them in the primary asset dashboard or in a Brand Card if coming from a contact with KeepMail automation turned on.'
                                origin={<img src={tipIcon} /> } />
                            <Switch
                                className='use-keepmail-toggle'
                                onClick={(checked: boolean) => {
                                    setFieldValue('useKeepMail', checked);
                                }}
                                checked={Boolean(values.useKeepMail)} />
                            <label htmlFor='assetMailbox' className='form-label'>
                                Keepmail Address
                            </label>
                            <div className='keepmail-address'>
                                <Input
                                    errors={errors.assetMailbox && touched.assetMailbox && errors.assetMailbox}
                                    className='input'
                                    disabled={!values.useKeepMail}
                                    required={Boolean(values.useKeepMail)}
                                    placeholder={store && _.kebabCase(store.title)}
                                    {...getFieldProps('assetMailbox')} />
                                <span className='domain-name'>
                                    {_.get(store, 'assetMailboxSuffix')}
                                </span>
                            </div>
                        </>
                    )}
                </div>
                <div className='modal-footer'>
                    <div
                        className='cancel-button'
                        onClick={() => {
                            onModalClose();
                            resetForm();
                        }}>
                        Cancel
                    </div>
                    <Button
                        type='submit'
                        className='btn-secondary submit-button'
                        form='edit-store'
                        disabled={isSubmitting || !_.isEmpty(errors)}>
                        {isSubmitting && (
                            <Loading inline />
                        )}
                        Update
                    </Button>
                </div>
            </Modal>
        </form>
    )
        : null;
};

export default EditStoreModal;
