import React, {useState, useEffect, useContext} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate, useLocation, useParams} from 'react-router-dom';
import {RootState} from 'src/redux/rootReducer';
import './styles.sass';
import _ from 'lodash';

//icons
import {PlusOutlined} from '@ant-design/icons';
import {Kabob, Note} from 'src/lib/svg-icon-helper';
import AssetLeftConfetti from '/src/assets/images/confetti/asset-left.png';
import AssetRightConfetti from '/src/assets/images/confetti/asset-right.png';
import infoIcon from 'src/assets/icons/info.svg';
import tipIcon from 'src/assets/icons/tip-hex.svg';

//components
import {Divider, Space, Typography, message} from 'antd';
import AssetPreview from 'src/Components/AssetPreview';
import BrandRenderer from 'src/Components/BrandRenderer';
import Button from 'src/Components/Button';
import ControlHeader from 'src/Components/ControlHeader';
import DropdownBtnCreateAsset from 'src/Components/DropdownBtnCreateAsset';
import Input from 'src/Components/Input';
import Loading from 'src/Components/Loading';
import Modal from 'src/Components/Modal';
import PopIcon from 'src/Components/PopIcon';
import {Prompt} from 'src/Components/ModalPrompt';
import Select from 'src/Components/Select';
import Table, {SortBy} from 'src/Components/Table';
import TagsEditable from 'src/Components/TagsEditable';
import TagsRenderer from 'src/Components/TagsRenderer';
import NoResults from 'src/Components/NoResults';
import EmptyState from 'src/Components/EmptyState';

//apis
import useAssetsApi, {MultiEditAsset} from 'src/Views/Store/Assets/useAssetsApi';
import {StoreInfoApi, getStoreRolesInfo} from 'src/api/StoreRole/api-store-role';

//types
import {NormalizedTag, ApiTag} from 'src/Types/Tags/types';
import {ApiAsset, duplicateAsset as duplicateAssetApi, getLockedAssetsStatus} from 'src/api/Assets/api-asset';
import {AlignType, ID, ReactEvent, ReactTextareaEvent, ReactAnchorEvent} from 'src/Types/CommonTypes';
import {BrandCard} from 'src/api/BrandCard/api-brand-card';
import {ApiWorkspace} from 'src/api/Workspace/api-workspace';

//libs
import {Filters, removeFilterFactory} from 'src/lib/filter-helper';
import {displayFileSize, _get, invisibleCharacter, classnames, buildUrlFromPartial, textToClipboard} from 'src/utils/general';
import {getContactDisplayBySource} from 'src/lib/asset-helper';
import useWindow from 'src/hooks/useWindow';
import Onboarding from 'src/Components/Onboarding';
import {formatDate} from 'src/lib/date-helper';
import useDocumentTitle from 'src/hooks/useDocumentTitle';
import {NewContentContext} from 'src/hooks/useNewContent';
import {StoreStatus} from 'src/redux/storeStatus/actionTypes';
import {AuthRoutes} from 'src/constants/userRoles';
import {buildQueryParams, buildUrl, getQueryParams} from 'src/lib/url';
import DropdownButton from 'src/Components/DropdownButton';
import getFileType, {SUPPORTED_FILE_TYPES, getFileExtension, getFileNoExtension} from 'src/lib/file-type-helper';
import {nameFormatter} from 'src/lib/name-helper';

const unchangedMultiSelectId = `(Several)${invisibleCharacter}`;

/*********************************************

 ***** THIS COMPONENT IS MIRRORED SOMEWHAT CLOSELY BY WorkspaceAssets COMPONENT *****
 ***** ANY UPDATE MAY NEED TO BE IMPLEMENTED THERE ALSO! *****

*********************************************/

const StoreAssets = (): JSX.Element => {
    const {storeId} = useParams<{storeId: any}>();
    const {assetId} = useParams<{assetId: any}>();
    const location = useLocation();
    const navigate = useNavigate();
    const {
        assets,
        allAssets,
        brandTags,
        refetchAssets,
        allBrands,
        isLoading,
        deleteAssets,
        textSearchValue,
        setTextSearchValue,
        tags,
        workspaces,
        saveAssetsApi,
        filters,
        filterData,
        setFilterData,
        filterValues,
        setFilterValues,
        downloadAll,
        lockedAssets,
        handleLockedAssets,
        silentlyRefreshAssets,
    } = useAssetsApi(storeId);
    const [multiSelectItems, setMultiSelectItems] = useState<ApiAsset[]>([]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [assetName, setAssetName] = useState<string>('');
    const [assetLink, setAssetLink] = useState<string>();
    const [assetBrand, setSelectedBrand] = useState<BrandCard>();
    const [assetWorkspace, setSelectedWorkspace] = useState<Record<'title', string>>();
    const [assetTags, setSelectedAssetTags] = useState<NormalizedTag[]>();
    const [assetNotes, setAssetNotes] = useState<string>('');
    const [assetToEdit, setAssetToEdit] = useState<ApiAsset>();
    const [newWorkspace, setNewWorkspace] = useState<string>('');
    const [isSubmittingEditAssets, setIsSubmittingEditAssets] = useState<boolean>(false);
    const [isDeletingAsset, setIsDeletingAsset] = useState<boolean>(false);
    const shouldMultiEdit = multiSelectItems.length > 1 && !assetToEdit;
    const [workspacesCompact, setWorkspacesCompact] = useState<Record<'title', string>[]>([]);
    const [assetToPreview, setAssetToPreview] = useState<ApiAsset>();
    const {windowIsTiny, windowIsSmall} = useWindow();
    const [userStoreInfo, setUserStoreInfo] = useState<StoreInfoApi>();
    const storeStatuses = useSelector((state: RootState) => state.storeStatus.storeStatus);
    const currentStoreStatus = storeStatuses?.find((status: StoreStatus) => `${status.id}` === storeId);
    const userId = useSelector((state: RootState) => state.auth.id);
    const readOnly = !_get(userStoreInfo, 'role') || _get(userStoreInfo, 'role') === 'retail_read_only';
    const [sortBy, setSortBy] = useState<SortBy>({
        key: 'updatedAt',
        title: 'Modified',
        isReversed: true,
    });
    const [selectedRowKeys, setSelectedRowKeys] = useState<ID[]>([]);
    const {newContent} = useContext(NewContentContext);

    const [newAssetName, setNewAssetName] = useState<string>('');
    const [duplicateAsset, setDuplicateAsset] = useState<ApiAsset>();
    const [showDuplicateAssetModal, setShowDuplicateAssetModal] = useState(false);
    const [isSubmittingDuplicate, setIsSubmittingDuplicate] = useState(false);

    useDocumentTitle('Assets');

    const handleWorkspacesForDropdown = () => {
        const workspacesByTitle = _.groupBy(workspaces, 'title');
        const combinedWorkspaces = _.map(workspacesByTitle, (workspaces: ApiWorkspace[]) => {
            return {
                title: workspaces[0].title,
            };
        });
        setWorkspacesCompact(_.sortBy(combinedWorkspaces, (workspace) => _.toLower(workspace.title)));
    };

    const handleBrandCardClose = () => {
        //clear out location state
        window.history.replaceState({}, document.title);
    };

    const previewAssetIfNotLocked = async(asset: ApiAsset) => {
        const assetId = _.toNumber(asset.id);
        const lockStatuses = await getLockedAssetsStatus(storeId, [assetId]);
        const lockStatus = _.find(lockStatuses, {id: assetId});
        if (lockStatus) {
            if (!lockStatus.locked && !lockStatus.editLocked) {
                setAssetToPreview(asset);
            } else {
                navigate(`${AuthRoutes.storeAssets(storeId)}`);
                await new Prompt().notice(
                    {
                        body: (
                            <>
                                {lockStatus.locked && (
                                    <div>This file is currently being saved by another user. Please wait until saving is complete.</div>
                                )}
                                {lockStatus.editLocked && (
                                    <div>This file is currently being edited by another user. Please wait until edit is complete.</div>
                                )}
                            </>
                        ),
                        title: 'File in use',
                        displayType: 'notice',
                        props: {confirmText: 'Close'},
                    }
                );
            }
        }
        await handleLockedAssets(true);
    };

    const handleEditPdf = async() => {
        await handleLockedAssets(true);
    };

    useEffect(() => {
        handleWorkspacesForDropdown();
    }, [workspaces]);

    useEffect(() => {
        const fetchUserStoreInfoTags = async() => {
            const userInfo = await getStoreRolesInfo(storeId, userId);
            setUserStoreInfo(userInfo[0]);
        };
        fetchUserStoreInfoTags();
        if (_.get(location, 'state.from') === 'brandCard') {
            handleBrandCardClose();
        }
    }, [storeId, userId]);

    useEffect(() => {
        if (allAssets && allAssets.length && assetId && !assetToPreview) {
            const asset = _.find(allAssets, (asset) => asset.id === Number(assetId));
            if (asset) {
                previewAssetIfNotLocked(asset);
            } else {
                setAssetToPreview(undefined);
                message.error('Asset not found, or it was deleted.');
                navigate(`${AuthRoutes.storeAssets(storeId)}`);
            }
        }
        if (!assetId && assetToPreview) {
            setAssetToPreview(undefined);
        }
    }, [assetId, allAssets, assetToPreview]);

    useEffect(() => {
        let promptReload = false;
        if (allAssets && allAssets.length && !assetId && newContent.length) {
            const handleRefresh = async() => {
                await silentlyRefreshAssets();
            };
            for (const asset of newContent) {
                if (asset.promptLoad) {
                    promptReload = true;
                    asset.promptLoad = false;
                }
            }
            if (promptReload) {
                //silently refresh assets, new content has been added
                handleRefresh();
            }

            //every time new assets come in to be viewed, mark them as viewed immediately?
            // logAssetsViewed(storeId);
        }
    }, [newContent]);

    useEffect(() => {
        if (assetId && assetToPreview) {
            //assetId changed while previewing
            const fetchNewAsset = async() => {
                await refetchAssets();
                const newAsset = _.find(allAssets, (asset) => asset.id === Number(assetId));
                if (newAsset) {
                    previewAssetIfNotLocked(newAsset);
                }
            };
            fetchNewAsset();
        }
    }, [assetId]);

    //filter options is raw input data
    const filterDependencies = {
        allBrands,
        tags,
        workspaces,
        allAssets,
        brandTags,
        brandFilterHasEmptyFilterOption: true,
    };

    const initFilters = (filterDependenciesInit = filterDependencies) => {

        const filterData: Filters = {};
        for (const [filterKey, filter] of Object.entries(filters)) {
            if (!filter.loadAfter && filter.show(filterDependenciesInit) && filter.enabled(filterDependenciesInit)) {
                filterData[filterKey] = filter.dataBuilder(filterDependenciesInit);
            }
        }
        //filter data is the processed and assigned data from `dataBuilder`
        setFilterData(filterData);
    };

    const handleSetFilter = (filterValues: Record<any, any>[]) => {
        setFilterValues(filterValues);
    };

    useEffect(() => {
        if (filters && tags && allBrands && workspaces && allAssets && brandTags) {
            initFilters({
                allBrands,
                tags,
                workspaces,
                allAssets,
                brandTags,
                brandFilterHasEmptyFilterOption: true,
            });
        }
    }, [tags, allBrands, workspaces, allAssets, brandTags]);

    const createAssetCallback = async() => {
        await refetchAssets();
    };

    const handleEditAssets = () => {
        const testBrandCard = _.get(multiSelectItems, [0, 'workspace', 'brandCard']) || multiSelectItems[0].brand;
        if (_.every(multiSelectItems, (asset) => _.get(asset, 'workspace.brandCard.id') === _.get(testBrandCard, 'id') || _.get(asset.brand, 'id') === _.get(testBrandCard, 'id'))) {
            setSelectedBrand(testBrandCard);
        } else if (multiSelectItems.length) {
            setSelectedBrand({id: unchangedMultiSelectId});
        }

        const testWorkSpaceTitle = _.get(multiSelectItems, [0, 'workspace', 'title']);
        if (_.every(multiSelectItems, (asset) => _.get(asset, 'workspace.title') === testWorkSpaceTitle)) {
            setSelectedWorkspace({title: testWorkSpaceTitle});
        } else {
            setSelectedWorkspace({title: unchangedMultiSelectId});
        }

        const testTags = _.get(multiSelectItems, [0, 'tags']);
        const selectedAssetTags = [];
        for (const tag of testTags) {
            if (_.every(multiSelectItems, (asset) => {
                const tagSet = new Set(_.map(asset.tags, 'id'));
                return tagSet.has(tag.id);
            })) {
                selectedAssetTags.push(tag);
            }
        }
        setSelectedAssetTags(selectedAssetTags);

        if (multiSelectItems.length === 1) {
            setAssetName(multiSelectItems[0].name);
            if (multiSelectItems[0].isLink) {
                setAssetLink(multiSelectItems[0].url);
            }
            setAssetNotes(multiSelectItems[0].notes);
        }

        setShowEditModal(true);
    };

    const hideEditModal = () => {
        setIsSubmittingEditAssets(false);
        setShowEditModal(false);
        setAssetToEdit(undefined);
        setAssetName('');
        setAssetLink(undefined);
        setSelectedBrand(undefined);
        setSelectedWorkspace(undefined);
        setSelectedAssetTags(undefined);
        setAssetNotes('');
        handleWorkspacesForDropdown();
    };

    const handleDownloadAssets = () => {
        downloadAll(storeId, _.map(multiSelectItems, 'id'));
    };

    const handlePreviewAsset = (asset: ApiAsset) => {
        const assetType = getFileType(asset.originalFilename);
        if (assetType === SUPPORTED_FILE_TYPES.PDF) {
            const queryParams = getQueryParams(location);
            navigate(`${AuthRoutes.storeAssetViewer(storeId)}/${asset.id}`, {state: {queryParams, from: AuthRoutes.storeAssets(storeId)}});
        } else {
            navigate(`${AuthRoutes.storeAssets(storeId)}/${asset.id}`);
        }
    };

    const handleCloseAssetPreview = async() => {
        setAssetToPreview(undefined);
        const searchObject: Record<string, any> = {};
        for (const [filterKey, filterValue] of Object.entries(filterValues)) {
            if (_.get(filters, [filterKey, 'transformForAPI'])) {
                filters[filterKey].transformForAPI(
                    filterValue,
                    searchObject,
                    filterKey,
                    filterDependencies
                );
            } else {
                searchObject[filterKey] = undefined;
            }
        }
        const finalSearchObject: Record<string, any> = {};
        for (const [searchKey, searchValue] of Object.entries(searchObject)) {
            if (!_.isEmpty(searchValue)) {
                finalSearchObject[searchKey] = searchValue;
            }
        }
        const queryParams = buildQueryParams(searchObject);
        navigate(AuthRoutes.storeAssets(storeId) + queryParams, {replace: false});
        await refetchAssets(finalSearchObject);
    };

    const handleDeleteAssets = async() => {
        await new Prompt().yesNoDanger(
            {
                body: multiSelectItems.length > 1 ? 'Are you sure you want to delete these assets?' : 'Are you sure you want to delete this asset?',
                title: multiSelectItems.length > 1 ? 'Delete Assets' : 'Delete Asset',
                displayType: 'delete',
                onYes: async() => {
                    await deleteAssets(_.map(multiSelectItems, 'id'));
                    setMultiSelectItems([]);
                },
            }
        );
    };

    const handleDeleteAsset = async(assetId: ID) => {
        await new Prompt().yesNoDanger(
            {
                body: 'Are you sure you want to delete this asset?',
                title: 'Delete Asset',
                displayType: 'delete',
                onYes: async() => {
                    setIsDeletingAsset(true);
                    await deleteAssets(assetId ? [assetId] : [_.get(assetToEdit, 'id', '')]);
                    setIsDeletingAsset(false);
                    hideEditModal();
                },
            }
        );
    };

    const handleCopyUrlToClipboard = (asset: ApiAsset) => {
        if (asset.isLink && asset.url) {
            textToClipboard(asset.url);
        } else {
            textToClipboard(buildUrl(
                '/store/{{storeId}}/assets/{{assetId}}',
                {
                    storeId,
                    assetId: asset.id,
                },
                false,
                false
            ));
        }
    };

    const handleSetNewAssetName = (event: ReactEvent) => {
        setNewAssetName(event.target.value);
    };

    const handleDuplicateAsset = (asset: ApiAsset) => {
        setDuplicateAsset(asset);
        setNewAssetName(`${getFileNoExtension(asset.name)} (copy)`);
        setShowDuplicateAssetModal(true);
    };

    const submitDuplicateAsset = async() => {
        if (duplicateAsset) {
            setIsSubmittingDuplicate(true);
            await duplicateAssetApi(storeId, duplicateAsset.id, `${newAssetName}.${getFileExtension(duplicateAsset.name)}`);
            await refetchAssets();
            setIsSubmittingDuplicate(false);
            setShowDuplicateAssetModal(false);
        }
    };

    const buildAssetNameClasses = (asset: ApiAsset) => classnames(
        'asset-link',
        {
            'new-content-bubble': _.some(newContent, (newContentAsset) => newContentAsset.id === asset.id),
        }
    );

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
            cellTitle: (asset: ApiAsset) => asset.name,
            render: (UNUSED: any, asset: ApiAsset) => {
                const assetIdLocked = asset && lockedAssets.has(_.toNumber(asset.id));
                return (
                    <>
                        {asset
                            && asset.isLink
                            && asset.url
                            && !assetIdLocked
                            && (
                                <a
                                    target='_blank'
                                    rel='noreferrer'
                                    href={buildUrlFromPartial(asset.url)}
                                    className={buildAssetNameClasses(asset)}>
                                    {asset.name}
                                </a>
                            )}
                        {asset
                            && !asset.isLink
                            && !assetIdLocked
                            && (
                                <a className={buildAssetNameClasses(asset)} onClick={() => handlePreviewAsset(asset)}>{asset.name}</a>
                            )}
                        {asset
                            && Boolean(asset.modifiedLogs && asset.modifiedLogs.length)
                            && !assetIdLocked
                            && (
                                <PopIcon
                                    type='info'
                                    className='table-info-icon'
                                    popClassName='asset-modified-container'
                                    placement='bottomLeft'
                                    content={
                                        <span>
                                            <h5>Modified By:</h5>
                                            {asset.modifiedLogs.map((log) => (
                                                <div key={log.id}>{nameFormatter(log.user)} on {formatDate(log.createdAt)}</div>
                                            ))}
                                        </span>
                                    }
                                    origin={<div className='btn btn-circle btn-white btn-icon-small'><img className='svg-icon' src={infoIcon} /></div>} />
                            )}
                        {asset
                            && assetIdLocked
                            && (

                                <PopIcon
                                    className='asset-name--loading'
                                    placement='topLeft'
                                    useHover
                                    content={
                                        <div>
                                            File will be available after saving is complete.
                                        </div>
                                    }
                                    origin={<span>{asset.name} <Loading inline size={14} /></span>} />
                            )}
                    </>
                );
            },
        },
        {
            title: 'Size',
            dataIndex: 'size',
            key: 'size',
            ellipsis: true,
            width: 75,
            cellTitle: (asset: ApiAsset) => asset.size ? displayFileSize(asset.size) : '',
            // eslint-disable-next-line react/display-name
            render: (UNUSED: any, asset: ApiAsset) => {
                return asset.size ? displayFileSize(asset.size) : '';
            },
        },
        {
            title: 'Modified',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            filtered: windowIsTiny,
            ellipsis: true,
            width: 97,
            cellTitle: (asset: ApiAsset) => asset.size ? displayFileSize(asset.size) : '',
            // eslint-disable-next-line react/display-name
            render: (UNUSED: any, asset: ApiAsset) => {
                return asset.updatedAt ? formatDate(asset.updatedAt) : '';
            },
        },
        {
            title: 'Source',
            dataIndex: 'creator.name',
            key: 'creator',
            filtered: windowIsTiny,
            ellipsis: true,
            width: '12%',
            cellTitle: (asset: ApiAsset) => getContactDisplayBySource(asset),
            render: (UNUSED: any, asset: ApiAsset) => getContactDisplayBySource(asset),
        },
        {
            title: 'Brand Card',
            key: 'brandCard',
            dataIndex: 'workspace.brandCard.name',
            ellipsis: true,
            width: '14%',
            // eslint-disable-next-line react/display-name
            render: (UNUSED: any, asset: ApiAsset) => {
                return <BrandRenderer brandCard={_.get(asset, 'workspace.brandCard') || _.get(asset, 'brand')} onClose={refetchAssets} origin='assets' />;
            },
        },
        {
            title: 'Category',
            dataIndex: 'tags',
            key: 'tag',
            width: '14%',
            ellipsis: true,
            cellTitle: _.noop,
            // eslint-disable-next-line react/display-name
            render: (UNUSED: any, asset: ApiAsset) =>
                <TagsRenderer
                    tags={_.get(asset, 'tags')}
                    tagClassName='asset-tag' />,
        },
        {
            title: 'Workspace',
            width: '15%',
            ellipsis: true,
            dataIndex: ['workspace', 'title'],
            key: 'Workspace-title',
            filtered: windowIsTiny,
            // eslint-disable-next-line react/display-name
            render: (workspaceName: string) => {
                return workspaceName && (
                    <div className='tag-container'>
                        <span className='tag workspace-tag single-line-ellipsis'>{workspaceName}</span>
                    </div>
                );
            },
        },
        {
            title: 'Notes',
            key: 'notes',
            className: 'notes-td',
            align: AlignType.center,
            width: 60,
            // eslint-disable-next-line react/display-name
            render: (UNUSED: any, asset: ApiAsset) => {
                return asset?.notes && (
                    <PopIcon
                        className='action-button primary-color'
                        popClassName='asset-dashboard-notes'
                        placement='bottomRight'
                        content={asset.notes}
                        origin={<span><Note /></span>} />
                );
            },
        },
        {
            key: 'actions',
            className: 'actions-td',
            title: '',
            align: AlignType.center,
            width: 55,
            // eslint-disable-next-line react/display-name
            render: (UNUSED: any, asset: ApiAsset) => (
                <DropdownButton
                    className='action-button primary-color'
                    placement='bottomRight'
                    items={[
                        {
                            key: 'edit',
                            visible: !readOnly && !asset.limitedByPlan,
                            onClick: () => {
                                setShowEditModal(true);
                                setAssetToEdit(asset);
                                setAssetName(asset.name);
                                if (asset.isLink) {
                                    setAssetLink(asset.url);
                                }
                                setSelectedBrand(asset?.workspace?.brandCard || asset?.brand);
                                setSelectedWorkspace({title: _get(asset, 'workspace.title')});
                                setSelectedAssetTags(asset.tags);
                                setAssetNotes(_get(asset, 'notes', ''));
                            },
                            disabled: lockedAssets.has(_.toNumber(asset.id)),
                            text: 'Edit',
                        },
                        {
                            key: 'copy_url',
                            onClick: () => handleCopyUrlToClipboard(asset),
                            text: 'Get Url',
                            visible: !asset.limitedByPlan,
                        },
                        {
                            key: 'copy',
                            onClick: () => handleDuplicateAsset(asset),
                            text: 'Copy',
                            disabled: lockedAssets.has(_.toNumber(asset.id)),
                            visible: !readOnly && !asset.limitedByPlan,
                        },
                        {
                            key: 'download',
                            onClick: () => downloadAll(storeId, [asset.id]),
                            text: 'Download',
                            disabled: lockedAssets.has(_.toNumber(asset.id)),
                            visible: !asset.limitedByPlan,
                        },
                        {
                            key: 'delete',
                            visible: !readOnly,
                            onClick: () => handleDeleteAsset(asset.id),
                            text: 'Delete',
                        },
                    ]}>
                    <Kabob />
                </DropdownButton>
            ),
        },
    ];

    const mergedColumns = columns.map((col) => {
        return {
            ...col,
            onCell: (record: ApiAsset) => ({
                record,
                key: col.key || col.dataIndex,
                title: col.dataIndex && (
                    col.cellTitle ? col.cellTitle(record) : _.get(record, col.dataIndex)
                ),
            }),
        };
    });

    const handleSetAssetName = (event: ReactEvent) => {
        setAssetName(event.target.value);
    };

    const handleSetAssetLink = (event: ReactEvent) => {
        setAssetLink(event.target.value);
    };

    const handleSetAssetBrand = (id: ID, selectedBrand: BrandCard) => {
        setSelectedBrand(selectedBrand);
    };

    const handleSetAssetWorkspace = (id: ID, selectedWorkspace: Record<'title', string>) => {
        setSelectedWorkspace(selectedWorkspace);
    };

    const handleWorkspaceSearchTextChange = (searchText: string) => {
        setNewWorkspace(searchText);
    };

    const handleOnKeyDownWorkspace = (e: any) => {
        if (e.key === 'Enter') {
            const values = _.filter(workspacesCompact, (workspace) => {
                return workspace.title.toLowerCase().includes(_.toLower(newWorkspace));
            });
            if (values.length === 0) {
                const newWorkspaceObject = {title: newWorkspace};

                setSelectedWorkspace(newWorkspaceObject);
                const workspacesCompactUpdated = [newWorkspaceObject, ..._.cloneDeep(workspacesCompact)];

                setWorkspacesCompact(workspacesCompactUpdated);
                setNewWorkspace('');
            }
        }
    };

    const handleSelectTags = (UNUSED: any, selectedTags: ApiTag[]) => {
        setSelectedAssetTags(selectedTags);
    };

    const handleSetAssetNotes = (event: ReactTextareaEvent) => {
        setAssetNotes(event.target.value);
    };

    const handleAddWorkspace = (event: ReactAnchorEvent) => {
        event.preventDefault();
        const newWorkspaceObject = {title: newWorkspace};

        setSelectedWorkspace(newWorkspaceObject);
        const workspacesCompactUpdated = [newWorkspaceObject, ..._.cloneDeep(workspacesCompact)];

        setWorkspacesCompact(workspacesCompactUpdated);
        setNewWorkspace('');
    };

    const handleSubmitAssetEdit = async() => {
        setIsSubmittingEditAssets(true);
        //these ignores cover when multiSelectItems have several brands or workspaces, but the user does not wish to remove them
        const ignoreBrandUpdate = Boolean(assetBrand && assetBrand.id === unchangedMultiSelectId);
        const ignoreWorkspaceUpdate = Boolean(assetWorkspace && assetWorkspace.title === unchangedMultiSelectId);
        const masterAsset: MultiEditAsset = {};

        const isWorkspaceAsset = assetBrand && assetWorkspace && assetWorkspace.title && !ignoreWorkspaceUpdate;

        if (isWorkspaceAsset) {
            //workspace-level asset
            masterAsset.storeId = null;
            masterAsset.workspaceTitle = assetWorkspace.title;
            masterAsset.brandCardId = _get(assetBrand, 'id');
        } else if (assetBrand) {
            if (!ignoreBrandUpdate) {
                //brand-level asset
                masterAsset.brandCardId = _get(assetBrand, 'id');
                masterAsset.storeId = null;
            }
            if (!ignoreWorkspaceUpdate) {
                masterAsset.workspaceTitle = null;
                masterAsset.workspaceId = null;
            }
        } else if (!assetBrand) {
            //store-level asset
            masterAsset.storeId = storeId;
            masterAsset.brandCardId = null;
            masterAsset.workspaceTitle = null;
            masterAsset.workspaceId = null;
        }

        if (assetName) {
            masterAsset.name = assetName;
        }

        if (assetNotes) {
            masterAsset.notes = assetNotes;
        }

        if (assetLink) {
            masterAsset.url = assetLink;
        }

        const tagsAbleToBeRemoved = assetToEdit ? assetToEdit.tags : [];
        if (!assetToEdit) {
            const testTags = _.get(multiSelectItems, [0, 'tags']);
            for (const tag of (testTags || [])) {
                if (_.every(multiSelectItems, (asset) => {
                    const tagSet = new Set(_.map(asset.tags, 'id'));
                    return tagSet.has(tag.id);
                })) {
                    tagsAbleToBeRemoved.push(tag);
                }
            }
        }

        await saveAssetsApi(
            masterAsset,
            _.cloneDeep(assetTags),
            tagsAbleToBeRemoved,
            assetToEdit ? [assetToEdit] : multiSelectItems,
            ignoreBrandUpdate,
            ignoreWorkspaceUpdate,
            isWorkspaceAsset,
            assetWorkspace && assetWorkspace.title
        );
        hideEditModal();
        setMultiSelectItems([]);
        setSelectedRowKeys([]);
    };

    const hasAnyAssets = Boolean(allAssets && allAssets.length > 0);
    const hasAssets = Boolean(assets && assets.length > 0);

    const editAssetsModalTitle = (
        <div className='edit-assets-modal-title'>
            {
                shouldMultiEdit
                    ? `Edit Assets (${multiSelectItems.length})`
                    : 'Edit Asset'
            }
        </div>
    );

    const locale = () => {
        if (hasAnyAssets) {
            return {emptyText: (<NoResults/>)};
        } else if (readOnly) {
            return {emptyText: (<EmptyState objectName='Asset' readOnly={readOnly}/>)};
        } else {
            return {emptyText: (
                <div
                    className={classnames(
                        'no-content',
                        {'no-content--is-small': windowIsSmall},
                        {'no-content--is-tiny': windowIsTiny}
                    )}>
                    <img className='no-content-background-image bottom-left' src={AssetLeftConfetti} />
                    <img className='no-content-background-image bottom-right' src={AssetRightConfetti} />
                    <div className='no-content-header'>
                        Bring all of your team’s assets together in one place to be organized.
                    </div>
                    <div className='no-content-body'>
                        {'Workbooks, pricelists, MAP policies, order forms, order confirmations, UPC lists, links to media sites, \
                        pictures, images, videos… BrandKeep is ready to accept all of it. Upload via dropbox, through your own KeepMail \
                        address, or directly from your computer.'}
                        <DropdownBtnCreateAsset actionText='Upload Your First Asset' onCreate={createAssetCallback} />
                    </div>
                </div>)};
        }
    };

    const limitedBrands = _.filter(allBrands, (brandCard) => !brandCard.isLimitedByPlan);

    const handleMultiSelectItems = (UNUSED: any, selectedAssets: ApiAsset[], {type}: Record<'type', string>) => {
        if (type === 'all') {
            const filteredAssets = _.filter(assets, (asset) => !(asset.limitedByPlan || lockedAssets.has(_.toNumber(asset.id))));
            if (multiSelectItems.length && (multiSelectItems.length === filteredAssets.length)) {
                setMultiSelectItems([]);
                setSelectedRowKeys([]);
            } else {
                setMultiSelectItems(filteredAssets);
                setSelectedRowKeys(_.map(filteredAssets, 'id'));
            }
        } else {
            const filteredAssets = _.filter(selectedAssets, (asset) => !(asset.limitedByPlan || lockedAssets.has(_.toNumber(asset.id))));
            setMultiSelectItems(filteredAssets);
            setSelectedRowKeys(_.map(filteredAssets, 'id'));
        }
    };

    const storageIsOverPlanCapacity = _.get(currentStoreStatus, 'areAssetsAtLimit');

    return (
        <div className='asset-dashboard'>
            {!readOnly && (
                <Onboarding onboardType='assets' />
            )}
            <div className={classnames(
                'dashboard-container asset-dashboard-container',
                {'dashboard-container--no-content': !hasAnyAssets}
            )}>
                <div className='asset-dashboard-header'>
                    <ControlHeader
                        setSearch={setTextSearchValue}
                        search={textSearchValue}
                        disableSearch={!assets || isLoading}
                        multiSelectActions={[
                            {
                                key: 'edit',
                                onClick: handleEditAssets,
                                text: 'Edit',
                                disabled: readOnly || !multiSelectItems.length,
                            },
                            {
                                key: 'download',
                                onClick: handleDownloadAssets,
                                text: 'Download',
                                disabled: _.every(multiSelectItems, {isLink: true}),
                            },
                            {
                                key: 'delete',
                                onClick: handleDeleteAssets,
                                text: 'Delete',
                                disabled: readOnly || !multiSelectItems.length,
                            },
                        ]}
                        multiSelectActionsId='multi-select-asset-button'
                        handleRemoveFilter={removeFilterFactory(filterValues, handleSetFilter)}
                        handleSetFilter={handleSetFilter}
                        filterValues={filterValues}
                        placeholder='Search description'
                        filterData={filterData}
                        filters={filters}
                        showSort={hasAssets}
                        sortOptions={[
                            {
                                key: 'brandCard.name',
                                title: 'Brand Card',
                                func: (asset) => _.get(asset, 'workspace.brandCard.name') || _.get(asset, 'brand.name'),
                            },
                            {
                                key: 'updatedAt',
                                title: 'Modified',
                                isReversed: true,
                            },
                            {
                                key: 'name',
                                title: 'Name',
                            },
                            {
                                key: 'size',
                                title: 'Size',
                                isReversed: true,
                                shouldSortEmpty: true,
                            },
                            {
                                key: 'source',
                                title: 'Source',
                                func: (asset) => getContactDisplayBySource(asset),
                            },
                        ]}
                        setSortOption={setSortBy}
                        activeSort={sortBy} />

                    <DropdownBtnCreateAsset
                        onCreate={createAssetCallback}
                        id='create-asset-button'
                        disabled={readOnly || storageIsOverPlanCapacity} />
                </div>
                {isLoading && (
                    <Loading fill size={56} />
                )}
                {!isLoading && (
                    <Table
                        locale={locale()}
                        columns={mergedColumns}
                        shouldScroll={true}
                        data={assets}
                        id='ASSETS_TABLE'
                        useCheckboxes={true}
                        onCheckboxClick={handleMultiSelectItems}
                        isRowDisabled={(asset: ApiAsset) => lockedAssets.has(_.toNumber(asset.id))}
                        selectedRowKeys={selectedRowKeys}
                        rowClassName={(asset) => (asset.limitedByPlan || lockedAssets.has(_.toNumber(asset.id))) ? 'disabled-table-row--allow-actions' : undefined}
                        sortBy={sortBy}
                        scrollParentSelector='.asset-dashboard-table .ant-table-body'
                        className='asset-dashboard-table' />
                )}
            </div>
            <Modal
                className='edit-asset-modal'
                open={showEditModal}
                title={editAssetsModalTitle}
                footer={false}
                onCancel={hideEditModal}
                destroyOnClose>
                <div className='modal-body'>
                    {!shouldMultiEdit && (
                        <Input
                            name='asset-name'
                            required
                            label='Name'
                            infoPopContent='Use the Name field to choose the text for this asset that will display in your assets dashboard.'
                            onChange={handleSetAssetName}
                            value={assetName} />
                    )}
                    {!shouldMultiEdit && assetToEdit?.isLink && (
                        <Input
                            name='asset-link'
                            required
                            label='Link'
                            onChange={handleSetAssetLink}
                            value={assetLink} />
                    )}
                    <div>
                        <div className='pop-label'>
                            <label className='form-label' htmlFor='asset-brand'>
                                Brand Card
                            </label>
                            <PopIcon
                                className='btn-tip'
                                type='tip'
                                content={shouldMultiEdit
                                    ? 'The Brand Card field is a dropdown menu with all of your created brand cards. Choosing a brand will associate all of your selected assets to that particular brand and make it viewable in the Brand Card. If this field says ‘several’ it means your selected assets are associated with different brands and any changes will uniformly be applied to all.'
                                    : 'The Brand Card field is a dropdown menu with all of your created brand cards. Choosing a brand will associate this asset to that particular brand and make it viewable in the Brand Card.'
                                }
                                origin={<img src={tipIcon} />} />
                        </div>
                        <Select
                            notFoundContent={allBrands && allBrands.length
                                ? (<div>No results found</div>)
                                : (<div>No Brands added yet</div>)}
                            className='asset-brand-select'
                            id='asset-brand'
                            labelKey='name'
                            expectScroll={false}
                            onChange={handleSetAssetBrand}
                            options={limitedBrands}
                            valueKey='id'
                            value={assetBrand} />
                    </div>
                    <div>
                        <div className='pop-label'>
                            <label className='form-label' htmlFor='asset-workspace'>
                                Workspace
                            </label>
                            <PopIcon
                                className='btn-tip'
                                type='tip'
                                content={shouldMultiEdit
                                    ? 'The workspace field is a dropdown menu with all of your created workspaces. You can use it to select an existing workspace or create a new one! Choosing a workspace will associate all of your selected assets to a workspace within Brand Card and make them viewable there. If this field says ‘several’ it means your selected assets are associated with different workspaces and any changes will uniformly be applied to all.'
                                    : 'The workspace field is a dropdown menu with all of your created workspaces. You can use it to select an existing workspace or create a new one! Choosing a workspace will associate this asset to a workspace within Brand Card and make it viewable there.'
                                }
                                origin={<img src={tipIcon} />} />
                        </div>
                        <Select
                            notFoundContent={workspaces?.length || newWorkspace.length > 0
                                ? (<div/>)
                                : (<div>No Workspaces added yet</div>)}
                            className='asset-workspace-select'
                            disabled={!assetBrand}
                            id='asset-workspace'
                            labelKey='title'
                            onChange={handleSetAssetWorkspace}
                            onSearch={handleWorkspaceSearchTextChange}
                            options={workspacesCompact}
                            valueKey='title'
                            onKeyDown={handleOnKeyDownWorkspace}
                            expectScroll={false}
                            dropdownRender={(menu) => (
                                <>
                                    {newWorkspace.length > 0 && (
                                        <Space align='center' style={{padding: '0 8px 4px'}}>
                                            <Typography.Link onClick={handleAddWorkspace} style={{whiteSpace: 'nowrap'}}>
                                                <PlusOutlined /> Add new <b>{newWorkspace}</b>
                                            </Typography.Link>
                                        </Space>
                                    )}
                                    {menu}
                                    <Divider style={{margin: '8px 0'}} />
                                </>
                            )}
                            value={assetWorkspace} />
                    </div>
                    <div>
                        <div className='pop-label'>
                            <label className='form-label' htmlFor='tags'>
                                Category
                            </label>
                            <PopIcon
                                className='btn-tip'
                                type='tip'
                                content={shouldMultiEdit
                                    ? 'Asset Category tags allow you to organize your assets to ensure you can find them quickly. Some common asset categories are: Workbook, Annotated Workbook, Order Form, Completed Order Form, Order Confirmation, UPC list, MAP Policy, Terms Sheet, Product Imagery. If a tag is showing up here, it means it is applied to ALL of your selected assets. Adding tags to multiple assets at once will not remove existing tags that may only be applied to a few.'
                                    : 'Asset Category tags allow you to organize your assets to ensure you can find them quickly. Some common asset categories are: Workbook, Annotated Workbook, Order Form, Completed Order Form, Order Confirmation, UPC list, MAP Policy, Terms Sheet, Product Imagery.'
                                }
                                origin={<img src={tipIcon} />} />
                        </div>
                        <TagsEditable
                            notFoundContent={(
                                <div>No Categories added yet</div>
                            )}
                            tags={tags}
                            placeholder='Category'
                            id='tags'
                            isEdit={true}
                            expectScroll={false}
                            onChange={handleSelectTags}
                            selectedTags={assetTags} />
                    </div>
                    {!shouldMultiEdit && (
                        <div>
                            <div className='pop-label'>
                                <label className='form-label' htmlFor='notes'>
                                    Notes
                                </label>
                                <PopIcon
                                    className='btn-tip'
                                    type='tip'
                                    content='Use notes to keep track of useful information that can be viewed by you and the rest of your team.'
                                    origin={<img src={tipIcon} />} />
                            </div>
                            <textarea
                                id='notes'
                                className='form-textarea'
                                maxLength={250}
                                value={assetNotes}
                                placeholder='Add Notes (250 characters max)'
                                onChange={handleSetAssetNotes} />
                        </div>
                    )}
                </div>
                <div className='modal-footer'>
                    {!shouldMultiEdit && (
                        <Button
                            onClick={handleDeleteAsset}
                            className='btn-delete'>
                            {isDeletingAsset && (
                                <Loading inline />
                            )}
                            Delete Asset
                        </Button>
                    )}
                    <div
                        className='cancel-button'
                        onClick={hideEditModal}>
                        Cancel
                    </div>
                    <Button
                        disabled={isSubmittingEditAssets || (!shouldMultiEdit && !assetName)}
                        className='btn-secondary'
                        onClick={handleSubmitAssetEdit}>
                        {isSubmittingEditAssets && (
                            <Loading inline />
                        )}
                        Save
                    </Button>
                </div>
            </Modal>
            <AssetPreview
                asset={assetToPreview}
                readOnly={readOnly}
                isLockedForEditing={assetToPreview && lockedAssets.has(_.toNumber(assetToPreview.id))}
                onAssetChange={(asset) => setAssetToPreview(asset)}
                onDownload={() => assetToPreview && downloadAll(storeId, [assetToPreview.id])}
                onEditPdf={handleEditPdf}
                show={Boolean(_.get(assetToPreview, 'id'))}
                onClose={handleCloseAssetPreview} />
            {showDuplicateAssetModal && (
                <Modal
                    className='new-name-modal modal-prompt fullpage-pop-prompt-modal'
                    maskStyle={{zIndex: 2000}}
                    open={showDuplicateAssetModal}
                    title='Save as a copy'
                    footer={false}
                    onCancel={() => setShowDuplicateAssetModal(false)}
                    destroyOnClose>
                    <div className='modal-body'>
                        <Input label='Name of Copy' value={newAssetName} onChange={handleSetNewAssetName} />
                    </div>
                    <div className='modal-footer'>
                        <div
                            className='cancel-button'
                            onClick={() => {
                                setShowDuplicateAssetModal(false);
                                setNewAssetName('');
                                setDuplicateAsset(undefined);
                            }}>
                            Cancel
                        </div>
                        <Button
                            className='btn-secondary submit-button'
                            onClick={submitDuplicateAsset}
                            disabled={isSubmittingDuplicate}>
                            {isSubmittingDuplicate && (
                                <Loading inline />
                            )}
                            Save Copy
                        </Button>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default StoreAssets;
