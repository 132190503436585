import {useFormik} from 'formik';
import _ from 'lodash';
import React from 'react';
import {
    useParams,
} from 'react-router-dom';
import logo from 'src/assets/images/logo-stacked.svg';
import Button from 'src/Components/Button';
import Input from 'src/Components/Input';
import Loading from 'src/Components/Loading';
import useSubmit from 'src/Views/Login/Forms/InvitedUserSignUp/useSubmit';
import useValidationSchema from 'src/Views/Login/Forms/InvitedUserSignUp/useValidationSchema';

const InvitedUserSignUp: React.FC = () => {
    const {submitHandler} = useSubmit();
    const {code} = useParams<{code: string}>();
    const initialValues = {
        code,
        name: '',
        familyName: '',
        password: '',
    };


    const viewport = document.querySelector('meta[name=viewport]');
    viewport?.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0');

    const {
        handleSubmit,
        errors,
        touched,
        isSubmitting,
        getFieldProps,
    } = useFormik({
        initialValues,
        validationSchema: useValidationSchema,
        onSubmit: submitHandler,
    });

    return (
        <>
            <div className='formBackground' />
            <div className='formWrapper'>
                <div className='formBody'>
                    <a href='https://www.brandkeep.com'>
                        <img src={logo} className='logo' />
                    </a>
                    <div className='form'>
                        <form onSubmit={handleSubmit} id='new-password-challenge'>
                            <div>
                                <div className='inputWrapper'>
                                    <Input
                                        errors={errors.name && touched.name && errors.name}
                                        autoFocus
                                        placeholder='Jill'
                                        label='First'
                                        {...getFieldProps('name')} />
                                </div>
                                <div className='inputWrapper'>
                                    <Input
                                        errors={errors.familyName && touched.familyName && errors.familyName}
                                        placeholder='Smith'
                                        label='Last'
                                        {...getFieldProps('familyName')} />
                                </div>
                                <div className='inputWrapper'>
                                    <Input
                                        errors={errors.password && touched.password && errors.password}
                                        type='password'
                                        placeholder='*********'
                                        label='New password'
                                        {...getFieldProps('password')} />
                                </div>
                            </div>
                            <Button
                                type='submit'
                                className='btn-secondary submit-button'
                                form='new-password-challenge'
                                disabled={isSubmitting || !_.isEmpty(errors)}>
                                {isSubmitting && (
                                    <Loading inline />
                                )}
                                Create Account
                            </Button>
                        </form>
                        <div className='agreement'>
                            We’ll send you emails with product tips and best practices to help get you started.
                            You can opt out from those emails at any time. Please read our <a href='https://www.brandkeep.com/privacy-policy' target='_blank' rel='noreferrer'>Privacy Policy </a>
                            to learn more. By signing up for BrandKeep, you agree to our <a href='https://www.brandkeep.com/terms-of-use' target='_blank' rel='noreferrer'>Terms of Service</a>.
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InvitedUserSignUp;
