import {classnames} from 'src/utils/general';
import _ from 'lodash';
import './styles.sass';
import {FormikErrors} from 'formik';
import {genericFn} from 'src/Types/CommonTypes';
import React, {useEffect, useRef, useCallback} from 'react';
import PopIcon from 'src/Components/PopIcon';
import {WarningFilled} from '@ant-design/icons';
import infoIcon from 'src/assets/icons/info.svg';
import tipIcon from 'src/assets/icons/tip-hex.svg';

interface Props {
    className?: string
    containerClassName?: string
    label?: string
    required?: boolean
    value: any
    name?: string
    onChange?: genericFn
    onClick?: genericFn
    type?: string
    errors?: string | false | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined
    onBlur?: genericFn
    id?: string
    placeholder?: string
    infoPopContent?: string | JSX.Element
    infoPopType?: 'tip' | 'info'
    disabled?: boolean
    autoFocus?: boolean
    maxLength?: number
    autoComplete?: string
    readOnly?: boolean
}

const Input: React.FC<Props> = React.forwardRef(
    (
        {
            className = '',
            containerClassName = '',
            label,
            infoPopContent,
            required = false,
            infoPopType = 'tip',
            value,
            errors,
            ...props
        },
        ref: React.ForwardedRef<HTMLInputElement>
    ) => {
        const isInvalid = useRef(false);

        const checkIsInvalid = useCallback((): boolean => {
            return (
                required
                    && !value
                    && value !== 0 // a raw number of `0` should be considered valid
            )
                || Boolean(errors);
        }, [errors, required, value]);

        const computedClassName = classnames(
            'Input input-default',
            {'input--is-invalid': isInvalid.current},
            {[className]: className}
        );
        const computedContainerClassName = classnames(
            'input-container',
            {[containerClassName]: containerClassName}
        );

        if (!props.id) {
            props.id = props.name;
        }

        const handleOnBlur: genericFn = (...args) => {
            isInvalid.current = checkIsInvalid();
            if (props.onBlur) {
                props.onBlur(...args);
            }
        };

        useEffect(() => {
            if (isInvalid) {
                isInvalid.current = checkIsInvalid();
            }
        }, [checkIsInvalid, value]);

        const inputBlock = (
            <input
                {..._.omit(props, ['className', 'value', 'label', 'onBlur'])}
                className={computedClassName}
                onBlur={handleOnBlur}
                ref={ref}
                value={value} />
        );

        const popOriginClassName = infoPopType === 'info' ? 'btn btn-circle btn-white' : 'btn-tip';

        return (
            <div className={computedContainerClassName}>
                {label
                    ? (
                        <>
                            <label className='form-label' htmlFor={props.name}>
                                <>
                                    {label}
                                    {required && (
                                        <span className='is-required'> *</span>
                                    )}
                                </>
                            </label>
                            {infoPopContent && (
                                <PopIcon
                                    className={popOriginClassName}
                                    popClassName={`${infoPopType}-pop`}
                                    type={infoPopType}
                                    content={infoPopContent}
                                    origin={infoPopType === 'info' ? <img src={infoIcon} /> : <img src={tipIcon} />} />
                            )}
                            {inputBlock}
                        </>
                    )
                    : inputBlock
                }
                {errors && (
                    <div className='input-errors'>
                        <WarningFilled /> {_.upperFirst(typeof errors === 'string' ? errors : '')}
                    </div>
                )}
            </div>
        );
    }
);

export default Input;
