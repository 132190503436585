import React from 'react';
import TagSelect from 'src/Components/TagSelect';
import TagsRenderer from 'src/Components/TagsRenderer';
import {NormalizedTag, ApiTag} from 'src/Types/Tags/types';
import {ID} from 'src/Types/CommonTypes';
import _ from 'lodash';
import {ApiAsset} from 'src/api/Assets/api-asset';
import {Contact} from 'src/api/Contact/api-contact';
import {Tag} from 'src/api/Tags/api-tags';

interface PropTypes {
    tags: NormalizedTag[] | ApiTag[] | undefined
    isEdit?: boolean
    record?: ApiAsset | Contact,
    onChange: (id: ID, tags: NormalizedTag[] | ApiTag[] | Tag[]) => any
    isContacts?: boolean
    name?: string
    id?: string
    placeholder?: string
    selectedTags?: NormalizedTag[] | ApiTag[] | Tag[]
    valueKey?: string
    notFoundContent?: React.ReactNode
    expectScroll?: boolean
}

const TagsEditable = ({
    isEdit = false,
    record,
    onChange,
    isContacts = false,
    placeholder,
    selectedTags,
    valueKey = 'title',
    notFoundContent,
    expectScroll = true, //see Select component
    ...props
}: PropTypes): JSX.Element => {
    const tags = _.uniqBy(props.tags, valueKey);
    const tagsById = _.keyBy(tags, 'id');
    const tagsBySearchName = _.keyBy(tags, 'searchName');
    const handleChangeTags = (selectedTags: ApiTag[]) => {
        const newTags = [];
        for (const selectedTag of selectedTags) {
            const newTagId = selectedTag.id;
            if (newTagId) {
                if (tagsById[newTagId]) {
                    newTags.push(tagsById[newTagId]);
                } else if (tagsBySearchName[newTagId.toString().toLowerCase()]) {
                    newTags.push(tagsBySearchName[newTagId.toString().toLowerCase()]);
                } else {
                    newTags.push({
                        title: newTagId.toString(),
                        id: newTagId.toString(),
                    });
                }
            }
        }
        onChange(_.get(record, 'id'), newTags);
    };
    const assignedTags = (isEdit && selectedTags) || _.get(record, 'tags') || _.get(record, 'roles') || [];
    if (isEdit) {
        return (
            <TagSelect
                tags={tags}
                notFoundContent={notFoundContent}
                placeholder={placeholder}
                multiple={true}
                expectScroll={expectScroll}
                name='tags'
                onChange={handleChangeTags}
                values={assignedTags}
                className='select' />
        );
    }


    return (
        <TagsRenderer tags={assignedTags} tagClassName={isContacts ? 'contact-tag' : 'asset-tag'} />
    );
};

export default TagsEditable;
