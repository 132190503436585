import {GlobalActionTypes, GlobalActions, GlobalState} from './actionTypes';
import _ from 'lodash';
import {serverErrorMatchesKey} from 'src/utils/general';


const initialState: GlobalState = {
    serverErrors: [],
    promptModalOptions: {body: '', title: ''},
};

const GlobalReducer = (state = initialState, action: GlobalActions): GlobalState => {
    switch (action.type) {
        case GlobalActionTypes.SERVER_ERRORS: {
            const serverErrorsFinal = _.cloneDeep(state.serverErrors);
            serverErrorsFinal.push({
                errors: action.payload.errors,
                combinedMessage: action.payload.combinedMessage,
                config: action.payload.config,
            });

            return {
                ...state,
                serverErrors: serverErrorsFinal,
            };
        }

        case GlobalActionTypes.CLEAR_SERVER_ERRORS: {
            const serverErrorsFinal = _.cloneDeep(state.serverErrors);

            return {
                ...state,
                serverErrors: _.filter(serverErrorsFinal, (serverError) => {
                    return action.payload.key
                        ? !serverErrorMatchesKey(action.payload.key || '', serverError)
                        : Boolean(serverError.read);
                }),
            };
        }
        case GlobalActionTypes.MARK_SERVER_ERROR_READ: {
            const serverError = _.find(state.serverErrors, {key: action.payload.key});
            if (serverError) {
                serverError.read = true;
            }
            return state;
        }

        case GlobalActionTypes.PROMPT_MODAL_OPTIONS: {
            return {
                ...state,
                promptModalOptions: action.payload.promptModalOptions,
            };
        }

        default:
            return state;
    }
};

export default GlobalReducer;
