/* eslint-disable camelcase */
import {all, call, put, takeEvery} from 'redux-saga/effects';
import apiHelper from 'src/api/api-helper';
import {setTokens, getRefreshToken, setAccessToken, getCognitoUsername} from 'src/utils/token.utils';
import {checkUserStatusFailure, checkUserStatusSuccess} from './actions';
import {AuthActionTypes, IGetAuthInfoByCode} from './actionTypes';
import {ID} from 'src/Types/CommonTypes';
import {OnboardStatus} from 'src/hooks/useOnboarding';

const getTokenByCode = (code: string | null) => {
    if (code) {
        return apiHelper.post(`/auth/code/${code}`, null);
    }
};

const getAuthStatus = () => {
    return apiHelper.get('/auth/status');
};

const attemptRefreshTokens = async() => {
    const refreshToken = getRefreshToken();
    const username = getCognitoUsername();
    if (refreshToken && username) {
        return apiHelper.post(
            '/auth/refresh',
            {refreshToken, username},
            undefined,
            {},
            {
                showDefaultError: false,
                showErrorAndThrow: false,
            }
        );
    }
};

function* forceRefresh() {
    try {
        const {accessToken} = yield call(attemptRefreshTokens);
        setAccessToken(accessToken);
    } catch (error) {
        return false;
    }
}

function* getInfoByCode(action: IGetAuthInfoByCode) {
    try {
        const token: {
            accessToken: string
            idToken: string
            refreshToken: string
            tokenType: string
        } = yield call(getTokenByCode, action.payload.code);

        if (token) {
            setTokens(token.idToken, token.refreshToken);
        }

        const currentStatus: {
            familyName?: string
            isSignedIn: boolean
            name?: string
            signInUrl?: string
            signUpUrl?: string
            canUseAdminDashboard?: boolean
            intercomUserId?: string
            onboardStatus?: OnboardStatus
            hasStores?: boolean
            isMainMenuPinned?: boolean
            brandView?: string
            id: ID
            email: string
        } = yield call(getAuthStatus);

        if (currentStatus.isSignedIn) {
            yield put(
                checkUserStatusSuccess(
                    true,
                    currentStatus.name || '',
                    currentStatus.familyName || '',
                    currentStatus.id,
                    currentStatus.email,
                    currentStatus.canUseAdminDashboard,
                    currentStatus.intercomUserId,
                    currentStatus.onboardStatus,
                    currentStatus.hasStores,
                    currentStatus.isMainMenuPinned,
                    currentStatus.brandView
                )
            );
        } else {
            yield put(checkUserStatusFailure(false, currentStatus.signInUrl || '', currentStatus.signUpUrl || ''));
        }
    // eslint-disable-next-line no-empty
    } catch (error) {}
}

function* watchGetInfoByCode() {
    yield takeEvery(AuthActionTypes.AUTH_GET_INFO_BY_CODE, getInfoByCode);
}

interface UserStatus {
    familyName?: string
    isSignedIn: boolean
    name?: string
    signInUrl?: string
    signUpUrl?: string
    email: string
    id: ID
    canUseAdminDashboard?: boolean,
    isMainMenuPinned?: boolean,
    brandView?: string
    intercomUserId?: string,
    onboardStatus?: OnboardStatus
    hasStores?: boolean
}

function* getUserStatus() {
    const initialStatus: UserStatus = yield call(getAuthStatus);

    if (initialStatus.isSignedIn) {
        yield put(
            checkUserStatusSuccess(
                true,
                initialStatus.name || '',
                initialStatus.familyName || '',
                initialStatus.id,
                initialStatus.email,
                initialStatus.canUseAdminDashboard,
                initialStatus.intercomUserId,
                initialStatus.onboardStatus,
                initialStatus.hasStores,
                initialStatus.isMainMenuPinned,
                initialStatus.brandView
            )
        );
        return;
    } else {
        try {
            const initialStatus: UserStatus = yield call(getAuthStatus);

            if (initialStatus.isSignedIn) {
                yield put(
                    checkUserStatusSuccess(
                        true,
                        initialStatus.name || '',
                        initialStatus.familyName || '',
                        initialStatus.id,
                        initialStatus.email,
                        initialStatus.canUseAdminDashboard,
                        initialStatus.intercomUserId,
                        initialStatus.onboardStatus,
                        initialStatus.hasStores,
                        initialStatus.isMainMenuPinned,
                        initialStatus.brandView
                    )
                );
                return;
            } else {
                yield put(checkUserStatusFailure(false, initialStatus.signInUrl || '', initialStatus.signUpUrl || ''));
            }

        } catch (error) {
            yield put(checkUserStatusFailure(false, initialStatus.signInUrl || '', initialStatus.signUpUrl || ''));
        }
        return;
    }
}

function* updateUserStatus() {
    yield Promise.resolve(false);
}

function* watchGetUserStatus() {
    yield takeEvery(AuthActionTypes.GET_USER_STATUS, getUserStatus);
}

function* watchUpdateUserStatus() {
    yield takeEvery(AuthActionTypes.UPDATE_USER_STATUS, updateUserStatus);
}

function* watchForceRefresh() {
    yield takeEvery(AuthActionTypes.FORCE_REFRESH, forceRefresh);
}

export default function* AuthSagas() {
    yield all([watchGetInfoByCode(), watchGetUserStatus(), watchForceRefresh(), watchUpdateUserStatus()]);
}
/* eslint-enable camelcase */
