import {classnames} from 'src/utils/general';
import _ from 'lodash';
import './styles.sass';
import {FormikErrors} from 'formik';
import {ReactEvent, genericFn} from 'src/Types/CommonTypes';
import {useState, useEffect, useRef, useCallback} from 'react';
import PopIcon from 'src/Components/PopIcon';
import {WarningFilled, CloseCircleFilled, SearchOutlined} from '@ant-design/icons';
import infoIcon from 'src/assets/icons/info.svg';
import tipIcon from 'src/assets/icons/tip-hex.svg';

interface Props {
    className?: string
    label?: string
    required?: boolean
    value: any
    name?: string
    onChange?: genericFn
    type?: string
    errors?: string | false | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined
    onBlur?: genericFn
    onFocus?: genericFn
    id?: string
    placeholder?: string
    infoPopContent?: string | JSX.Element
    infoPopType?: 'tip' | 'info'
    disabled?: boolean
    preventInput?: boolean
}

const InputSearch: React.FC<Props> = ({
    className = '',
    label,
    infoPopContent,
    required = false,
    infoPopType = 'tip',
    value,
    errors,
    preventInput = false,
    ...props
}) => {
    const isInvalid = useRef(false);
    const [isFocused, setIsFocused] = useState(false);
    const [hasValue, setHasValue] = useState(Boolean(value));

    const checkIsInvalid = useCallback((): boolean => {
        return (
            required
                && !value
                && value !== 0 // a raw number of `0` should be considered valid
        )
            || Boolean(errors);
    }, [errors, required, value]);

    const computedClassName = classnames(
        'InputSearch Input input-default',
        {'input--is-invalid': isInvalid.current},
        {'input--is-focused': isFocused},
        {[className]: className}
    );

    if (!props.id) {
        props.id = props.name;
    }

    const handleOnBlur: genericFn = (...args) => {
        isInvalid.current = checkIsInvalid();
        setIsFocused(false);
        if (props.onBlur) {
            props.onBlur(...args);
        }
    };

    const handleOnFocus = (...args: any) => {
        setIsFocused(true);
        if (props.onFocus) {
            props.onFocus(...args);
        }
    };

    const handleOnChange = (event: ReactEvent) => {
        if (!preventInput) {
            if (event.target.value && event.target.value !== '') {
                setHasValue(true);
            } else {
                setHasValue(false);
            }
            if (props.onChange) {
                props.onChange(event);
            }
        }
    };

    const handleClear = () => {
        if (props.onChange) {
            props.onChange({target: {value: ''}});
        }
        setHasValue(false);
        setIsFocused(false);
    };

    useEffect(() => {
        if (isInvalid) {
            isInvalid.current = checkIsInvalid();
        }
    }, [checkIsInvalid, value]);

    const inputBlock = (
        <>
            <input
                {..._.omit(props, ['className', 'value', 'label', 'onBlur'])}
                className={computedClassName}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                onChange={handleOnChange}
                value={value} />
            {(isFocused || hasValue) ? <CloseCircleFilled onClick={handleClear} /> : <SearchOutlined />}
        </>
    );

    const popOriginClassName = infoPopType === 'info' ? 'btn btn-circle btn-white' : 'btn-tip';

    return (
        <div className='input-container'>
            {label
                ? (
                    <>
                        <label className='form-label' htmlFor={props.name}>
                            <>
                                {label}
                                {required && (
                                    <span className='is-required'> *</span>
                                )}
                            </>
                        </label>
                        {infoPopContent && (
                            <PopIcon
                                className={popOriginClassName}
                                popClassName={`${infoPopType}-pop`}
                                type={infoPopType}
                                content={infoPopContent}
                                origin={infoPopType === 'info' ? <img src={infoIcon} /> : <img src={tipIcon} />} />
                        )}
                        {inputBlock}
                    </>
                )
                : inputBlock
            }
            {errors && (
                <div className='input-errors'>
                    <WarningFilled /> {_.upperFirst(typeof errors === 'string' ? errors : '')}
                </div>
            )}
        </div>
    );
};

export default InputSearch;
