import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Routes, Route} from 'react-router-dom';
import Login from 'src/Views/Login';
import ConfirmResetPass from 'src/Views/Login/Forms/ConfirmResetPass/ConfirmResetPass';
import InvitedUserSignUp from 'src/Views/Login/Forms/InvitedUserSignUp/InvitedUserSignUp';
import ConfirmSignUp from 'src/Views/Login/Forms/ConfirmSignUp/ConfirmSignUp';
import ForgotPassForm from 'src/Views/Login/Forms/ForgotPassForm/ForgotPassForm';
import SignInForm from 'src/Views/Login/Forms/SignInForm/SignInForm';
import SignUpForm from 'src/Views/Login/Forms/SignUpForm/SignUpForm';
import {AuthRoutes} from './constants/userRoles';
import usePageTracking from './hooks/usePageTracking';
import Intercom from 'src/hooks/useIntercom';
import {getUserStatus} from 'src/redux/auth/actions';
import {getStoreStatus} from 'src/redux/storeStatus/actions';
import AuthRoute from './routes/AuthRoute';
import CreateStore from './Views/Onboarding/CreateStore';
import InviteUsers from './Views/Onboarding/InviteUsers';
import MobileMessage from './Views/Onboarding/MobileMessage';
import Store from './Views/Store';
import Admin from './Views/Admin';
import Pings from 'src/lib/pings';
import apiHelper from 'src/api/api-helper';
import ModalPrompt from 'src/Components/ModalPrompt';
import RootError from 'src/Components/RootError';
import {RootState} from 'src/redux/rootReducer';
import {getToken} from 'src/redux/token/actions';
import _ from 'lodash';
import AdminAuthRoute from './routes/AdminAuthRoute';
import {ConfigProvider} from 'antd';
import {registerLicense} from '@syncfusion/ej2-base';

// Registering Syncfusion license key
registerLicense('ORg4AjUWIQA/Gnt2VFhhQlJNfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5Xd0ZjUH1ecHVUQGNU');

const App: React.FC = (): JSX.Element => {
    const dispatch = useDispatch();
    const promptModalOptions = useSelector((state: RootState) => state.global.promptModalOptions);


    useEffect(() => {
        const handleInit = async() => {
            await apiHelper.forceTokenRefresh();
            dispatch(getUserStatus());
            dispatch(getToken());
        };
        handleInit();
        Pings.startPings();
        Pings.addPing({
            id: 'refreshTokenPing',
            //token expires every 30, lets refresh every 20, so other pings have 10 minutes to use old tokens/pickup new ones
            frequency: 2,
            task: async() => {
                await apiHelper.forceTokenRefresh();
                dispatch(getToken());
            },
            onError: _.noop,
        });
        //refresh current store perms/plan status
        Pings.addPing({
            id: 'refreshStoreStatus',
            frequency: 1,
            task: () => {
                dispatch(getStoreStatus());
            },
            onError: _.noop,
        });
    }, [dispatch]);

    usePageTracking();

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#9A247C',
                    fontFamily: 'Metrik, Arial, sans-serif',
                    borderRadius: 2,
                },
                // components: {
                //     Modal: {
                //         padding: 0,
                //     },
                // },
            }}>
            <Routes>
                <Route path='/' element={<Login />} />
                <Route path='/signIn' element={<SignInForm />} />
                <Route path='/signUp' element={<SignUpForm />} />
                <Route path='/confirmEmail/:code' element={<ConfirmSignUp />} />
                <Route path='/resetPassword/:code' element={<ConfirmResetPass />} />
                <Route path='/invite/:code' element={<InvitedUserSignUp />} />
                <Route path='/forgotPassword' element={<ForgotPassForm />} />
                <Route
                    path={AuthRoutes.onboardingCreateStore}
                    element={
                        <AuthRoute>
                            <CreateStore />
                        </AuthRoute>
                    } />
                <Route
                    path={AuthRoutes.onboardingInviteTeam}
                    element={
                        <AuthRoute>
                            <InviteUsers />
                        </AuthRoute>
                    } />
                <Route
                    path={AuthRoutes.onboardingMobileMessage}
                    element={
                        <AuthRoute>
                            <MobileMessage />
                        </AuthRoute>
                    } />
                <Route
                    path='/store/*'
                    element={
                        <AuthRoute>
                            <Store />
                        </AuthRoute>
                    } />
                <Route
                    path='/admin/*'
                    element={
                        <AdminAuthRoute>
                            <Admin />
                        </AdminAuthRoute>
                    } />
            </Routes>
            <div id='promptModal'>
                <ModalPrompt {...promptModalOptions} />
            </div>
            <RootError />
            <Intercom />
        </ConfigProvider>
    );
};

export default App;
