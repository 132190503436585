import {useState} from 'react';
import {forgotPassword} from 'src/api/Auth';
import {FormValues} from 'src/Views/Login/Forms/ForgotPassForm/useValidationSchema';

const useSubmit = () => {
    const [message, setMessage] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const submitHandler = async(values: FormValues) => {
        try {
            const response = await forgotPassword({username: values.email});
            setMessage(response.msg);
            setIsSuccess(true);
        } catch (e: any) {
            //handled by <RootError>
        }
    };
    return {submitHandler, message, isSuccess};
};

export default useSubmit;
