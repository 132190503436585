import {UserDTOForApi, User} from 'src/Types/User/types';
import apiHelper from '../api-helper';
import {ID} from 'src/Types/CommonTypes';
import {OnboardStatus} from 'src/hooks/useOnboarding';
import {NewContent} from 'src/hooks/useNewContent';

export interface UserInfo {
    email: string
    firstName: string
    lastName: string
}

interface ParamsForUsers {
    textSearch?: string
}

interface Invite {
    email: string
    role: string
}

interface NewUserInfo {
    invites: Invite[]
}

export const getUsers = async(storeId: ID, params?: ParamsForUsers): Promise<User[]> => {
    return apiHelper.get('/users', {storeId, ...params});
};

export const getUserById = async(storeId: ID, userId: ID): Promise<User> => {
    return apiHelper.get(`/users/${userId}`, {storeId});
};

export const updateUser = async(storeId: ID, userId: ID, newUserInfo: UserDTOForApi): Promise<User> => {
    return apiHelper.put(
        `/stores/${storeId}/users/${userId}`,
        newUserInfo,
        undefined,
        {},
        {
            inlineErrorFields: ['email'],
        }
    );
};

export const updateOnboarding = async(storeId: ID, onboardingStatus: OnboardStatus): Promise<void> => {
    return apiHelper.put('/users/onboarding', {storeId, ...onboardingStatus});
};

export const updateMainMenuPin = async(storeId: ID, isMainMenuPinned: boolean): Promise<void> => {
    return apiHelper.put('/users/menu_pin', {storeId, isMainMenuPinned});
};

export const updateBrandView = async(storeId: ID, brandView: boolean): Promise<void> => {
    return apiHelper.put('/users/brand_view', {storeId, brandView});
};

export const getNewContentForUser = async(storeId: ID, init: boolean): Promise<NewContent[]> => {
    const queryString = init ? {init} : undefined;
    return apiHelper.get(
        `/users/${storeId}/new_content`,
        queryString,
        {showDefaultError: false, showErrorAndThrow: false}
    );
};

export const inviteUsers = async(storeId: ID, newUserInfo: NewUserInfo): Promise<any> => {
    return apiHelper.post(`stores/${storeId}/invite`, newUserInfo);
};

export const resendInvite = (storeId: ID, userId: ID): Promise<User> => {
    return apiHelper.post(`/stores/${storeId}/users/${userId}/resendInvite`, {storeId});
};

export const resetPassword = (storeId: ID, userId: ID): Promise<User> => {
    return apiHelper.post(`/users/${userId}/resetPassword`, {storeId});
};

export const deleteUser = async(storeId: ID, userId: ID): Promise<void> => {
    return apiHelper.delete(`/stores/${storeId}/users/${userId}`);
};
