import {FormikHelpers} from 'formik';
import {nanoid} from 'nanoid';
import {useEffect, useRef, useState, useCallback} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {BrandCard, getBrandCards, ParamsForBrandCard} from 'src/api/BrandCard/api-brand-card';
import {
    deleteContact,
    deleteContacts as deleteContactsApi,
    getStoreContacts,
    createContact,
    updateContact,
    ContactBase,
    Contact,
    ContactRefTypes,
} from 'src/api/Contact/api-contact';
import {getTags, createTag, Tag} from 'src/api/Tags/api-tags';
import {ID} from 'src/Types/CommonTypes';
import brandFilter from 'src/lib/filters/brand';
import contactFilter from 'src/lib/filters/contact-tag';
import {ApiTag, TagRefType, TagsScope, NormalizedTag} from 'src/Types/Tags/types';
import {Filter, handleFilterValidations, GLOBAL_FILTER_DEBOUNCE_RATE} from 'src/lib/filter-helper';
import {getQueryParams, HistoryHelperFactory} from 'src/lib/url';
import {debouncePromise} from 'src/utils/general';
import _ from 'lodash';


interface FilterDependencies {
    tags?: ApiTag[]
    contacts?: Contact[]
    allBrands?: BrandCard[]
    allContacts?: Contact[]
}

export interface MultiEditContact {
    refType?: ContactRefTypes
    brandCardId?: ID | null
    storeId?: ID | null
    firstName?: string | null
    lastName?: string | null
    phone?: string | null
    email?: string | null
    notes?: string | null
    tags?: number[]
    useKeepMail?: boolean
}

const useContactsApi = (storeId: ID, brandCardId?: ID) => {
    //temporary until we allow brandCard contact-list to use filters
    const DISABLE_QUERY_PARAM = Boolean(brandCardId);
    const historyHelper = HistoryHelperFactory(useNavigate());
    const queryParams = getQueryParams(useLocation());

    const [editingKey, setEditingKey] = useState<ID>('');

    const [contacts, setContacts] = useState<Contact[]>();
    const [allContacts, setAllContacts] = useState<Contact[]>();
    const [isLoading, setIsLoading] = useState(true);
    const [filterByName, setFilterByName] = useState(
        (
            !DISABLE_QUERY_PARAM
            && (_.isArray(queryParams.filterByName) ? queryParams.filterByName.join(' ') : queryParams.filterByName)
        )
        || ''
    );

    const [tags, setTags] = useState<ApiTag[]>();
    const [allBrands, setAllBrands] = useState<BrandCard[]>();

    const [selectedBrandNames, setSelectedBrandNames] = useState<Array<string>>([]);
    const [selectedContactTypes, setSelectedContactTypes] = useState<Array<string>>([]);
    const callId = useRef<string | null>(null);

    const initialFilters = [
        contactFilter,
    ];
    if (!brandCardId) {
        initialFilters.push(brandFilter);
    }
    const rawFilters = _.keyBy(initialFilters, 'key');

    const filterDependencies: FilterDependencies = {
        allBrands,
        tags,
        contacts,
        allContacts,
    };

    const [filterData, setFilterData] = useState<any>();
    const [filterValues, setFilterValues] = useState<any>({});
    const [areFilterValuesDefault, setAreFilterValuesDefault] = useState(true);
    const [filters, setFilters] = useState<Record<string, Filter>>(
        _.omitBy(rawFilters, (filter) => {
            return !filter.enabled(filterDependencies) && !filter.show(filterDependencies);
        })
    );

    const getFilters = (filterDependencies: FilterDependencies, brandCardId) => {
        const initialFilters = [
            contactFilter,
        ];
        if (!brandCardId) {
            initialFilters.push(brandFilter);
        }
        //filters here must still be key'ed to their respective 'key'
        const rawFilters = _.keyBy(initialFilters, 'key');
        const filters = _.omitBy(
            rawFilters,
            (filter) => !filter.enabled(filterDependencies) && !filter.show(filterDependencies)
        );
        setFilters(filters);
    };

    type SearchObject = {
        filterByName?: string
        brand?: ID[]
        workspace?: ID[]
    };

    const getContact = useCallback(async(
        currentCallId: string,
        brandCardId: ID,
        {
            filterValuesFromState = filterValues,
            forceInitialLoad = false,
        } = {}
    ) => {
        if (callId.current === currentCallId) {
            setIsLoading(true);
            const searchObject: Record<string, any> = {};
            if (filterByName) {
                searchObject.filterByName = filterByName;
            }
            for (const [filterKey, filterValue] of Object.entries(filterValuesFromState)) {
                if (_.get(filters, [filterKey, 'transformForAPI'])) {
                    filters[filterKey].transformForAPI(filterValue, searchObject, filterKey, filterDependencies);
                }
            }

            const isInitialLoad = !(contacts && tags && allBrands);
            const finalSearchObject: SearchObject = (isInitialLoad && !DISABLE_QUERY_PARAM) ? queryParams : searchObject;
            const allContactsSearchObject: SearchObject = {};

            //brandcard
            if (brandCardId) {
                finalSearchObject.brand = [brandCardId.toString()];
                allContactsSearchObject.brand = [brandCardId.toString()];
            }

            const fetchedContacts = await getStoreContacts(
                storeId,
                finalSearchObject
            );
            if (isInitialLoad || forceInitialLoad) {
                const [
                    tags,
                    {data: allBrands},
                    {data: allContacts},
                ] = await Promise.all([
                    getTags('contacts', 'store', storeId),
                    getBrandCards(storeId, {
                        perpage: 5000,
                        [ParamsForBrandCard.Assets]: false,
                        [ParamsForBrandCard.Contacts]: false,
                        [ParamsForBrandCard.Workspaces]: false,
                    }),
                    getStoreContacts(storeId, allContactsSearchObject),
                ]);
                setAllContacts(allContacts);
                setAllBrands(allBrands);
                setTags(tags);

                getFilters({
                    allContacts,
                    allBrands,
                    tags,
                    contacts: fetchedContacts.data,
                }, brandCardId);

                const {
                    updatedQueryParamObject,
                    urlFilterValues,
                    didFiltersObviouslyChange,
                } = handleFilterValidations(
                    filters,
                    {
                        allContacts,
                        allBrands,
                        tags,
                        contacts: fetchedContacts.data,
                    },
                    DISABLE_QUERY_PARAM ? {} : queryParams
                );

                setFilterValues(urlFilterValues);
                if (!DISABLE_QUERY_PARAM) {
                    historyHelper.updateQueryParams(updatedQueryParamObject, false);
                }
                if (didFiltersObviouslyChange) {
                    //filters changed, short-circuit this request and fire new query
                    const currentCallId = nanoid();
                    // eslint-disable-next-line require-atomic-updates
                    callId.current = currentCallId;
                    getContact(
                        currentCallId,
                        brandCardId,
                        {filterValuesFromState: urlFilterValues}
                    );
                    return;
                } else {
                    setContacts(fetchedContacts.data);
                }
            } else {
                setContacts(fetchedContacts.data);
                if (!DISABLE_QUERY_PARAM) {
                    historyHelper.updateQueryParams(searchObject);
                }
            }
            setIsLoading(false);
        }
    }, [storeId, allBrands, tags, filters, contacts, getFilters, filterValues]);

    const refetchContacts = async() => {
        setIsLoading(true);
        const searchObject = {
            filterByName,
        };
        for (const [filterKey, filterValue] of Object.entries(filterValues)) {
            if (_.get(filters, [filterKey, 'transformForAPI'])) {
                filters[filterKey].transformForAPI(filterValue, searchObject, filterKey, filterDependencies);
            }
        }
        const fetchedContacts = await getStoreContacts(storeId, searchObject);

        setContacts(fetchedContacts.data);
        setIsLoading(false);
    };

    const deleteContactApi = async(contactId: ID) => {
        setIsLoading(true);
        await deleteContact(contactId);
        const currentCallId = nanoid();
        callId.current = currentCallId;
        await getContact(currentCallId, brandCardId, {forceInitialLoad: true});
        setIsLoading(false);
    };

    const saveContactApi = async(record: ContactBase, actions: FormikHelpers<any>) => {
        await updateContact(editingKey, {
            firstName: record.firstName,
            lastName: record.lastName,
            phone: record.phone,
            email: record.email,
        });

        setEditingKey('');
        actions.setSubmitting(false);
        const currentCallId = nanoid();
        callId.current = currentCallId;
        await getContact(currentCallId, brandCardId, {forceInitialLoad: true});
    };

    const saveContactsApi = async(
        record: MultiEditContact,
        assignedRoles: NormalizedTag[] | undefined,
        rolesAbleToBeRemoved: ApiTag[],
        contactsToUpdate: Partial<Contact>[]
    ) => {

        const rolesAbleToBeRemovedSet = new Set(_.map(rolesAbleToBeRemoved, 'id'));
        const tagsById = _.keyBy(tags, 'id');
        const payloadForSaveRoles: Tag[] = [];
        for (const recordRole of assignedRoles || []) {
            if (recordRole && (!recordRole.id || !tagsById[recordRole.id])) {
                delete recordRole.id;
                payloadForSaveRoles.push({
                    refType: TagRefType.Contacts,
                    scope: TagsScope.Store,
                    scopeId: Number(storeId),
                    title: `${recordRole.title}` || '',
                });
            }
        }

        const rolePromises: Array<Promise<any>> = [];
        payloadForSaveRoles?.forEach((role) => {
            rolePromises.push(createTag(role));
        });

        const savedRoles = await Promise.all(rolePromises);
        const savedRolesByTitle = _.keyBy(savedRoles, 'title');

        const aggregatedRoleIds = _.map(assignedRoles, (recordRole) => {
            if (!recordRole.id && savedRolesByTitle[recordRole.title]) {
                return savedRolesByTitle[recordRole.title].id;
            } else {
                return recordRole.id;
            }
        });
        if (contactsToUpdate && contactsToUpdate.length) {
            for (const contact of contactsToUpdate) {
                if (contactsToUpdate.length === 1) {
                    delete contact.firstName;
                    delete contact.lastName;
                    delete contact.phone;
                    delete contact.email;
                    delete contact.notes;
                }
                const updatedContact = Object.assign({}, contact, record);
                const contactRoles = _.filter(contact.roles, (role) => !rolesAbleToBeRemovedSet.has(role.id));
                updatedContact.roles = [..._.map(contactRoles, 'id'), ...aggregatedRoleIds];
                await updateContact(storeId, updatedContact);
            }
        } else {
            record.roles = aggregatedRoleIds;
            await createContact(record);
        }


        const currentCallId = nanoid();
        callId.current = currentCallId;
        await getContact(currentCallId, brandCardId, {forceInitialLoad: true});
    };

    const deleteContacts = async(contactIds: ID[]) => {
        setIsLoading(true);
        await deleteContactsApi(storeId, contactIds);
        const currentCallId = nanoid();
        callId.current = currentCallId;
        await getContact(currentCallId, brandCardId, {forceInitialLoad: true});
        setIsLoading(false);
    };


    useEffect(() => {
        const currentCallId = nanoid();
        callId.current = currentCallId;
        getContact(currentCallId, brandCardId, {forceInitialLoad: true});
    }, [storeId, brandCardId]);

    //if filterValues change, get new contacts list
    useEffect(() => {
        if (contacts && !isLoading && !areFilterValuesDefault) {
            const currentCallId = nanoid();
            callId.current = currentCallId;
            debouncePromise(
                GLOBAL_FILTER_DEBOUNCE_RATE,
                () => getContact(currentCallId, brandCardId, {filterValuesFromState: filterValues})
            )();
        }
        if (!_.isEmpty(filterValues)) {
            setAreFilterValuesDefault(false);
        }
    }, [filterValues, filterByName]);

    return {
        allContacts,
        contacts,
        isLoading,
        deleteContactApi,
        deleteContacts,
        filterByName,
        setFilterByName,
        editingKey,
        setEditingKey,
        saveContactApi,
        allBrands,
        tags,
        saveContactsApi,
        selectedContactTypes,
        setSelectedContactTypes,
        selectedBrandNames,
        setSelectedBrandNames,
        refetchContacts,
        filterData,
        setFilterData,
        filterValues,
        setFilterValues,
        filters,
        setFilters,
    };
};

export default useContactsApi;
