import {ID, ApiMeta, API_ID} from 'src/Types/CommonTypes';
import apiHelper from '../api-helper';
import {WorkspaceLink} from '../WorkspaceLink/api-workspace-link';
import {NormalizedTag} from 'src/Types/Tags/types';
import {ApiWorkspace} from 'src/api/Workspace/api-workspace';
import {BrandCard} from 'src/api/BrandCard/api-brand-card';

export interface Asset {
    asset: File
    tags: Array<string | number>
}

export interface AssetCreator {
    id: ID
    firstName?: string
    lastName?: string
    email?: string
}

interface LogUser {
    firstName: string
    lastName: string
    id: API_ID
    email: string
}

interface ModifiedLog {
    id: API_ID
    assetId: API_ID
    user: LogUser
    userId: API_ID
    createdAt: string
}

export interface ApiAsset {
    brand?: BrandCard
    createdAt: string
    creator?: AssetCreator
    rawCreatedBy?: string
    contactCreator?: AssetCreator
    id: API_ID
    isLink: boolean
    locked: boolean
    name: string
    originalFilename: string
    workspace: ApiWorkspace | null
    workspaceId: API_ID
    storeId: API_ID
    brandCardId: API_ID
    notes: string | null
    source: string | null
    size: number | null
    tags: NormalizedTag[]
    updatedAt: string
    url?: string
    limitedByPlan?: boolean
    modifiedLogs: ModifiedLog[]
}

export interface LinkAsset {
    name?: string
    url?: string
    tags?: number[]
}

export interface DataMetaReturn {
    data: ApiAsset[],
    meta: ApiMeta
}

export interface ParamsForStoreAssets {
    brand?: ID[]
    workspace?: ID[]
}

interface AssetFileStreamResults {
    config: any
    data: any
    headers: any
    request: XMLHttpRequest
    status: number
    statusText: string
}

interface AssetUrlRawResponse {
    url: string
}

export const getAsset = (storeId: ID, assetId: ID): Promise<ApiAsset> => {
    return apiHelper.get(`/stores/${storeId}/assets/${assetId}`);
};

export const getWorkspaceAssets = (workspaceId: ID) => {
    return apiHelper.get(`/seasons/${workspaceId}/assets`);
};

export const getStoreAssets = (storeId: ID, params?: ParamsForStoreAssets): Promise<DataMetaReturn> => {
    return apiHelper.post(`/stores/${storeId}/assets`, {...params, refType: 'brand_cards'});
};

export const postWorkspaceAsset = (workspaceId: string | number, asset: Asset) => {
    return apiHelper.post(`/seasons/${workspaceId}/assets`, asset);
};

export const postWorkspaceAssetFiles = (workspaceId: ID, formData: FormData) => {
    return apiHelper.post(`/seasons/${workspaceId}/files`, formData, {'Content-Type': 'multipart/form-data'});
};

export const postWorkspaceAssetFileFromLink = (workspaceId: ID, link: WorkspaceLink) => {
    return apiHelper.post(`/seasons/${workspaceId}/filelinks`, link);
};

export const postWorkspaceDriveFile = (workspaceId: ID, files: any[]): Promise<string> => {
    return apiHelper.post(`/seasons/${workspaceId}/drive`, {files});
};

export const getUrlForWorkspaceAsset = (assetId: ID) => {
    return apiHelper.get(`/seasons/assets/${assetId}/signed_url`);
};

export const getUrlForDownloadWorkspaceAssets = (assetId: ID) => {
    return apiHelper.get(`/seasons/assets/${assetId}/download`);
};

export const getAssetFileStream = (assetId: ID): Promise<AssetFileStreamResults> => {
    return apiHelper.getRaw(`/seasons/files/${assetId}/open`);
};

//deprecated?
export const updateWorkspaceAsset = (assetId: ID, asset: {tags: ID[]}) => {
    return apiHelper.put(`/seasons/assets/${assetId}`, asset);
};

export const updateAssets = (
    storeId: ID,
    updatedAssets: Partial<ApiAsset>[],
    ignoreBrandUpdate = false,
    ignoreWorkspaceUpdate = false
): Promise<void> => {
    return apiHelper.put(`/stores/${storeId}/assets`, {updatedAssets, ignoreBrandUpdate, ignoreWorkspaceUpdate});
};

export const updateWorkspaceFile = (fileId: ID, asset: {tags: ID[]}) => {
    return apiHelper.put(`/seasons/files/${fileId}`, asset);
};

export const deleteWorkspaceAsset = (assetId: ID) => {
    return apiHelper.delete(`/seasons/assets/${assetId}`);
};

export const deleteAssets = (storeId: ID, assetIds: ID[]): Promise<void> => {
    return apiHelper.post(`/stores/${storeId}/assets/delete`, {assetIds});
};

export const downloadAllApiCall = (workspaceId: string | number) => {
    return apiHelper.get(`/seasons/${workspaceId}/files/download_all`);
};

export const downloadAllForWorkspaceLink = (workspaceId: ID, assetIds?: ID[]): Promise<Record<'url', string>> => {
    return apiHelper.get(`/seasons/${workspaceId}/files/download_signed_for_workspace`, {ids: assetIds});
};

export const downloadAllForStoreLink = (storeId: ID, assetIds?: ID[]): Promise<Record<'url', string>> => {
    return apiHelper.get(`/stores/${storeId}/files/download_signed_for_store`, {ids: assetIds});
};

export const downloadAssetFile = (fileId: ID, type: 'open' | 'download') => {
    return apiHelper.get(`/seasons/files/${fileId}/signed_url`, {type});
};

export const downloadAssetFileNoBrand = (fileId: ID, type: 'open' | 'download') => {
    return apiHelper.get(`/stores/files/${fileId}/signed_url`, {type});
};

export const assetUrlRaw = (fileId: ID, type: 'open' | 'download'): Promise<AssetUrlRawResponse> => {
    return apiHelper.get(`/files/${fileId}/signed_url`, {type});
};

export const streamAsset = (fileId: ID) => {
    return apiHelper.get(`seasons/files/${fileId}/outside/open`);
};

//deprecate
export const suggestAssetsForStore = (storeId: ID, search: string, count: number) => {
    return apiHelper.get(`stores/${storeId}/assets/suggest`, {search, count});
};

export const createAssetLink = (storeId: ID, linkAsset: LinkAsset): Promise<void> => {
    return apiHelper.post(`/stores/${storeId}/assets/links`, linkAsset);
};

export const createAssetFiles = (storeId: ID, formData: FormData): Promise<void> => {
    return apiHelper.post(`/stores/${storeId}/assets/files`, formData, {'Content-Type': 'multipart/form-data'});
};

export const createAssetDropbox = (storeId: ID, files: any[]): Promise<void> => {
    return apiHelper.post(`/stores/${storeId}/assets/dropbox`, {files});
};

export const createAssetDrive = (storeId: ID, files: any[]): Promise<string> => {
    return apiHelper.post(`/stores/${storeId}/assets/drive`, {files});
};

export const createBrandAssetLink = (brandCardId: ID, linkAsset: LinkAsset): Promise<void> => {
    return apiHelper.post(`/stores/brand_cards/${brandCardId}/assets/links`, linkAsset);
};

export const createBrandAssetFiles = (brandCardId: ID, formData: FormData): Promise<void> => {
    return apiHelper.post(`/stores/brand_cards/${brandCardId}/assets/files`, formData, {'Content-Type': 'multipart/form-data'});
};

export const createBrandAssetDropbox = (brandCardId: ID, files: any[]): Promise<void> => {
    return apiHelper.post(`/stores/brand_cards/${brandCardId}/assets/dropbox`, {files});
};

export const createBrandAssetDrive = (brandCardId: ID, files: any[]): Promise<string> => {
    return apiHelper.post(`/stores/brand_cards/${brandCardId}/assets/drive`, {files});
};

export const removeAssetAssociations = (storeId: ID, brandCardIds: ID[]): Promise<void> => {
    return apiHelper.put(`/stores/${storeId}/assets/disassociate`, {brandCardIds});
};

export const removeWorkspaceAssetAssociations = (brandCardId: ID, workspaceIds: ID[]): Promise<void> => {
    return apiHelper.put(`/stores/brand_cards/${brandCardId}/assets/disassociate`, {workspaceIds});
};

export const updateAssetFile = (storeId: ID, assetId: ID, formData: FormData) => {
    return apiHelper.post(`/stores/${storeId}/assets/${assetId}/update`, formData, {'Content-Type': 'multipart/form-data'});
};

export const duplicateAsset = (storeId: ID, assetId: ID, newName: string) => {
    return apiHelper.post(`/stores/${storeId}/assets/${assetId}/duplicate`, {newName});
};

export const duplicateAssetForCopySave = (storeId: ID, assetId: ID, newName: string): Promise<ApiAsset> => {
    return apiHelper.post(`/stores/${storeId}/assets/${assetId}/copy`, {newName});
};

export const getAssetApiTokenForExternal = (storeId: ID, assetId: ID): Promise<{key: string, s3Key: string}> => {
    return apiHelper.get(`/stores/${storeId}/assets/${assetId}/api_key`);
};

//lock the asset during saving
export const lockAssetForSaving = (storeId: ID, assetId: ID): Promise<void> => {
    return apiHelper.post(`/stores/${storeId}/assets/${assetId}/lock`, {});
};


interface AssetLockStatusResponse {
    id: API_ID
    locked: boolean
    editLocked: boolean
}
interface AssetEditLockStatusResponse {
    id: API_ID
    userId: API_ID
    assetId: API_ID
}

export const getLockedAssetsStatus = (storeId: ID, assetIds: number[]): Promise<AssetLockStatusResponse[]> => {
    return apiHelper.post(`/stores/${storeId}/assets/lock_status`, {assetIds});
};

//lock the asset during editing
export const lockAssetForEditing = (storeId: ID, assetId: ID): Promise<void> => {
    return apiHelper.post(`/stores/${storeId}/assets/${assetId}/edit_lock`, {});
};
//lock the asset during editing
export const unlockAssetForEditing = (storeId: ID, assetId: ID): Promise<void> => {
    return apiHelper.post(`/stores/${storeId}/assets/${assetId}/edit_unlock`, {});
};

export const getEditingAssetsStatus = (storeId: ID, assetId: ID): Promise<AssetEditLockStatusResponse | null> => {
    return apiHelper.get(`/stores/${storeId}/assets/${assetId}/edit_status`);
};

export const logAssetsViewed = (storeId: ID): Promise<void> => {
    return apiHelper.post(`/stores/${storeId}/assets/activity`, {});
};
