import {AuthActions, AuthActionTypes, UserStatusPayload} from './actionTypes';
import {ID} from 'src/Types/CommonTypes';
import {OnboardStatus} from 'src/hooks/useOnboarding';

export const checkIsUserAuthByCode = (code: string | null): AuthActions => ({
    type: AuthActionTypes.AUTH_GET_INFO_BY_CODE,
    payload: {code},
});

export const getUserStatus = (): AuthActions => ({
    type: AuthActionTypes.GET_USER_STATUS,
});

export const updateUserStatusPayload = (payload: Partial<UserStatusPayload>): AuthActions => ({
    type: AuthActionTypes.UPDATE_USER_STATUS,
    payload,
});

export const checkUserStatusSuccess = (
    isSignedIn: boolean,
    name: string,
    familyName: string,
    id: ID,
    email: string,
    canUseAdminDashboard = false,
    intercomUserId?: string,
    onboardStatus?: OnboardStatus,
    hasStores?: boolean,
    isMainMenuPinned?: boolean,
    brandView?: string
): AuthActions => {
    const status: AuthActions = {
        type: AuthActionTypes.GET_USER_STATUS_SUCCESS,
        payload: {
            isSignedIn,
            name,
            familyName,
            id,
            email,
            intercomUserId,
            onboardStatus,
            hasStores,
            isMainMenuPinned,
            brandView,
        },
    };
    if (canUseAdminDashboard) {
        status.payload.canUseAdminDashboard = canUseAdminDashboard;
    }
    return status;
};

export const checkUserStatusFailure = (isSignedIn: boolean, signInUrl: string, signUpUrl: string): AuthActions => ({
    type: AuthActionTypes.GET_USER_STATUS_FAILURE,
    payload: {
        isSignedIn,
        signInUrl,
        signUpUrl,
    },
});

export const logout = (): AuthActions => ({
    type: AuthActionTypes.LOGOUT,
});

export const forceRefresh = (): AuthActions => ({
    type: AuthActionTypes.FORCE_REFRESH,
});
