import apiHelper from '../api-helper';
import {ID, SuggestedName, ApiMeta} from 'src/Types/CommonTypes';
import {Tag} from 'src/api/Tags/api-tags';
import {Link} from 'src/api/Link/api-link';
import {Contact} from 'src/api/Contact/api-contact';

export interface BrandCard {
    id?: ID
    storeId?: ID
    name?: string
    address?: string
    warrantyNotes?: string
    accountNumber?: number
    b2bNotes?: string
    consumerUrl?: string
    notes?: string
    tags?: Tag[]
    brandTags?: string[]
    logoUrl?: string
    contacts?: Contact[]
    links?: Link[]
    isLimitedByPlan?: boolean
}

export enum ParamsForBrandCard {
    Workspaces = 'workspaces',
    Contacts = 'contacts',
    Assets = 'assets',
}

export interface GetParams {
    page?: number
    perpage?: number
    filterByName?: string
    brandTag?: string[] | number[]
    workspace?: string[] | number[]
    assets: boolean
    contacts: boolean
    workspaces: boolean
}

export interface DataMetaReturn {
    data: BrandCard[],
    meta: ApiMeta
}

export const getBrandCards = (storeId: ID, params?: GetParams): Promise<DataMetaReturn> => {
    const endpoint = `/stores/${storeId}/brand_cards`;
    return apiHelper.get(endpoint, params);
};

export const createBrandCard = (storeId: ID, brandCard: BrandCard): Promise<BrandCard> => {
    return apiHelper.post(
        `/stores/${storeId}/brand_cards`,
        brandCard,
        undefined,
        {},
        {
            inlineErrorFields: ['name'],
        }
    );
};

export const getBrandCardById = (brandCardId: ID, params: Array<ParamsForBrandCard> = []): Promise<BrandCard> => {
    const paramsString = params.join('&') || '';
    return apiHelper.get(`/stores/brand_cards/${brandCardId}?${paramsString}`);
};

export const updateBrandCard = (storeId: ID, brandCardId: ID, brandCard: BrandCard): Promise<BrandCard> => {
    return apiHelper.put(
        `/stores/${storeId}/brand_cards/${brandCardId}`,
        brandCard,
        undefined,
        {},
        {
            inlineErrorFields: ['name'],
        }
    );
};

interface NoteParams {
    notes?: string
    warrantyNotes?: string
}
export const updateBrandCardNotes = (brandCardId: ID, params: NoteParams): Promise<BrandCard> => {
    return apiHelper.put(`/stores/brand_cards/${brandCardId}/notes`, params);
};

type UpsertBrandCardLogoReturn = {
    brandCard: BrandCard,
    logo: string,
};

export const upsertBrandCardLogo = (brandCardId: ID, formData?: FormData): Promise<UpsertBrandCardLogoReturn> => {
    return apiHelper.put(`stores/brand_cards/${brandCardId}/logo`, formData, {'Content-Type': 'multipart/form-data'});
};

export const populateMarketingLogos = (storeId: ID): Promise<BrandCard> => {
    return apiHelper.get(`stores/${storeId}/brand_cards/populate_logos`);
};

export const deleteBrandCard = (brandCardId: ID): Promise<void> => {
    return apiHelper.delete(`/stores/brand_cards/${brandCardId}`);
};

export const deleteBrandCards = (storeId: ID, brandCardIds: ID[]): Promise<void> => {
    return apiHelper.post(`/stores/${storeId}/brand_cards/delete`, {brandCardIds});
};

export const getSuggestBrand = (storeId: ID, search: string, count: number): Promise<SuggestedName[]> => {
    return apiHelper.get(`stores/${storeId}/brand_cards/suggest`, {search, count});
};

export const getApiKeyForBrandCard = (storeId: ID, brandCardId: ID): Promise<Record<'key', string>> => {
    return apiHelper.get(`stores/${storeId}/brand_cards/${brandCardId}/api_key`);
};

export const logBrandCardsViewed = (storeId: ID): Promise<void> => {
    return apiHelper.post(`/stores/${storeId}/brand_cards/activity`, {});
};
