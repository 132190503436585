export type ID = string | number | undefined; //usually set from getParams
export type API_ID = number; //usually set as a return from the database
export type Editable_ID = ID | API_ID; //any editable field or instance where an id could be from multiple sources

export enum AlignType {
    left = 'left',
    center = 'center',
    right = 'right',
}

export type typeFn = () => void;
export type typeFnPromise = () => Promise<void>;
export type genericFn = (...args: any[]) => any;
export type genericObj = {
    [key: string]: any
};

export type ReactEvent = React.ChangeEvent<HTMLInputElement>;
export type ReactTextareaEvent = React.ChangeEvent<HTMLTextAreaElement>;
export type ReactAnchorEvent = React.MouseEvent<HTMLElement>;

interface FieldError {
    field: string
    message: string
    rule: string
}

export interface ResponseError {
    errors: FieldError[]
    combinedMessage: string
}

//merge these two someday.... sigh
export interface Suggested {
    title: string
    searchName: string
}
export interface SuggestedName {
    name: string
    searchName: string
}

export interface ApiError {
    message: string
}

export interface ApiMeta {
    currentPage: number
    firstPage: number
    firstPageUrl: string
    lastPage: number
    lastPageUrl: string
    nextPageUrl: string | null
    perPage: number
    previousPageUrl: string | null
    total: number,
}

export interface Note {
    key: number,
    id: number,
    notes: string | undefined
}

type DeepPartialAny<T> = {
    [P in keyof T]?: T[P] extends AnyObject ? DeepPartialAny<T[P]> : any
};

type AnyObject = Record<string, any>;

//allows over-writing when extending an interface
export type ModifyDeep<A extends AnyObject, B extends DeepPartialAny<A>> = {
    [K in keyof A]: B[K] extends never
        ? A[K]
        : B[K] extends AnyObject
            ? ModifyDeep<A[K], B[K]>
            : B[K]
} & (A extends AnyObject ? Omit<B, keyof A> : A);
