import React from 'react';
import {AuthRoutes} from 'src/constants/userRoles';
import {useNavigate, useLocation, useParams} from 'react-router-dom';
import {parents} from 'src/Components/BrandCard';
import {BrandCard} from 'src/api/BrandCard/api-brand-card';
import {classnames} from 'src/utils/general';


interface Props {
    brandCard: Partial<BrandCard>
    origin?: keyof typeof parents,
    className?: string
}

const BrandRenderer = ({brandCard, origin, className = ''}: Props): JSX.Element => {
    const navigate = useNavigate();
    const location = useLocation();
    const {storeId} = useParams<{storeId: any}>();
    const classes = classnames(
        'brand-renderer single-line-ellipsis',
        {[className]: className},
        {'link-button': brandCard && !brandCard.isLimitedByPlan}
    );

    return (
        <>
            <div
                className={classes}
                title={brandCard && brandCard.name}
                onClick={() => {
                    if (brandCard && !brandCard.isLimitedByPlan) {
                        navigate(
                            `${AuthRoutes.storeBrands(storeId)}/${brandCard.id}`,
                            {
                                state: {
                                    from: origin || 'brands',
                                    search: location.search,
                                },
                            }
                        );
                    }
                }}>
                {brandCard && brandCard.name}
            </div>
        </>
    );
};

export default BrandRenderer;
