import _ from 'lodash';
import {genericFn} from 'src/Types/CommonTypes';
import {useEffect, useRef} from 'react';
import {ServerError} from 'src/redux/global/actionTypes';
import {message} from 'antd';

//replicate _.get functionality
export type lodashGetType = ((arg0: any) => any) | string | any[] | undefined;

/*
    -map and keyBy in one loop
    -map and accessor can be functions or _.get strings
    -accessor is optional, if not specified the filter will be used as the accessor
*/
export const mapKeyBy = (dataSet: Array<any>, map: lodashGetType, rawAccessor: lodashGetType): Record<any, any> => {
    const mappedAndRekeyedData: Record<any, any> = {};
    const accessor = rawAccessor || map;
    for (const data of dataSet) {
        mappedAndRekeyedData[
            typeof accessor === 'function' ? accessor(data) : _.get(data, accessor)
        ] = typeof map === 'function' ? map(data) : _.get(data, map);
    }
    return mappedAndRekeyedData;
};

//useSet will use a javascript Set instead of an array
export const mapGroupBy = (dataSet: Array<any>, map: lodashGetType, accessor: lodashGetType, useSet = false): Record<any, any> => {
    const mappedAndGroupedData: Record<any, any> = {};
    for (const data of dataSet) {
        const dataAccessor = typeof accessor === 'function' ? accessor(data) : _.get(data, accessor);
        if (!mappedAndGroupedData[dataAccessor]) {
            mappedAndGroupedData[dataAccessor] = useSet ? new Set() : [];
        }
        if (useSet) {
            mappedAndGroupedData[dataAccessor].add(typeof map === 'function' ? map(data) : _.get(data, map));
        } else {
            mappedAndGroupedData[dataAccessor].push(typeof map === 'function' ? map(data) : _.get(data, map));
        }
    }
    return mappedAndGroupedData;
};

export const findAndReturn = (dataObj: Record<any, any>, accessor: lodashGetType) => {
    if (!dataObj || !accessor) {
        return undefined;
    }
    for (const data of Object.values(dataObj)) {
        const test = typeof accessor === 'function' ? accessor(data) : _.get(data, accessor);
        if (test) {
            return test;
        }
    }
};


export const filterMapGroupBy = (dataSet: Array<any>, filter: lodashGetType, map: lodashGetType, accessor: lodashGetType, useSet = false): Record<any, any> => {
    const filteredMappedAndGroupedData: Record<any, any> = {};
    for (const data of dataSet) {
        if (typeof filter === 'function' ? filter(data) : _.get(data, filter)) {
            const dataAccessor = typeof accessor === 'function' ? accessor(data) : _.get(data, accessor);
            if (!filteredMappedAndGroupedData[dataAccessor]) {
                filteredMappedAndGroupedData[dataAccessor] = useSet ? new Set() : [];
            }
            if (useSet) {
                filteredMappedAndGroupedData[dataAccessor].add(typeof map === 'function' ? map(data) : _.get(data, map));
            } else {
                filteredMappedAndGroupedData[dataAccessor].push(typeof map === 'function' ? map(data) : _.get(data, map));
            }
        }
    }
    return filteredMappedAndGroupedData;
};

/*
    -filter and map in one loop
    -filter and accessor can be functions or _.get strings
    -accessor is optional, if not specified the filter will be used as the accessor
*/
export const filterMap = (dataSet: Array<any>, filter: lodashGetType, rawAccessor?: lodashGetType): Array<any> => {
    const filteredAndRemappedData = [];
    const accessor = rawAccessor || filter;
    for (const data of dataSet) {
        if (typeof filter === 'function' ? filter(data) : _.get(data, filter)) {
            filteredAndRemappedData.push(
                typeof accessor === 'function' ? accessor(data) : _.get(data, accessor)
            );
        }
    }
    return filteredAndRemappedData;
};

export const filterKeyBy = (dataSet: Array<any>, filter: lodashGetType, rawAccessor?: lodashGetType): Record<any, any> => {
    const filteredAndRekeyedData: Record<any, any> = {};
    const accessor = rawAccessor || filter;
    for (const data of dataSet) {
        if (typeof filter === 'function' ? filter(data) : _.get(data, filter)) {
            filteredAndRekeyedData[
                typeof accessor === 'function' ? accessor(data) : _.get(data, accessor)
            ] = data;
        }
    }
    return filteredAndRekeyedData;
};

export const filterAndSet = (dataSet: Array<any>, filter: lodashGetType, setObj = {}): Record<any, any> => {
    const filterAndSetData = [];
    for (const data of dataSet) {
        if (typeof filter === 'function' ? filter(data) : _.get(data, filter)) {
            filterAndSetData.push(
                Object.assign({}, data, setObj)
            );
        }
    }
    return filterAndSetData;
};

//uses match to get element of dataSet, then sets it to setObj
export const setToMatching = (dataSet: Array<any>, match: lodashGetType, setObj = {}): Record<any, any> => {
    const newSet = [];
    for (const data of dataSet) {
        if (typeof match === 'function' ? match(data) : _.get(data, match)) {
            newSet.push(Object.assign({}, data, setObj));
        } else {
            newSet.push(data);
        }
    }
    return newSet;
};

// native _.get doesnt cooperate well enough with typescript :(
//build out 2nd param to accept an array, like _.get
export const _get = (dataObj: Record<any, any> | undefined, accessor: lodashGetType, fallback: lodashGetType | undefined = undefined): any => {
    if (!dataObj || !accessor) {
        return undefined;
    }
    const buildAccessor = typeof accessor === 'function' ? accessor : (obj: any) => _.get(obj, accessor);
    const buildFallback = typeof fallback === 'function' ? fallback : () => fallback;
    return dataObj && (buildAccessor(dataObj) || buildFallback(dataObj));
};

export const attemptWithRetries = (totalRetries: number, func: (arg0: number) => any): void => {
    let error;
    for (const index of _.range(totalRetries)) {
        try {
            func(index);

            // If success, don't just break out of loop. We're done-done.
            return;
        } catch (err) {
            error = err;
        }
    }

    // If all out of attempts and none succeeded, hard fail
    throw error;
};

//is provided date older than secondsAgo
export const isDateOld = (date: Date, secondsAgo: number): boolean => {
    const timeAgo = Date.now() - date.getTime();

    return timeAgo > (secondsAgo * 1000);
};

export const invisibleCharacter = '‎';

export const promiseState = (p: Promise<any>): Promise<string> => {
    const t = {};
    return Promise.race([p, t]).then((v) => (v === t)? 'pending' : 'fulfilled', () => 'rejected');
};

export const usePrevious = (value: any): any => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value; //assign the value of ref to the argument
    }, [value]); //this code will run when the value of 'value' changes
    return ref.current; //in the end, return the current ref value.
};

/* MATH */
export const toRadians = (angle: number): number => {
    return angle * (Math.PI / 180);
};

export const toDegrees = (radians: number): number => {
    return radians * (180 / Math.PI);
};

interface FileSizeNearestUnitResult {
    reducedSize: number
    unit: string
}

const units = [
    'b',
    'kb',
    'mb',
    'gb',
    'tb',
    'pb',
];

export const getFileSizeNearestUnit = (size: number, decimalPlaces = 0): FileSizeNearestUnitResult => {
    let reducedSize = size;
    let unitIncrement = 0;
    while (reducedSize > 999) {
        reducedSize = reducedSize / 1000;
        unitIncrement++;
    }
    return {
        reducedSize: _.round(reducedSize, decimalPlaces),
        unit: units[unitIncrement],
    };
};


export const displayFileSize = (size?: number | null, decimalPlaces = 0): string => {
    if (size || size === 0) {
        const {reducedSize, unit} = getFileSizeNearestUnit(size, decimalPlaces);
        return `${reducedSize} ${unit}`;
    }
    return '';
};

/**
 * Returns a comparator using String.prototype.localeCompare with our standard options
 * @param languageCode
 * @returns {function(*, *): number}
 */
export const getLocaleComparator = (languageCode: string) => {
    return (val1: string, val2: string) => {
        return val1.toLocaleLowerCase(languageCode).localeCompare(
            val2.toLocaleLowerCase(languageCode),
            languageCode,
            {ignorePunctuation: true, numeric: true, sensitivity: 'base'}
        );
    };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const isIterable = (obj: any, includeStrings = false): boolean => {
    return obj && (_.isObject(obj) || _.isArray(obj) || (includeStrings && _.isString(obj)));
};

export const classnames = (...args: Array<Record<string, string | boolean | undefined> | string>): string => {
    const classes = [];
    for (const arg of args) {
        if (_.isObject(arg) && Object.values(arg)[0]) {
            classes.push(Object.keys(arg)[0]);
        } else if (_.isString(arg) && arg !== '') {
            classes.push(arg);
        }
    }
    return classes.join(' ');
};

/*
 * A simple debouncer, mostly intended to reduce FE ajax calls
 */
export const debouncePromise = (
    wait = 300,
    functionReturningPromise: genericFn
): any => {
    let counter = 0;
    const method = async function() {
        counter++;
        const current = counter;
        if (counter === current) {
            //eslint-disable-next-line prefer-rest-params
            const results = await functionReturningPromise(...arguments);
            return results;
        }
    };
    return _.debounce(method, wait, {
        'leading': false,
        'trailing': true,
    });
};

export const buildUrlFromPartial = (partial?: string): string => {
    if (!partial) {
        return '';
    }
    if (!partial.match(/^https?:\/\//i)) {
        partial = `http://${partial}`;
    }
    return partial;
};

export const handleUrlClick = (url: string | undefined) => {
    return (event: MouseEvent): void => {
        event.stopPropagation();
        if (url) {
            const openInNewTab = (event.metaKey || event.ctrlKey) || false;
            url = buildUrlFromPartial(url);
            if (openInNewTab) {
                window.open(url);
            } else {
                window.location.href = url;
            }
        }
    };
};

export const textToClipboard = (copyText: string, successMessage = 'Link copied to clipboard!'): void => {
    //note: does not work in a non-https environment
    navigator.clipboard.writeText(copyText);
    message.success(successMessage);
};

export const serverErrorMatchesKey = (key: string, serverError: ServerError, ignoreUrlElementsCount = 0): boolean => {
    const url = ignoreUrlElementsCount
        ? serverError.config.url.split('/').slice(0, ignoreUrlElementsCount * -1).join('/')
        : serverError.config.url;

    return _.camelCase(url.replace(/[0-9]/g, '')) === key;
};

export const getServerError = (key: string, serverErrors: ServerError[], ignoreUrlElementsCount = 0) => {
    const serverError = _.find(serverErrors, (serverError) => {
        if (serverErrorMatchesKey(key, serverError, ignoreUrlElementsCount)) {
            return serverError.error;
        }
    });
    return serverError;
};

//`key` is a string constructed from the URL used to call the endpoint that surfaced the error
//example: /api/stores/199/users/99 would have a `key` of 'storesUsers'
//use ignoreUrlElementsCount to remove X number of elements from the URL before it is compared
//example: /api/stores/214/test/lyhd7-asdlifh7-asd3-lh782m923d could have the last portion removed by passing "1"
export const getErrorMessage = (key: string, serverErrors: ServerError[], ignoreUrlElementsCount = 0) => {
    const serverError = getServerError(key, serverErrors, ignoreUrlElementsCount);
    return _.get(serverError, 'error');
};

//used especially for cloudwatch, this removes all length/depth limiting and handles circular references
// export const deepInspect = (anything: any): string => {
//     return util.inspect(anything, {depth: 8, maxStringLength: null, maxArrayLength: null});
// };
