import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useParams, useNavigate, useLocation} from 'react-router-dom';
import {nanoid} from 'nanoid';
import './styles.sass';
import _ from 'lodash';

//icons
import {LeftOutlined, PlusOutlined, DeleteFilled} from '@ant-design/icons';
import {Kabob, Pen} from 'src/lib/svg-icon-helper';
import disabledLogo from 'src/assets/images/bk-disabled-2.svg';

//components
import {Upload, message} from 'antd';
import DropdownButton from 'src/Components/DropdownButton';
import Button from 'src/Components/Button';
import ControlHeader from 'src/Components/ControlHeader';
import Loading from 'src/Components/Loading';
import {Prompt} from 'src/Components/ModalPrompt';
import StoreEdit from 'src/Components/StoreEdit';
import Table from 'src/Components/Table';
import TeamCreate from 'src/Components/TeamCreate';
import TeamEdit from 'src/Components/TeamEdit';

//apis
import {
    getUsers,
    User,
    deleteUser,
    resendInvite,
    resetPassword,
} from 'src/api/Admin/User/api-admin-user';
import {
    getStoreById,
    Store,
    upsertStoreLogo,
    forceRecalulationOfPlanLimitationsForStore,
} from 'src/api/Admin/Store/api-admin-store';

//types
import {ID} from 'src/Types/CommonTypes';
import {Roles, RolesTypes} from 'src/Types/StoreRoles/types';
import {fileTypes} from 'src/constants/fileTypes';
import {UploadRequestOption as RcCustomRequestOptions} from 'rc-upload/lib/interface';

//libs
import {Filters, Filter, handleFilterValidations, removeFilterFactory, GLOBAL_FILTER_DEBOUNCE_RATE} from 'src/lib/filter-helper';
import {classnames, displayFileSize, debouncePromise} from 'src/utils/general';
import {getQueryParams, HistoryHelperFactory} from 'src/lib/url';
import {formatDate} from 'src/lib/date-helper';
import userStatusFilter from 'src/lib/filters/user-status';
import userRoleFilter from 'src/lib/filters/user-role';

interface FilterDependencies {
    allUsers?: User[]
}

const StoreDetail = (): JSX.Element => {
    const navigate = useNavigate();
    const queryParams = getQueryParams(useLocation());
    const historyHelper = HistoryHelperFactory(navigate);
    const {adminStoreId} = useParams<{adminStoreId: any}>();
    const [textSearchValue, setTextSearchValue] = useState(queryParams.textSearch || '');
    const [store, setStore] = useState<Store>();
    const [users, setUsers] = useState<User[]>();
    const [allUsers, setAllUsers] = useState<User[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [showCreateUsersModal, setShowCreateUsersModal] = useState<boolean>(false);
    const [showTeamEditModal, setShowTeamEditModal] = useState<boolean>(false);
    const [isHoveringStoreImage, setIsHoveringStoreImage] = useState<boolean>(false);
    const [isUploadingLogo, setIsUploadingLogo] = useState<boolean>(true);
    const [selectedUser, setSelectedUser] = useState<User>();

    const callId = useRef<string | null>(null);

    const [filterData, setFilterData] = useState<any>();
    const [filterValues, setFilterValues] = useState<any>({});

    const initialFilters = [
        userStatusFilter,
        userRoleFilter,
    ];
    const rawFilters = _.keyBy(initialFilters, 'key');
    const filterDependencies: FilterDependencies = {
        allUsers,
    };
    const [filters, setFilters] = useState<Record<string, Filter>>(
        _.omitBy(rawFilters, (filter) => {
            return !filter.enabled(filterDependencies) && !filter.show(filterDependencies);
        })
    );

    const getFilters = (filterDependencies: FilterDependencies) => {
        const initialFilters = [
            userStatusFilter,
            userRoleFilter,
        ];
        //filters here must still be key'ed to their respective 'key'
        const rawFilters = _.keyBy(initialFilters, 'key');
        const filters = _.omitBy(
            rawFilters,
            (filter) => !filter.enabled(filterDependencies) && !filter.show(filterDependencies)
        );
        setFilters(filters);
    };

    const getStoreHandler = useCallback(async(
        currentCallId,
        {
            init = false,
            textSearchValue = undefined,
            filterValuesFromState = filterValues,
        }
    ) => {
        if (callId.current === currentCallId) {
            setIsLoading(true);
            const searchObject = {};
            if (textSearchValue) {
                searchObject.textSearch = textSearchValue;
            }
            for (const [filterKey, filterValue] of Object.entries(filterValuesFromState)) {
                if (_.get(filters, [filterKey, 'transformForAPI'])) {
                    filters[filterKey].transformForAPI(filterValue, searchObject, filterKey, filterDependencies);
                }
            }
            const finalSearchObject = init ? queryParams : searchObject;
            if (init) {
                const [store, allUsers] = await Promise.all([
                    getStoreById(adminStoreId),
                    getUsers(adminStoreId),
                ]);
                setAllUsers(allUsers);
                setStore(store);

                getFilters({
                    allUsers,
                });

                const {
                    updatedQueryParamObject,
                    urlFilterValues,
                    didFiltersObviouslyChange,
                } = handleFilterValidations(
                    filters,
                    {
                        allUsers,
                    },
                    queryParams
                );

                setFilterValues(urlFilterValues);
                historyHelper.updateQueryParams(updatedQueryParamObject, false);
                if (didFiltersObviouslyChange) {
                    //filters changed, short-circuit this request and fire new query
                    const currentCallId = nanoid();
                    // eslint-disable-next-line require-atomic-updates
                    callId.current = currentCallId;
                    getStoreHandler(
                        currentCallId,
                        {filterValuesFromState: urlFilterValues}
                    );
                    return;
                } else {
                    const users = await getUsers(adminStoreId, updatedQueryParamObject);
                    setUsers(users);
                }
            } else {
                const users = await getUsers(adminStoreId, finalSearchObject);
                setUsers(users);
                historyHelper.updateQueryParams(searchObject);
            }
            setIsLoading(false);
        }
    }, []);

    const initFilters = (filterDependenciesInit = filterDependencies) => {

        const filterData: Filters = {};
        for (const [filterKey, filter] of Object.entries(filters)) {
            if (!filter.loadAfter && filter.show(filterDependenciesInit) && filter.enabled(filterDependenciesInit)) {
                filterData[filterKey] = filter.dataBuilder(filterDependenciesInit);
            }
        }
        //filter data is the processed and assigned data from `dataBuilder`
        setFilterData(filterData);
    };

    if (!isLoading && filters && !filterData && _.every(Object.values(filterDependencies))) {
        initFilters();
    }

    useEffect(() => {
        const currentCallId = nanoid();
        callId.current = currentCallId;
        getStoreHandler(currentCallId, {init: true});
    }, [adminStoreId]);

    useEffect(() => {
        if (store && !isLoading) {
            const currentCallId = nanoid();
            callId.current = currentCallId;
            debouncePromise(
                GLOBAL_FILTER_DEBOUNCE_RATE,
                () => getStoreHandler(currentCallId, {textSearchValue, filterValuesFromState: filterValues})
            )();
        }
    }, [filterValues, textSearchValue]);

    useEffect(() => {
        if (allUsers) {
            initFilters({
                allUsers,
            });
        }
    }, [allUsers]);

    const handleSetFilter = (filterValues: Record<any, any>[]) => {
        setFilterValues(filterValues);
    };

    const handleHover = () => {
        setIsHoveringStoreImage(true);
    };

    const handleHoverOff = () => {
        setIsHoveringStoreImage(false);
    };

    const handleDeleteImage = async() => {
        if (store) {
            setStore(Object.assign({}, store, {logoUrl: undefined}));
            await upsertStoreLogo(store.id);
        }
    };

    const hasStoreImage = Boolean(store && store.logoUrl);
    const addStoreLogo = async(info: RcCustomRequestOptions) => {
        if (store) {
            setIsUploadingLogo(true);
            const formData = new FormData();
            if (info) {
                formData.append('file', info.file);
            }
            formData.append('name', info.filename || '');
            const storeReturn = await upsertStoreLogo(store.id, formData);
            setStore(Object.assign({}, store, {logoUrl: storeReturn.logo}));
        }
    };

    const imageIsLoaded = () => {
        if (store) {
            setIsUploadingLogo(false);
        }
    };

    const handleStoreEditModalClose = (shouldReload = false) => {
        if (shouldReload) {
            const currentCallId = nanoid();
            callId.current = currentCallId;
            getStoreHandler(currentCallId, {init: true, textSearchValue});
        }
        setShowEditModal(false);
    };

    const handleTeamEditModalClose = (shouldReload = false) => {
        if (shouldReload) {
            const currentCallId = nanoid();
            callId.current = currentCallId;
            getStoreHandler(currentCallId, {init: false, textSearchValue, filterValuesFromState: filterValues});
        }
        setShowTeamEditModal(false);
    };

    const handleTeamCreateModalClose = (shouldReload = false) => {
        if (shouldReload) {
            const currentCallId = nanoid();
            callId.current = currentCallId;
            getStoreHandler(currentCallId, {init: false, textSearchValue, filterValuesFromState: filterValues});
        }
        setShowCreateUsersModal(false);
    };

    const handleDeleteUser = async(id: ID) => {
        await new Prompt().yesNoDanger(
            {
                body: 'Are you sure you want to delete this user?',
                title: 'Delete User',
                displayType: 'delete',
                onYes: async() => {
                    await deleteUser(store && store.id, id);
                    const currentCallId = nanoid();
                    callId.current = currentCallId;
                    getStoreHandler(currentCallId, {init: false, textSearchValue, filterValuesFromState: filterValues});
                },
            }
        );
    };

    const handleEditUser = (user?: User) => {
        setSelectedUser(user);
        setShowTeamEditModal(true);
    };

    const handleResetPassword = async(userId: ID) => {
        message.loading({content: 'Sending Password reset email...', key: 'RESET_PASSWORD'});
        await resetPassword(adminStoreId, userId);
        message.success({content: 'Password reset email sent', key: 'RESET_PASSWORD'});

    };
    const handleResendInvite = async(userId: ID) => {
        message.loading({content: 'Resending Invite...', key: 'RESEND_INVITE'});
        await resendInvite(adminStoreId, userId);
        message.success({content: 'Invite Resent', key: 'RESEND_INVITE'});
    };

    const handleReturnToDashboard = () => {
        navigate('/admin/stores');
    };

    return (
        <div className='store-detail'>
            <div className='store-detail-header'>
                <div className='header-toolbar'>
                    <div className='toolbar-return pointer' onClick={() => handleReturnToDashboard()}>
                        <Button className='btn-icon btn-primary btn-circle'><LeftOutlined /></Button>
                        STORES DASHBOARD
                    </div>
                </div>
                <div className='store-detail-body'>
                    <div
                        className='store-main-image-container'
                        onMouseOver={handleHover}
                        onMouseLeave={handleHoverOff}>
                        {store && (
                            <img
                                className='store-main-image'
                                src={hasStoreImage
                                    ? `${BASE_API || BASE_URL}${store.logoUrl}`
                                    : disabledLogo}
                                onLoad={imageIsLoaded} />
                        )}
                        {!store && (
                            <img
                                className='store-main-image'
                                src={disabledLogo} />
                        )}
                        {isUploadingLogo && (
                            <Loading fill size={56} />
                        )}
                        {store && !isUploadingLogo && (
                            <div className={classnames(
                                'main-image-controls',
                                {'is-hovered': isHoveringStoreImage}
                            )}>
                                <Upload
                                    accept={fileTypes.image.join(', ')}
                                    className='store-image-upload'
                                    name='file'
                                    showUploadList={false}
                                    customRequest={addStoreLogo}>
                                    <Button
                                        className='btn-white btn-circle btn-icon'>
                                        <Pen className='edit-store-image-icon' />
                                    </Button>
                                </Upload>
                                {hasStoreImage && (
                                    <Button
                                        className='btn-white btn-circle btn-icon'
                                        onClick={handleDeleteImage}>
                                        <DeleteFilled />
                                    </Button>
                                )}
                            </div>
                        )}
                    </div>
                    <div className='store-detail-info'>
                        <div className='store-title-block'>
                            <div className='store-title'>
                                {store ? store.title : ' '}
                            </div>
                        </div>
                        <div className='store-detail-info-block'>
                            <div className='store-detail-info-row'>
                                <span className='info-row-title'>Account Number #:</span>
                                {_.get(store, 'accountNumber', '')}
                            </div>
                            <div className='store-detail-info-row'>
                                <span className='info-row-title'>Store Created:</span>
                                {formatDate(_.get(store, 'createdAt'))}
                            </div>
                            <div className='store-detail-info-row'>
                                <span className='info-row-title'>Next Billing Amount:</span>
                                {Boolean(store && store.nextBill) && (
                                    <span>{(store?.nextBill / 100).toLocaleString('en-US', {style: 'currency', currency: 'USD'})}</span>
                                )}
                                {!(store && store.nextBill) && (
                                    <span>-</span>
                                )}
                            </div>
                            <div className='store-detail-info-row'>
                                <span className='info-row-title'>Next Billing Date:</span>
                                {store && store.planEnd && (
                                    <span>{formatDate(store.planEnd)}</span>
                                )}
                                {!(store && store.planEnd) && (
                                    <span>-</span>
                                )}
                            </div>
                        </div>
                        <div className='store-detail-info-block'>
                            <div className='store-detail-info-row'>
                                <span className='info-row-title'>Plan:</span>
                                {store && _.capitalize(store.nextPlan || store.planName)}
                                {store && store.nextPlan && (
                                    <span> ({store.planName} until {formatDate(store.planEnd)})</span>
                                )}
                                {store && store.hasNoPlan && (
                                    <span className='delete-color'>STORE HAS NO PLAN! SHOULD NOT HAPPEN, BLAME A DEV</span>
                                )}
                            </div>
                            <div className='store-detail-info-row'>
                                <span className='info-row-title'>Number of Brand Cards:</span>
                                {_.get(store, 'brandCardCount', 0)}
                            </div>
                            <div className='store-detail-info-row'>
                                <span className='info-row-title'>Storage Used:</span>
                                {(store && store.assetUsage) ? displayFileSize(store.assetUsage) : '0 mb'}
                            </div>
                            <div className='store-detail-info-row'>
                                <span className='info-row-title'>Keepmail Address:</span>
                                {(store && store.useKeepMail && store.assetMailbox)
                                    ? `${store.assetMailbox}${store.assetMailboxSuffix}`
                                    : 'Disabled'}
                            </div>
                        </div>
                    </div>
                    <div className='store-detail-controls'>
                        {store && (
                            <>
                                <div
                                    className='btn btn-action'
                                    onClick={() => {
                                        setShowEditModal(true);
                                    }}>
                                    Edit Store Info
                                </div>
                                <div
                                    className='btn btn-action'
                                    onClick={async() => {
                                        try {
                                            await forceRecalulationOfPlanLimitationsForStore(store.id);
                                            message.success('Success');
                                        } catch (error) {
                                            message.error('Fail');
                                        }
                                    }}>
                                    Force Plan Limits Recalc
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className='store-users'>
                <div className='store-users-header'>
                    <ControlHeader
                        setSearch={setTextSearchValue}
                        search={textSearchValue}
                        disableSearch={isLoading}
                        placeholder='Search by user name'
                        handleSetFilter={handleSetFilter}
                        handleRemoveFilter={removeFilterFactory(filterValues, handleSetFilter)}
                        filterValues={filterValues}
                        filterData={filterData}
                        filters={filters} />
                    <Button
                        className='btn btn-action add-user-button'
                        onClick={() => setShowCreateUsersModal(true)}>
                        <PlusOutlined className='icon left-icon' />Team Member
                    </Button>
                </div>
                {isLoading && (
                    <Loading fill size={56} />
                )}
                {!isLoading && (
                    <Table
                        columns={[
                            {
                                title: 'First',
                                dataIndex: 'firstName',
                                key: 'firstName',
                                width: '12%',
                                ellipsis: true,
                            },
                            {
                                title: 'Last',
                                dataIndex: 'lastName',
                                key: 'lastName',
                                width: '12%',
                                ellipsis: true,
                            },
                            {
                                title: 'Email',
                                dataIndex: 'email',
                                key: 'email',
                                ellipsis: true,
                                render: (email) => <a href={`mailto:${email}`}>{email}</a>,
                            },
                            {
                                title: 'Permissions',
                                dataIndex: 'storeRoles',
                                key: 'storeRoles',
                                ellipsis: true,
                                width: '12%',
                                render: (UNUSED: any, user: User) =>
                                    _.get(user, ['storeRoles', 0, 'role']) && Roles[user.storeRoles[0].role as keyof RolesTypes],
                            },
                            {
                                title: 'Status',
                                dataIndex: 'isActive',
                                key: 'isActive',
                                width: 72,
                                ellipsis: true,
                                render: (UNUSED: any, user: User) => user.isActive ? 'Active' : 'Inactive',
                            },
                            {
                                title: 'Last Login',
                                dataIndex: 'lastActive',
                                key: 'lastActive',
                                width: 105,
                                ellipsis: true,
                                render: (UNUSED: any, user: User) => Boolean(user.activities && user.activities.length)
                                    && formatDate(user.activities[0].createdAt),
                            },
                            {
                                key: 'actions',
                                className: 'actions-td',
                                width: 55,
                                // eslint-disable-next-line react/display-name
                                render: (UNUSED: any, user: User) => (
                                    <DropdownButton
                                        className='action-button primary-color'
                                        items={[
                                            {
                                                key: 'edit',
                                                onClick: () => handleEditUser(user),
                                                text: 'Edit',
                                            },
                                            {
                                                key: 'reset',
                                                disabled: !user.isActive,
                                                onClick: () => handleResetPassword(user.id),
                                                text: 'Reset Password',
                                            },
                                            {
                                                key: 'resend',
                                                visible: !user.isActive,
                                                onClick: () => handleResendInvite(user.id),
                                                text: 'Resend Invite',
                                            },
                                            {
                                                key: 'remove',
                                                onClick: () => handleDeleteUser(user.id),
                                                text: 'Remove User',
                                            },
                                        ]}>
                                        <Kabob />
                                    </DropdownButton>
                                ),
                            },
                        ]}
                        data={users}
                        shouldScroll={true}
                        id='STORE_DETAIL_TABLE'
                        scrollParentSelector='.store-detail-table .ant-table-body'
                        className='store-detail-table' />
                )}
            </div>
            {store && (
                <StoreEdit
                    canUseKeepMail={Boolean(_.get(store, 'brandCardCount'))}
                    isModalOpen={showEditModal}
                    onModalClose={handleStoreEditModalClose}
                    isAdmin={true}
                    store={store} />
            )}
            <TeamEdit
                isModalOpen={showTeamEditModal}
                onModalClose={handleTeamEditModalClose}
                isAdmin={true}
                storeId={store && store.id}
                user={selectedUser} />
            <TeamCreate
                isModalOpen={showCreateUsersModal}
                onModalClose={handleTeamCreateModalClose}
                isAdmin={true}
                storeId={store && store.id} />
        </div>
    );
};

export default StoreDetail;
