import apiHelper from '../api-helper';
import {ID} from 'src/Types/CommonTypes';
import {StoreStatus} from 'src/redux/storeStatus/actionTypes';

export interface Store {
    id: ID
    title: string
    description?: string
    logoUrl?: string
    storage?: number
    assetMailbox?: string
    assetMailboxSuffix?: string
    useKeepMail?: boolean
    assetUsage?: number
}

export interface GetPaymentInfoResponse {
    creditCardType: 'VISA' | 'MC' | 'AMEX' | 'DISC'
    creditCardLastFour: string
    expiry: string
}

export const getStores = (): Promise<Store[]> => {
    return apiHelper.get('/stores');
};

export const getStoreStatus = (accessToken?: string | null): Promise<StoreStatus[]> | void => {
    if (accessToken) {
        return apiHelper.getWithAccessToken(
            accessToken,
            '/stores/status',
            {},
            {
                transformKeysToSnakeCase: true,
                useNoAuth: true,
            }
        );
    } else {
        try {
            return apiHelper.get(
                '/stores/status',
                {},
                {
                    transformKeysToSnakeCase: true,
                    useNoAuth: true,
                    showDefaultError: false,
                    showErrorAndThrow: false,
                    rawHeaders: {
                        'Cache-Control': 'no-cache',
                        Pragma: 'no-cache',
                        Expires: '0',
                    },
                }
            );
        } catch (e) {
            //handled by <RootError>
        }
    }
};

export const getStoreById = (storeId: ID): Promise<Store> => {
    return apiHelper.get(`/stores/${storeId}`);
};

export const createStore = (store: Store): Promise<Store> => {
    return apiHelper.post('/stores', store);
};

export const updateStore = (storeId: ID, store: Store): Promise<Store> => {
    return apiHelper.put(`/stores/${storeId}`, store);
};

export const deleteStore = (storeId: ID): Promise<void> => {
    return apiHelper.delete(`/stores/${storeId}`);
};

export const subscribeStore = (storeId: ID, planId: ID, subscriptionInfo?: any) => {
    return apiHelper.post(`stores/${storeId}/subscribe/${planId}`, subscriptionInfo);
};

export const savePaymentInfo = (storeId: ID, subscriptionInfo?: any) => {
    return apiHelper.post(`stores/${storeId}/payment_info`, subscriptionInfo);
};

export const getPaymentInfo = (storeId: ID): Promise<GetPaymentInfoResponse | null> => {
    return apiHelper.get(`stores/${storeId}/payment_info`);
};

export const upsertStoreLogo = (storeId: ID, formData?: FormData): Promise<Store> => {
    return apiHelper.put(`/stores/${storeId}/logo`, formData, {'Content-Type': 'multipart/form-data'});
};

export const validateKeepmail = (storeId: ID, email: string): Promise<Record<'mailboxUsed', boolean>> => {
    return apiHelper.post(`stores/${storeId}/validateKeepmail`, {email});
};
