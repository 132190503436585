import {nanoid} from 'nanoid';
import {CloseCircleFilled, CloseOutlined, CaretDownOutlined} from '@ant-design/icons';
import {FilterIcon, CheckboxIcon, Checkmark, Sort} from 'src/lib/svg-icon-helper';
import {Popover} from 'antd';
import React, {useState} from 'react';
import DropdownButton from 'src/Components/DropdownButton';
import InputSearch from 'src/Components/InputSearch';
import {Filter, onChangeCb} from 'src/lib/filter-helper';
import {ReactEvent, genericFn} from 'src/Types/CommonTypes';
import {classnames} from 'src/utils/general';
import _ from 'lodash';
import './styles.sass';

interface sortOption {
    key: string
    title: string
    isReversed?: boolean
    func?: genericFn
    useNaturalSort?: boolean
    shouldSortEmpty?: boolean
}

interface Props {
    setSearch: (newValue: string) => void
    search: string
    placeholder: string
    multiSelectActions: any[]
    filterButtonId?: string
    multiSelectActionsId?: string
    filters: Record<'string', Filter>
    filterData: Record<'string', any[]>
    handleSetFilter: onChangeCb
    disableSearch: boolean
    filterValues: any
    handleRemoveFilter: genericFn
    showSort?: boolean
    sortOptions?: sortOption[]
    setSortOption?: genericFn
    activeSort?: sortOption
}

const ControlHeader: React.FC<Props> = ({
    setSearch,
    search,
    placeholder,
    multiSelectActions,
    filterButtonId,
    multiSelectActionsId,
    filters,
    filterData,
    filterValues,
    handleSetFilter,
    handleRemoveFilter,
    disableSearch,
    showSort = false,
    sortOptions,
    setSortOption,
    activeSort,
}) => {
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const handleVisibleChange = (visible: boolean) => {
        setIsFilterOpen(visible);
    };

    const handleSearchChange = (event: ReactEvent) => {
        return setSearch(event.target.value);
    };

    const handleRemoveFilterFactory = (key, child, filter, valueKey) => {
        return () => {
            handleRemoveFilter(key, child, filter, valueKey);
        };
    };

    const handleClearFilters = () => {
        handleSetFilter({});
    };

    const getValueKey = (appliedFilters, filter, filterKey, filters) => {
        const valueKey = filter[filters[filterKey].valueKey || 'id'];
        return filters[filterKey].valueKey === 'ARRAY___INDEX' ? appliedFilters[filterKey].indexOf(filter) : valueKey;
    };

    const renderFilterDropdown = (displayKey, child, key, filter, isLast, shouldShow, valueKey) => {
        return (shouldShow && (
            <div key={displayKey} className='applied-filter-container'>
                <a
                    className='applied-filter__remove-button'
                    onClick={handleRemoveFilterFactory(key, child, filter, valueKey)}>
                    <CheckboxIcon filled />
                </a>
                <div className={classnames(
                    'applied-filter single-line-ellipsis',
                    {capitalize: !filter.preserveFormatting},
                    _.kebabCase(filter.key)
                )}
                onClick={handleRemoveFilterFactory(key, child, filter, valueKey)}>
                    {filter.preserveFormatting ? child : _.toLower(child)}
                </div>
            </div>
        )) || null;
    };

    const renderFilterHeader = (displayKey, child, key, filter, isLast, shouldShow, valueKey) => {
        return (shouldShow && (
            <div key={displayKey} className='applied-filter-container tag'>
                <div className={classnames(
                    'applied-filter single-line-ellipsis',
                    {capitalize: !filter.preserveFormatting},
                    _.kebabCase(filter.key)
                )}>
                    {filter.preserveFormatting ? child : _.toLower(child)}
                </div>
                <a
                    className='applied-filter__remove-button'
                    onClick={handleRemoveFilterFactory(key, child, filter, valueKey)}>
                    <CloseOutlined />
                </a>
            </div>
        )) || null;
    };

    const appliedFilters = _.pickBy(filterValues, (values, key) => {
        return filters && filters[key];
    });

    const hasFilters = (!_.isEmpty(filters) && !_.isEmpty(filterData));
    const hasAppliedFilters = _.some(Object.values(appliedFilters), 'length');
    const lastKey = hasAppliedFilters && _.findLastKey(appliedFilters);

    const appliedFiltersDisplay = (renderFilter) => {
        return hasAppliedFilters && (Object.keys(appliedFilters) || []).map((filterKey) => {
            // Multi-select filters
            return _.isArray(appliedFilters[filterKey])
                ? appliedFilters[filterKey].map(
                    (filter, index) => renderFilter(
                        nanoid(),
                        filters[filterKey].detailRenderer(filter, filterData),
                        filterKey,
                        filters[filterKey],
                        filterKey === lastKey && (index + 1) === appliedFilters[filterKey].length,
                        filters[filterKey].filterValidationOnChange
                            ? filters[filterKey].filterValidationOnChange(filter, filterData)
                            : true,
                        getValueKey(appliedFilters, filter, filterKey, filters)
                    )
                )
                : renderFilter(
                    filterKey,
                    filters[filterKey].detailRenderer(appliedFilters[filterKey], filterData),
                    filterKey,
                    filters[filterKey],
                    filterKey === lastKey,
                    filters[filterKey].filterValidationOnChange
                        ? filters[filterKey].filterValidationOnChange(appliedFilters[filterKey], filterData)
                        : true,
                    getValueKey(appliedFilters, appliedFilters[filterKey], filterKey, filters)
                );
        });
    };

    const hasSort = Boolean(showSort && sortOptions && sortOptions.length && setSortOption);

    return (
        <div className='controlHeader'>
            <div className='control-block'>
                {Boolean(multiSelectActions && multiSelectActions.length) && (
                    <DropdownButton
                        className='multi-select-actions text-color'
                        id={multiSelectActionsId}
                        items={multiSelectActions}>
                        <>
                            <CaretDownOutlined /> Actions
                        </>
                    </DropdownButton>
                )}
                <InputSearch
                    name='search'
                    preventInput={disableSearch}
                    onChange={handleSearchChange}
                    value={search}
                    placeholder={placeholder} />
                <div>
                    {hasFilters && (
                        <Popover
                            placement='bottomLeft'
                            open={isFilterOpen}
                            content={
                                <div className='filters'>
                                    <div className='header'>Filters</div>
                                    {hasAppliedFilters && (
                                        <div className='selected-filters'>
                                            <div className='selected-filter-header'>
                                                <span>Selected</span>
                                                <a
                                                    className='clear-filters'
                                                    onClick={handleClearFilters}>
                                                    All <CloseCircleFilled />
                                                </a>
                                            </div>
                                            {appliedFiltersDisplay(renderFilterDropdown)}
                                        </div>
                                    )}
                                    {_.map(
                                        filters,
                                        (filter) => {
                                            return (
                                                <span key={filter.key}>
                                                    <div className='filterTitle'>{filter.name}</div>
                                                    {filter.component(filterValues, handleSetFilter, filterData)}
                                                </span>
                                            );
                                        }
                                    )}
                                </div>
                            }
                            onOpenChange={handleVisibleChange}
                            trigger='click'>
                            <div className='filterButton' id={filterButtonId}>
                                <FilterIcon className='left-icon' /> Filter
                            </div>
                        </Popover>
                    )}
                </div>
                {hasSort && (
                    <div className='sort-container'>
                        <div className='sort-button primary-color' onClick={() => {
                            if (activeSort && setSortOption) {
                                setSortOption(Object.assign({}, activeSort, {isReversed: !activeSort.isReversed}));
                            }
                        }}>
                            <Sort down={activeSort && activeSort.isReversed} up={activeSort && !activeSort.isReversed} />
                        </div>
                        <DropdownButton
                            className='sort-options text-color'
                            items={sortOptions && sortOptions.map((sort) => ({
                                key: sort.key,
                                onClick: () => {
                                    if (setSortOption) {
                                        setSortOption(sort);
                                    }
                                },
                                optionRenderer: (props: any) => {
                                    const isActive = activeSort && activeSort.key === sort.key;
                                    return (
                                        <a className={classnames(
                                            'dropdown-menu-button sort-options-button',
                                            {'sort-options-button--is-active': isActive}
                                        )} {...props}>
                                            {sort.title}
                                            {isActive && (
                                                <Checkmark className='primary-color' />
                                            )}
                                            {!isActive && (
                                                <span className='spacer' />
                                            )}
                                        </a>
                                    );
                                },
                            }))}>
                            Sort By
                        </DropdownButton>
                    </div>
                )}
            </div>
            {hasFilters && hasAppliedFilters && (
                <div className='applied-filters-block'>
                    {appliedFiltersDisplay(renderFilterHeader)}
                    <a
                        className='clear-filters'
                        onClick={handleClearFilters}>
                        Clear All <CloseCircleFilled />
                    </a>
                </div>
            )}
        </div>
    );
};

export default ControlHeader;
