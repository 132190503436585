import React, {useCallback, useEffect, useState} from 'react';
import {genericFn} from 'src/Types/CommonTypes';
import _ from 'lodash';

interface Props {
    children: React.ReactElement
    incrementAmount: number
    onChange: genericFn

    // this is used ONLY for it's length and detecting when you've hit the bottom
    // once bottom is hit, onChange stops firing
    totalItemCount: number

    scrollerEnd: number
    scrollParentSelector: string

    // This is the number of pixels before the bottom that you'd like the onChange to fire
    pixelLead: number
}

const InfiniteScroll: React.FC<Props> = ({
    children,
    scrollParentSelector,
    onChange,
    pixelLead = 1,
    scrollerEnd,
    incrementAmount,
    totalItemCount,
}) => {
    const [hasRef, setHasRef] = useState(false);

    const itemsDontFillDiv = useCallback(() => {
        if (!(scrollParentSelector && document.querySelector(scrollParentSelector))) {
            return;
        }
        const scrollParent = document.querySelector(scrollParentSelector);
        if (!scrollParent?.firstChild) {
            return;
        }
        if (scrollerEnd >= totalItemCount) {
            return;
        }

        const windowHeight = scrollParent.getBoundingClientRect().height;

        const docHeight = (scrollParent.firstChild as Element).getBoundingClientRect().height;
        const itemsDontFillPage = docHeight > 0 && docHeight < windowHeight;

        return (totalItemCount || scrollerEnd < totalItemCount)
            ? itemsDontFillPage
            : false;
    }, [scrollParentSelector, scrollerEnd, totalItemCount]);

    const onDivScroll = useCallback(() => {
        if (!(scrollParentSelector && document.querySelector(scrollParentSelector))) {
            return;
        }
        const scrollParent = document.querySelector(scrollParentSelector);
        if (!scrollParent?.firstChild) {
            return;
        }
        if (scrollerEnd >= totalItemCount) {
            return;
        }

        const scrollTop = scrollParent.scrollTop;
        const windowHeight = scrollParent.getBoundingClientRect().height;
        const docHeight = (scrollParent.firstChild as Element).getBoundingClientRect().height;

        const hitBottomOfScroll = windowHeight !== 0
            && scrollTop !== 0
            && scrollTop + windowHeight >= docHeight - pixelLead;

        if (hitBottomOfScroll || itemsDontFillDiv()) {
            onChange((scrollerEnd: number) => scrollerEnd + incrementAmount);
        }
    }, [incrementAmount, itemsDontFillDiv, onChange, pixelLead, scrollParentSelector, scrollerEnd, totalItemCount]);

    // const itemsDontFillWindow = () => {
    //     if (scrollerEnd >= totalItemCount) {
    //         return;
    //     }
    //     if (!(scrollParentSelector && document.querySelector(scrollParentSelector))) {
    //         return;
    //     }

    //     const windowHeight = window.innerHeight;
    //     const docHeight = document.body.clientHeight;

    //     const itemsDontFillPage = docHeight <= windowHeight;

    //     return (!totalItemCount || scrollerEnd < totalItemCount)
    //         ? itemsDontFillPage
    //         : false;
    // };

    const fillItemsIfNeeded = useCallback(() => {

        if (!(scrollParentSelector && document.querySelector(scrollParentSelector))) {
            return;
        }
        if (itemsDontFillDiv()) {
            onChange(scrollerEnd + incrementAmount);
        }
    }, [incrementAmount, itemsDontFillDiv, onChange, scrollParentSelector, scrollerEnd]);

    useEffect(() => {
        fillItemsIfNeeded();
    }, [scrollerEnd, fillItemsIfNeeded]);

    useEffect(() => {
        if (scrollParentSelector && document.querySelector(scrollParentSelector) && !hasRef) {
            const element = document.querySelector(scrollParentSelector);
            element?.addEventListener('scroll', onDivScroll);
            setHasRef(true);
        }

        return () => {
            window.removeEventListener('scroll', _.noop);
        };
    }, [hasRef, onDivScroll, scrollParentSelector]);

    // const onWindowScroll = () => {
    //     if (scrollerEnd >= totalItemCount) {
    //         return;
    //     }

    //     const scrollTop = window.scrollY;
    //     const windowHeight = window.innerHeight;
    //     const docHeight = document.body.clientHeight;

    //     const hitBottomOfScroll = scrollTop + windowHeight >= docHeight - pixelLead;

    //     if (hitBottomOfScroll || itemsDontFillWindow()) {
    //         if (!totalItemCount || scrollerEnd < totalItemCount) {
    //             onChange(scrollerEnd + incrementAmount);
    //         }
    //     }
    // };

    return children;
};

export default InfiniteScroll;
