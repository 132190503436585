import apiHelper from 'src/api/api-helper';
import {ID} from 'src/Types/CommonTypes';
import {StoreRole} from 'src/Types/StoreRoles/types';

enum ActivityRefTypes {
    ACTIVE = 'active', //generic type marking activity of any sort
}

interface Activity {
    id: ID
    createdAt: string
    refType: ActivityRefTypes
    storeId: ID
    userId: ID
}

export interface User {
    id: ID
    storeRoles: StoreRole[]
    email: string
    isActive: boolean
    firstName: string
    lastName: string
    activities: Activity[]
}

interface ParamsForUsers {
    textSearch?: string
}

const adminPrefix = '/admin';

export const getUsers = (storeId: ID, params?: ParamsForUsers): Promise<User[]> => {
    return apiHelper.get(`${adminPrefix}/stores/${storeId}/users`, params);
};

export const updateUser = (storeId: ID, userId: ID, user: User): Promise<User> => {
    return apiHelper.put(
        `${adminPrefix}/stores/${storeId}/users/${userId}`,
        user,
        undefined,
        {},
        {
            inlineErrorFields: ['email'],
        }
    );
};

export const inviteUsers = (storeId: ID, user: User): Promise<User> => {
    return apiHelper.post(`${adminPrefix}/stores/${storeId}/users`, user);
};

export const resendInvite = (storeId: ID, userId: ID): Promise<User> => {
    return apiHelper.post(`${adminPrefix}/stores/${storeId}/users/${userId}/resendInvite`, {});
};

export const resetPassword = (userId: ID): Promise<User> => {
    return apiHelper.post(`${adminPrefix}/users/${userId}/resetPassword`, {});
};

export const deleteUser = (storeId: ID, userId: ID): Promise<void> => {
    return apiHelper.delete(`${adminPrefix}/stores/${storeId}/users/${userId}`);
};
