import {useFormik} from 'formik';
import React from 'react';
import logo from 'src/assets/images/logo-stacked.svg';
import Button from 'src/Components/Button';
import Loading from 'src/Components/Loading';
import useSubmit from 'src/Views/Login/Forms/ConfirmResetPass/useSubmit';
import useValidationSchema from 'src/Views/Login/Forms/ConfirmResetPass/useValidationSchema';
import Input from 'src/Components/Input';
import _ from 'lodash';
import {useParams} from 'react-router-dom';

const ConfirmResetPass: React.FC = () => {
    const {submitHandler} = useSubmit();
    const {code} = useParams<{code: string}>();
    const initialValues = {code, password: '', confirmPass: ''};


    const viewport = document.querySelector('meta[name=viewport]');
    viewport?.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0');

    const {
        handleSubmit,
        errors,
        touched,
        isSubmitting,
        getFieldProps,
    } = useFormik({
        initialValues,
        validationSchema: useValidationSchema,
        onSubmit: submitHandler,
    });

    return (
        <>
            <div className='formBackground' />
            <div className='formWrapper'>
                <div className='formBody'>
                    <a href='https://www.brandkeep.com'>
                        <img src={logo} className='logo' />
                    </a>
                    <div className='formHeader'>Reset your password</div>
                    <div className='message'>
                        Enter your new password below.
                    </div>
                    <div className='form'>
                        <form onSubmit={handleSubmit} id='confirm-reset-pass'>
                            <div>
                                <div className='inputWrapper'>
                                    <Input
                                        errors={errors.password && touched.password && errors.password}
                                        required
                                        autoFocus
                                        type='password'
                                        label='New password'
                                        {...getFieldProps('password')} />
                                </div>

                                <div>
                                    <Input
                                        errors={errors.confirmPass && touched.confirmPass && errors.confirmPass}
                                        required
                                        type='password'
                                        placeholder='*********'
                                        label='Confirm Password'
                                        {...getFieldProps('confirmPass')} />
                                </div>
                            </div>
                            <Button
                                type='submit'
                                className='btn-secondary submit-button'
                                form='confirm-reset-pass'
                                disabled={isSubmitting || !_.isEmpty(errors)}>
                                {isSubmitting && (
                                    <Loading inline />
                                )}
                                Update Password
                            </Button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ConfirmResetPass;
