import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import _ from 'lodash';
import './styles.sass';
import {createTag, getTags, Tag} from 'src/api/Tags/api-tags';
import Button from 'src/Components/Button';
import Loading from 'src/Components/Loading';
import Input from 'src/Components/Input';
import TagsEditable from 'src/Components/TagsEditable';
import {createBrandCard, updateBrandCard, BrandCard as BrandCardType, getBrandCardById} from 'src/api/BrandCard/api-brand-card';
import {ID, genericFn} from 'src/Types/CommonTypes';
import {createLink, updateLink, deleteLink, Link, LinkRefTypes} from 'src/api/Link/api-link';
import {ApiTag} from 'src/Types/Tags/types';
import {_get, filterMap, filterKeyBy, mapKeyBy} from 'src/utils/general';
import PopIcon from 'src/Components/PopIcon';
import tipIcon from 'src/assets/icons/tip-hex.svg';

interface Props {
    brandId?: ID
    storeId: ID
    refetchBrands: genericFn
    onSelectBrandId?: genericFn
    onCancel: genericFn
    onDelete?: genericFn
}

const BrandCardForm: React.FC<Props> = ({brandId, storeId, refetchBrands, onCancel, onDelete = _.noop, onSelectBrandId}) => {
    const [brandTags, setBrandTags] = useState<ApiTag[]>();
    const [selectedBrandTags, setSelectedBrandTags] = useState<ApiTag[]>();
    const [brandCard, setBrandCard] = useState<BrandCardType>();

    useEffect(() => {
        const getBrandTags = async() => {
            const tags = await getTags('brand_cards', 'store', storeId);
            setBrandTags(tags);
        };
        getBrandTags();
    }, [storeId]);

    const fetchBrand = async(brandId: ID) => {
        const fetchedBrand = await getBrandCardById(brandId);
        setBrandCard(fetchedBrand);
        setSelectedBrandTags(fetchedBrand.tags);
    };

    useEffect(() => {
        if (brandId) {
            fetchBrand(brandId);
        }
    }, [brandId]);

    const handleSelectTags = (_, selectedTags: ApiTag[]) => {
        setSelectedBrandTags(selectedTags);
    };

    const validateSchema = () => Yup.object().shape({
        name: Yup.string().required(),
        accountNumber: Yup.string().optional(),
        b2bUrl: Yup.string().optional(),
        creditLimit: Yup.string().optional(),
        links: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().when('url', {
                    is: (url: string | undefined) => Boolean(url),
                    then: Yup.string().required('Name is required when URL is set'),
                }),
                url: Yup.string()
                    .when('name', {
                        is: (name: string | undefined) => Boolean(name),
                        then: Yup.string().required('URL is required when Name is set'),
                    }),
            }, [['url', 'name']])
        ),
    });

    const defaultSchema = validateSchema();

    const handleSubmitBrandCard = async(values: any, {setSubmitting}: any) => {
        const submitValues = _.cloneDeep(values);
        delete submitValues.links;
        delete submitValues.b2bUrl;

        let existingBrandCard = undefined;
        try {
            existingBrandCard = brandId
                ? brandCard
                : await createBrandCard(storeId, submitValues);
        } catch (e: any) {
            if (e.errors) {
                //eslint-disable-next-line @typescript-eslint/no-use-before-define
                setErrors(mapKeyBy(e.errors, 'message', 'field'));
            }
        }

        if (!existingBrandCard) {
            return false;
        }

        const updatePayload: BrandCardType = {
            id: existingBrandCard.id,
            ...submitValues,
        };
        const tagPromises: Promise<any>[] = [];
        //handle create/update tags
        if (selectedBrandTags && selectedBrandTags.length) {
            const tagsById = _.keyBy(brandTags, 'id');
            const payloadForSaveTags: Tag[] = [];
            for (const tag of selectedBrandTags) {
                if (tag && (!tag.id || !tagsById[tag.id])) {
                    delete tag.id;
                    payloadForSaveTags.push({
                        refType: 'brand_cards',
                        scope: 'store',
                        scopeId: Number(storeId),
                        title: `${tag.title}` || '',
                    });
                }
            }

            payloadForSaveTags?.forEach((tag) => {
                tagPromises.push(createTag(tag));
            });
            const tags = await Promise.all(tagPromises);
            const tagIds = _.map([...tags, ...selectedBrandTags], 'id');
            updatePayload.tags = _.compact(tagIds);
        }

        const marketingLink = brandCard && brandCard.links
            ? _.find(brandCard.links, {refType: LinkRefTypes.MarketingLink})
            : undefined;
        //handle update links
        const preExistingLinks = (existingBrandCard && existingBrandCard.links) || [];
        const newLinks = _.cloneDeep(values.links) || [];
        //add in marketing link if it exists, since the url is just a string in the form object
        if (marketingLink) {
            newLinks.push({id: marketingLink.id, url: values.b2bUrl});
        }
        const newLinksById = filterKeyBy(newLinks, 'id') || {};
        for (const existingLink of preExistingLinks) {
            if (existingLink && existingLink.id && (
                (existingLink.url !== _get(newLinksById, [existingLink.id, 'url']))
                || (_get(existingLink, 'name') !== _get(newLinksById, [existingLink.id, 'name']))
            )) {
                //a change happened
                const finalLink = Object.assign({}, existingLink, newLinksById[existingLink.id]);
                if (finalLink.url) {
                    await updateLink(existingLink.id, finalLink);
                } else {
                    //the change was a delete
                    if (existingBrandCard && existingBrandCard.links) {
                        _.remove(existingBrandCard.links, (link) => {
                            return link.id === existingLink.id;
                        });
                    }
                    await deleteLink(existingLink.id);
                }
            }
        }

        //handle create links
        const hasLinkValues = values.links && values.links.length;
        if (hasLinkValues || values.b2bUrl) {
            const payloadForSaveLinks: Link[] = [];
            const linksById = existingBrandCard && existingBrandCard.links
                ? _.keyBy(existingBrandCard.links, 'id')
                : {};

            if (hasLinkValues) {
                for (const link of values.links) {
                    if ((link.url && link.name) && (!link.id || !linksById[link.id])) {
                        payloadForSaveLinks.push({
                            refType: 'brand_card_quick_link',
                            scope: 'brand',
                            scopeId: Number(existingBrandCard.id),
                            url: link.url,
                            name: link.name,
                        });
                    }
                }
            }

            if (values.b2bUrl && !marketingLink) {
                payloadForSaveLinks.push({
                    refType: 'brand_card_marketing_link',
                    scope: 'brand',
                    scopeId: Number(existingBrandCard.id),
                    url: values.b2bUrl,
                    name: undefined,
                });
            }

            const linkIds = [];
            for (const link of payloadForSaveLinks) {
                const linkReturn = await createLink(storeId, link);
                linkIds.push(linkReturn.id);
            }

            updatePayload.links = _.compact([
                ...linkIds,
                ...filterMap(_.get(existingBrandCard, 'links', []), (link: Link) => link.url && link.name, 'id'),
            ]);
            if (values.b2bUrl && marketingLink) {
                updatePayload.links.push(marketingLink.id);
            }
        }

        try {
            await updateBrandCard(storeId, updatePayload.id, updatePayload);
        } catch (e: any) {
            if (e.errors) {
                //eslint-disable-next-line @typescript-eslint/no-use-before-define
                setErrors(mapKeyBy(e.errors, 'message', 'field'));
            }
            setSubmitting(false);
            return false;
        }

        await refetchBrands();
        setSubmitting(false);
        if (existingBrandCard && existingBrandCard.id && onSelectBrandId) {
            onSelectBrandId(existingBrandCard.id);
        }
        onCancel();
    };

    const quickLinks = brandCard && brandCard.links
        ? _.filter(brandCard.links, {refType: LinkRefTypes.QuickLink})
        : [];
    const marketingLink = brandCard && brandCard.links
        ? _.find(brandCard.links, {refType: LinkRefTypes.MarketingLink})
        : undefined;

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        setErrors,
        values,
        errors,
        touched,
        isSubmitting,
        getFieldProps,
    } = useFormik({
        initialValues: {
            name: _get(brandCard, 'name', ''),
            accountNumber: _get(brandCard, 'accountNumber', ''),
            b2bUrl: marketingLink ? marketingLink.url : '',
            creditLimit: _get(brandCard, 'creditLimit', ''),
            links: [
                {
                    name: _get(quickLinks, [0, 'name'], ''),
                    url: _get(quickLinks, [0, 'url'], ''),
                    id: _get(quickLinks, [0, 'id']),
                },
                {
                    name: _get(quickLinks, [1, 'name'], ''),
                    url: _get(quickLinks, [1, 'url'], ''),
                    id: _get(quickLinks, [1, 'id']),
                },
                {
                    name: _get(quickLinks, [2, 'name'], ''),
                    url: _get(quickLinks, [2, 'url'], ''),
                    id: _get(quickLinks, [2, 'id']),
                },
            ],
        },
        validationSchema: defaultSchema,
        onSubmit: handleSubmitBrandCard,
        enableReinitialize: true,
    });

    if (brandId && !brandCard) {
        return (<Loading fill size={32} />);
    }

    return (
        <form onSubmit={handleSubmit} id='submit-brandcard' className='brand-card-form'>
            <div className='create-brand-card-form'>
                <Input
                    errors={errors.name && touched.name && errors.name}
                    infoPopContent='Brand name is the key to your brand card being connected with a brand. Create brand cards for anything you would like, from the most established brand in your store, the startup you just heard about, or even the company you buy reciept paper from.'
                    required
                    autoFocus
                    label='Name'
                    {...getFieldProps('name')} />
                <Input
                    errors={errors.b2bUrl && touched.b2bUrl && errors.b2bUrl}
                    infoPopContent=' Enter the marketing or direct to consumer website URL for the brand here.'
                    label='Brand Marketing Site'
                    {...getFieldProps('b2bUrl')} />
                <Input
                    errors={errors.accountNumber && touched.accountNumber && errors.accountNumber}
                    label='Account Number'
                    {...getFieldProps('accountNumber')} />
                <Input
                    errors={errors.creditLimit && touched.creditLimit && errors.creditLimit}
                    label='Credit Limit'
                    {...getFieldProps('creditLimit')} />
                <div className='form-divider' />
                {values.links && values.links.map((link: Link, index: number) => (
                    <React.Fragment key={index}>
                        <Input
                            errors={_.get(touched, ['links', index, 'name']) && _.get(errors, ['links', index, 'name'])}
                            infoPopContent={index === 0 ? 'Use quick links to store important links that you utilize frequently. B2B, pro purchase sites, media sites, payment portals, etc... they\'re all fair game! Use the name field to quickly identify your link and use the URL field to paste the destination.' : undefined}
                            name={`links.${index}.name`}
                            label={`Quick Link Name #${index + 1}`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.links[index].name} />
                        <Input
                            errors={_.get(touched, ['links', index, 'url']) && _.get(errors, ['links', index, 'url'])}
                            name={`links.${index}.url`}
                            label={`Quick Link URL #${index + 1}`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.links[index].url} />
                    </React.Fragment>
                ))}
                <div className='select-brand-tags'>
                    <label htmlFor='tags'>Tags</label>
                    <PopIcon
                        className='btn-tip'
                        type='tip'
                        content='Use brand level tags to help keep track of important brand characteristics. Brand categories (footwear, hardgoods, apparel) and tagging buyer responsibilities are two common uses we’ve seen thus far.'
                        origin={<img src={tipIcon} />} />
                    {((brandId && selectedBrandTags) || !brandId) && (
                        <TagsEditable
                            notFoundContent={(
                                <div>No Brand Tags added yet</div>
                            )}
                            tags={brandTags}
                            placeholder='Brand Tags'
                            name='tags'
                            id='tags'
                            isEdit={true}
                            onChange={handleSelectTags}
                            selectedTags={selectedBrandTags} />
                    )}
                </div>
            </div>
            <div className='modal-footer'>
                {brandId && (
                    <button
                        type='button'
                        className='btn btn-delete'
                        onClick={onDelete}>
                        Delete Brand Card
                    </button>
                )}
                <div
                    className='cancel-button'
                    onClick={onCancel}>
                    Cancel
                </div>
                <Button
                    type='submit'
                    className='btn-secondary submit-button'
                    form='submit-brandcard'
                    disabled={isSubmitting || !_.isEmpty(errors)}>
                    {isSubmitting && (
                        <Loading inline />
                    )}
                    {brandId ? 'Save' : 'Create'}
                </Button>
            </div>
        </form>
    );
};

export default BrandCardForm;
