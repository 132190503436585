import React, {useEffect, useState, useRef} from 'react';
import ReactDOM from 'react-dom';
import {useNavigate, useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
// import {Document, Page} from 'react-pdf/dist/esm/entry.webpack5';
// import {BlobProvider, Document, Page} from '@react-pdf/renderer';
import './styles.sass';
import _ from 'lodash';

//icons
import {PlusOutlined, MinusOutlined} from '@ant-design/icons';
import {Close, Download, Kabob, Share} from 'src/lib/svg-icon-helper';

//components
import {message} from 'antd';
import AssetPdf from 'src/Components/AssetPdf';
// import AssetSpreadsheet from 'src/Components/AssetSpreadsheet';
import AssetVideo from 'src/Components/AssetVideo';
import Button from 'src/Components/Button';
import DropdownButton from 'src/Components/DropdownButton';
import Input from 'src/Components/Input';
import Loading from 'src/Components/Loading';
import Modal from 'src/Components/Modal';
import {Prompt} from 'src/Components/ModalPrompt';

//apis
import {
    getAssetFileStream,
    ApiAsset,
    assetUrlRaw,
    getAssetApiTokenForExternal,
    lockAssetForSaving,
    duplicateAssetForCopySave,
    lockAssetForEditing,
    getEditingAssetsStatus,
    unlockAssetForEditing,
} from 'src/api/Assets/api-asset';

//types
import {genericFn, ID, ReactAnchorEvent, ReactEvent} from 'src/Types/CommonTypes';
import {RootState} from 'src/redux/rootReducer';
import {IActionElements, PdfViewerComponent} from '@syncfusion/ej2-react-pdfviewer';

//libs
import {classnames, displayFileSize, textToClipboard} from 'src/utils/general';
import getFileType, {SUPPORTED_FILE_TYPES, FileTypes, FILE_TYPES_FOR_CONVERSION, getFileNoExtension} from 'src/lib/file-type-helper';
import {getContactDisplayBySource} from 'src/lib/asset-helper';
import {buildUrl} from 'src/lib/url';
import {AuthRoutes} from 'src/constants/userRoles';
import {authNameFormatter} from 'src/lib/name-helper';
import Pings from 'src/lib/pings';
import {Browser} from '@syncfusion/ej2/base';

const {
    IMAGE,
    PDF,
    VIDEO,
} = SUPPORTED_FILE_TYPES;
const UNSUPPORTED = 'UNSUPPORTED';

interface Props {
    className?: string
    asset?: ApiAsset
    onAssetChange: (asset: ApiAsset) => void
    onClose: genericFn
    onDownload: genericFn
    onEditPdf: genericFn
    show: boolean
    showTopbar?: boolean
    copyUrl?: string
    urlData?: any
    readOnly?: boolean
    isLockedForEditing?: boolean
    portal?: boolean
}

const AssetPreview: React.FC<Props> = ({
    asset,
    className = '',
    show = false,
    onAssetChange = _.noop,
    onClose,
    onDownload,
    onEditPdf = _.noop,
    showTopbar = true,
    copyUrl = '/store/{{storeId}}/assets/{{assetId}}',
    readOnly = false,
    urlData: urlDataRaw = {},
    isLockedForEditing = false,
    portal = true,
}) => {
    const navigate = useNavigate();
    const {storeId} = useParams<{storeId: any}>();
    const userId = useSelector((state: RootState) => state.auth.id);
    const userInfo = useSelector((state: RootState) => state.auth);
    const urlData = {
        ...urlDataRaw,
        storeId,
        assetId: asset && asset.id,
    };
    const [assetFile, setAssetFile] = useState<any>();
    // const [assetFileBase64, setAssetFileBase64] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [zoomFactor, setZoomFactor] = useState<number>(1);
    const [boardOffsetX, setBoardOffsetX] = useState<number>(0);
    const [boardOffsetY, setBoardOffsetY] = useState<number>(0);
    const [isPanning, setIsPanning] = useState<boolean>(false);
    const [isSubmittingFiles, setIsSubmittingFiles] = useState<boolean>(false);
    const [startingPageX, setStartingPageX] = useState<number>(0);
    const [startingPageY, setStartingPageY] = useState<number>(0);
    const [startingBoardOffsetX, setStartingBoardOffsetX] = useState<number>(0);
    const [startingBoardOffsetY, setStartingBoardOffsetY] = useState<number>(0);
    const [dragTarget, setDragTarget] = useState<any>();
    const [potentialDragTarget, setPotentialDragTarget] = useState<any>();
    const [assetType, setAssetType] = useState<FileTypes | 'UNSUPPORTED'>();
    const [assetFileType, setAssetFileType] = useState<string>();
    const [newFileName, setNewFileName] = useState<string>('');
    const [showNewNameModal, setShowNewNameModal] = useState(false);
    const [assetPdfEditMode, setAssetPdfEditMode] = useState(false);
    const [assetApiToken, setAssetApiToken] = useState<string>();
    const [assetS3Key, setAssetS3Key] = useState<string>();
    const [pdfHistoryCollection, setPdfHistoryCollection] = useState<IActionElements[] | never[]>([]);
    const [pdfUrl, setPdfUrl] = useState<string>();
    const stopWatching = useRef(false);

    const imageRef = useRef<HTMLImageElement>(null);
    const imageAreaRef = useRef<HTMLImageElement>(null);
    const pdfRef = useRef<PdfViewerComponent>(null);
    const classes = classnames(
        'AssetPreview',
        {[className]: className},
        {'fullpage-cover': portal && showTopbar},
        {'fullpage-cover-no-topbar': portal && !showTopbar},
        {hidden: !show}
    );

    const handlePortal = (element: JSX.Element) => {
        if (portal) {
            const pop = document.querySelector('#fullPagePop');
            return pop && ReactDOM.createPortal(element, pop);
        } else {
            return element;
        }
    };

    // const blobToBase64 = (blob: any) => {
    //     return new Promise((resolve) => {
    //         const reader = new FileReader();
    //         reader.onloadend = () => resolve(reader.result);
    //         reader.readAsDataURL(blob);
    //     });
    // };

    const getAssetUrl = async(assetId: ID) => {
        const response = await assetUrlRaw(assetId, 'open');
        if (_.get(response, 'url')) {
            setPdfUrl(`${BASE_API || BASE_URL}${response.url}`);
        }
    };

    const handleOnClose = () => {
        onClose();
        setAssetFile(undefined);
        setZoomFactor(1);
        setAssetType(undefined);
        setPdfUrl(undefined);
        // setAssetFileBase64(undefined);
    };

    useEffect(() => {
        const getAssetFile = async(asset: ApiAsset) => {
            const assetType = getFileType(asset.originalFilename);
            if (Object.values<string>(SUPPORTED_FILE_TYPES).includes(assetType)) {
                setAssetType(assetType);
                if (assetType === SUPPORTED_FILE_TYPES.PDF || (Object.values<string>(FILE_TYPES_FOR_CONVERSION).includes(assetType))) {
                    //syncfusion lib will handle file loading, we need only a url for it
                    getAssetUrl(asset.id);
                } else {
                    try {
                        const assetFileResponse = await getAssetFileStream(asset.id);
                        if (_.get(assetFileResponse, 'data.type')) {
                            setAssetFileType(assetFileResponse.data.type);
                        }
                        setAssetFile(URL.createObjectURL(assetFileResponse.data));
                        // setAssetFileBase64(await blobToBase64(assetFileResponse.data));
                    } catch (e: any) {
                        message.error('Asset not found, or it was deleted.');
                        handleOnClose();
                        setIsLoading(false);
                    }
                }
            } else {
                setAssetType(UNSUPPORTED);
                setIsLoading(false);
            }
        };
        setIsLoading(true);
        if (asset) {
            getAssetFile(asset);
        }
    }, [asset]);

    const handleCopyUrl = () => {
        textToClipboard(buildUrl(
            copyUrl,
            urlData,
            false,
            false
        ));
    };
    const handleZoomOut = () => {
        const newZoomFactor = zoomFactor + ((zoomFactor > 1.01) ? -.25 : -.1);
        if (newZoomFactor > .21) {
            setZoomFactor(newZoomFactor);
        }
    };
    const handleZoomIn = () => {
        const newZoomFactor = zoomFactor + ((zoomFactor > .99) ? .25 : .1);
        if (newZoomFactor <= 5) {
            setZoomFactor(newZoomFactor);
        }
    };

    const preparePdfEditorForClose = () => {
        const viewer = pdfRef.current;
        const viewerBase = viewer?.viewerBase;
        if (!viewer || !viewerBase) {
            return;
        }
        viewer.annotation.setAnnotationMode('None');
        viewer.annotationModule.clearSelection();

        // if (viewerBase.isTextMarkupAnnotationModule()) {
        //     viewer.annotationModule.textMarkupAnnotationModule.saveTextMarkupAnnotations();
        // }
        // if (viewerBase.isShapeAnnotationModule()) {
        //     viewer.annotationModule.shapeAnnotationModule.saveShapeAnnotations();
        // }
        // if (viewerBase.isCalibrateAnnotationModule()) {
        //     viewer.annotationModule.measureAnnotationModule.saveMeasureShapeAnnotations();
        // }
        // if (viewerBase.isStampAnnotationModule()) {
        //     viewer.annotationModule.stampAnnotationModule.saveStampAnnotations();
        // }
        // if (viewerBase.isCommentAnnotationModule()) {
        //     viewer.annotationModule.stickyNotesAnnotationModule.saveStickyAnnotations();
        // }
        // if (viewer.formDesignerModule) {
        //     viewer.formDesignerModule.downloadFormDesigner();
        // } else if (viewer.formFieldsModule) {
        //     viewer.formFieldsModule.downloadFormFieldsData();
        // }
        // // const signatureData = this.signatureModule.saveSignature();
        // if (viewerBase.isFreeTextAnnotationModule()) {
        //     viewer.annotationModule.freeTextAnnotationModule.saveFreeTextAnnotations();
        // }
        // if (viewerBase.isInkAnnotationModule()) {
        //     viewer.annotationModule.inkAnnotationModule.saveInkSignature();
        // }
    };

    const closeEditMode = async(reload = true) => {
        setAssetPdfEditMode(false);
        stopWatching.current = false;
        //unload and reload to clear unsaved changes
        if (pdfRef.current && pdfRef.current.toolbar) {
            pdfRef.current.toolbar.showAnnotationToolbar(false);
            if (pdfRef.current.toolbar.annotationToolbarModule && Browser.isDevice && !pdfRef.current.enableDesktopMode) {
                pdfRef.current.toolbar.annotationToolbarModule.hideMobileAnnotationToolbar();
                pdfRef.current.toolbar.annotationToolbarModule.createAnnotationToolbarForMobile();

                //applyMobileAnnotationToolbarSettings
            }
        }
        if (reload && pdfUrl && pdfRef.current) {
            pdfRef.current.unload();
            // pdfRef.current.load(assetFileBase64, null);
            pdfRef.current.load(pdfUrl, '');
        }
        Pings.removePing('assetEditLocksPing');
        if (asset) {
            await unlockAssetForEditing(storeId, asset.id);
        }
    };

    const handleAssetApiToken = async(asset: ApiAsset): Promise<{key: string, s3Key: string}> => {
        const {key, s3Key} = await getAssetApiTokenForExternal(storeId, asset.id);
        setAssetApiToken(key);
        setAssetS3Key(s3Key);
        return {
            key,
            s3Key,
        };
    };

    const handleCopyPdf = async() => {
        if (!asset) {
            return;
        }
        const hide = message.loading('Saving PDF...', 0);
        setIsSubmittingFiles(true);
        setIsLoading(true);

        preparePdfEditorForClose();

        const response = await duplicateAssetForCopySave(storeId, asset.id, newFileName);
        const token = await handleAssetApiToken(response);
        const pdfviewer = pdfRef.current;
        if (pdfviewer && response) {
            pdfviewer.ajaxRequestSettings = {
                ajaxHeaders: [
                    {
                        headerName: 'operation',
                        headerValue: 'copy',
                    },
                    {
                        headerName: 'env',
                        headerValue: ENV_VARS.env,
                    },
                    {
                        headerName: 'authToken',
                        headerValue: token.key,
                    },
                    {
                        headerName: 's3Key',
                        headerValue: token.s3Key,
                    },
                    {
                        headerName: 'filename',
                        headerValue: asset.originalFilename,
                    },
                ],
                withCredentials: false,
            };
            pdfviewer.serverActionSettings.download = 'SaveDocument';

            /********************
             * MARK FILE AS BEING SAVED
             */
            pdfviewer.download();
            await lockAssetForSaving(storeId, response.id);
            onEditPdf(asset);

            setShowNewNameModal(false);
            setIsSubmittingFiles(false);
            hide();
            closeEditMode(false);

            setAssetApiToken(undefined);
            setAssetS3Key(undefined);

            setTimeout(() => {
                if (response && response.id && !Pings.isPingRunning('assetLocksPing')) {
                    navigate(`${AuthRoutes.storeAssets(storeId)}/${response.id}`);
                    onAssetChange(response);
                } else {
                    handleOnClose();
                    setIsLoading(false);
                }
            }, 3600);
        }
    };

    const handleSavePdf = async() => {
        if (!asset) {
            return;
        }
        const hide = message.loading('Saving PDF...', 0);
        setIsSubmittingFiles(true);

        preparePdfEditorForClose();

        const assetStatus = await getEditingAssetsStatus(storeId, asset.id);
        if (!(assetStatus && assetStatus.assetId === asset.id && assetStatus.userId === userId)) {
            await new Prompt().notice(
                {
                    body: 'Your session is out of date, unable to over-write PDF. Please close and re-open the file, or save it as a copy.',
                    title: 'File outdated',
                    displayType: 'notice',
                    props: {confirmText: 'Close'},
                    onYes: _.noop,
                }
            );
            hide();
            setIsSubmittingFiles(false);
            return;
        }

        const pdfviewer = pdfRef.current;
        if (pdfviewer) {
            pdfviewer.ajaxRequestSettings = {
                ajaxHeaders: [
                    {
                        headerName: 'operation',
                        headerValue: 'save',
                    },
                    {
                        headerName: 'env',
                        headerValue: ENV_VARS.env,
                    },
                    {
                        headerName: 'authToken',
                        headerValue: assetApiToken,
                    },
                    {
                        headerName: 's3Key',
                        headerValue: assetS3Key,
                    },
                    {
                        headerName: 'filename',
                        headerValue: asset.originalFilename,
                    },
                ],
                withCredentials: false,
            };
            pdfviewer.serverActionSettings.download = 'SaveDocument';
            /********************
             * MARK FILE AS BEING SAVED
             */
            await lockAssetForSaving(storeId, asset.id);
            onEditPdf(asset);
            pdfviewer.download();

            setShowNewNameModal(false);
            setIsSubmittingFiles(false);
            hide();
            closeEditMode(false);

            setAssetApiToken(undefined);
            setAssetS3Key(undefined);
        }
    };

    const handleSetFileName = (event: ReactEvent) => {
        setNewFileName(event.target.value);
    };

    const handleShowNewNameModal = () => {
        setShowNewNameModal(true);
        if (asset) {
            setNewFileName(`${getFileNoExtension(asset.name)} (copy)`);
        }
    };

    /******** IMAGES ******/
    const handleCenterImage = () => {
        if (imageRef && imageRef.current && imageAreaRef && imageAreaRef.current) {
            const {height, width} = imageRef.current.getBoundingClientRect();
            const {height: areaHeight, width: areaWidth} = imageAreaRef.current.getBoundingClientRect();

            if (height && width && areaHeight && areaWidth) {
                setBoardOffsetX((areaWidth / 2) - (width / 2));
                setBoardOffsetY((areaHeight / 2) - (height / 2));
                setZoomFactor(1);
            }
        }
    };

    const handleClickImage = (event: ReactAnchorEvent) => {
        if (event.button === 0) {
            setIsPanning(true);
            setStartingPageX(event.pageX);
            setStartingPageY(event.pageY);
            setStartingBoardOffsetX(boardOffsetX);
            setStartingBoardOffsetY(boardOffsetY);
        }
    };

    const handleMouseMove = (event: ReactAnchorEvent) => {
        const target = dragTarget || potentialDragTarget;

        // on first move, start to "really" drag
        if (potentialDragTarget && !dragTarget) {
            setDragTarget(target);
        }

        const e = event;
        const deltaX = startingPageX - e.pageX;
        const deltaY = startingPageY - e.pageY;
        if (isPanning) {
            setBoardOffsetX(startingBoardOffsetX - deltaX);
            setBoardOffsetY(startingBoardOffsetY - deltaY);
        }
    };

    const handleMouseUp = () => {
        setDragTarget(undefined);
        setPotentialDragTarget(undefined);
        setIsPanning(false);
    };

    const handleEditCheckin = () => {
        if (asset) {
            //getEditingAssetsStatus
            Pings.removePing('assetEditLocksPing');
            //begin ping'ing for lock updates
            Pings.addPing({
                id: 'assetEditLocksPing',
                pingOnInit: false,
                frequency: 1,
                unit: 'minute',
                task: async() => {
                    await lockAssetForEditing(storeId, asset.id);
                },
                onError: _.noop,
            });
        }
    };

    const annotationWatcher = (lock: boolean, shouldUseRedundancy = true) => {
        //lock annotations when outside of edit mode
        if (pdfRef.current && pdfRef.current.annotationCollection && pdfRef.current.annotationCollection.length) {
            stopWatching.current = true;
            //all of the string checks are due to some weird behavior buried in the lib. Type checking says it shouldn't happen :(
            if (pdfRef.current.annotationSettings && typeof pdfRef.current.annotationSettings !== 'string') {
                pdfRef.current.annotationSettings.isLock = lock;
            }
            if (pdfRef.current.freeTextSettings && typeof pdfRef.current.freeTextSettings !== 'string') {
                pdfRef.current.freeTextSettings.isLock = lock;
            }
            for (let i = 0; i < pdfRef.current.annotationCollection.length; i++) {
                if (_.some(pdfRef.current.annotationCollection, (collection) => typeof collection.annotationSettings === 'string')) {
                    continue;
                }
                if (
                    pdfRef.current.annotationCollection[i].annotationSettings
                    && typeof pdfRef.current.annotationCollection[i].annotationSettings !== 'string'
                ) {
                    pdfRef.current.annotationCollection[i].annotationSettings.isLock = lock;
                    //timeout to give the lib a chance to catch up?
                    setTimeout(() => {
                        if (pdfRef && pdfRef.current && _.get(pdfRef.current, 'annotationModule.editAnnotation')) {
                            //apply above edits
                            pdfRef.current.annotationModule.editAnnotation(pdfRef.current.annotationCollection[i]);
                        }
                    }, 100);
                }
            }
            if (!lock) {
                setPdfHistoryCollection(_.cloneDeep(_.get(pdfRef.current, 'annotationModule.actionCollection', [])));
                if (shouldUseRedundancy) {
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    fireRedundantWatchers();
                }
            }
        } else {
            setTimeout(() => {
                annotationWatcher(lock);
            }, 100);
        }
    };

    const fireRedundantWatchers = () => {
        let iterations = 5;
        while (iterations > 0) {
            setTimeout(() => {
                annotationWatcher(false, false);
            }, iterations * 100);
            iterations--;
        }
    };

    const handlePdfEdit = async() => {
        if (asset && pdfRef.current) {
            const assetStatus = await getEditingAssetsStatus(storeId, asset.id);
            if (assetStatus && assetStatus.userId !== userId) {
                await new Prompt().notice(
                    {
                        body: 'This PDF is being edited by another member of your team. Please wait until they are done to edit this file, or make a copy for your own use!',
                        title: 'File already open',
                        displayType: 'notice',
                        props: {confirmText: 'Close'},
                        onYes: _.noop,
                    }
                );
            } else {
                setAssetPdfEditMode(true);
                stopWatching.current = false;
                pdfRef.current.toolbar.showAnnotationToolbar(true);
                if (pdfRef.current.toolbar.annotationToolbarModule && Browser.isDevice && !pdfRef.current.enableDesktopMode) {
                    pdfRef.current.toolbar.annotationToolbarModule.createAnnotationToolbarForMobile();
                    //applyMobileAnnotationToolbarSettings
                }
                try {
                    await Promise.all([
                        handleAssetApiToken(asset),
                        lockAssetForEditing(storeId, asset.id),
                    ]);
                    handleEditCheckin();
                } catch (e) {
                    await unlockAssetForEditing(storeId, asset.id);
                }
            }
        }
        // else {
        //     await new Prompt().notice(
        //         {
        //             body: 'This PDF is too large for editing, please use one smaller than 50MB',
        //             title: 'File Too Large',
        //             displayType: 'notice',
        //             props: {confirmText: 'Close'},
        //             onYes: _.noop,
        //         }
        //     );
        // }
    };

    const annotationCollection = (pdfRef.current && pdfRef.current.annotationCollection);
    useEffect(() => {
        if (pdfRef.current && !stopWatching.current) {
            annotationWatcher(!assetPdfEditMode);
        }
    }, [annotationCollection, assetPdfEditMode]);

    return asset
        ? handlePortal(
            <div className={classes}>
                <div className='preview-header'>
                    <div className='preview-info'>
                        <div className='info-segment'>
                            {asset && asset.name}
                        </div>
                        <div className='info-segment'>
                            {asset && displayFileSize(asset.size)}
                        </div>
                        <div className='info-segment'>
                            {getContactDisplayBySource(asset)}
                        </div>
                    </div>
                    <div className='preview-controls'>
                        {asset && assetType === IMAGE && (
                            <div className='pill-button-group'>
                                <span className='pill-button-group__spacer' />
                                <Button className='btn-icon zoom-btn' onClick={handleZoomOut}>
                                    <MinusOutlined />
                                </Button>
                                <div className='zoom-level-display'>
                                    {`${Math.round(zoomFactor * 100)}%`}
                                </div>
                                <Button className='btn-icon zoom-btn' onClick={handleZoomIn}>
                                    <PlusOutlined />
                                </Button>
                                <span className='pill-button-group__spacer' />
                            </div>
                        )}
                        {asset && assetType && assetType === PDF && assetPdfEditMode && (
                            <>
                                <DropdownButton
                                    className='btn btn-white pdf-save-btn'
                                    placement='bottomRight'
                                    disabled={isSubmittingFiles}
                                    items={[
                                        {
                                            key: 'save',
                                            onClick: handleSavePdf,
                                            text: 'Over-write existing PDF',
                                        },
                                        {
                                            key: 'copy',
                                            onClick: handleShowNewNameModal,
                                            text: 'Save as a Copy',
                                        },
                                    ]}>
                                    <>
                                        <span>Save</span> <Kabob className='icon right-icon' />
                                    </>
                                </DropdownButton>
                                <Button className='btn btn-white pdf-save-btn' onClick={async() => {
                                    preparePdfEditorForClose();
                                    const actionCollection = _.get(pdfRef.current, 'annotationModule.actionCollection', []);

                                    //try to determine if the pdf has been edited at all
                                    if (_.get(pdfRef.current, 'isDocumentEdited')
                                        && (
                                            pdfHistoryCollection.length !== actionCollection.length
                                            || (pdfHistoryCollection.length === actionCollection.length && !_.isEqual(pdfHistoryCollection, actionCollection))
                                        )
                                    ) {
                                        await new Prompt().yesNoDanger(
                                            {
                                                body: 'Are you sure you want to close without saving?',
                                                title: 'Changes were made',
                                                displayType: 'delete',
                                                props: {confirmText: 'Yes'},
                                                onYes: closeEditMode,
                                            });
                                    } else {
                                        closeEditMode(false);
                                    }
                                }}>
                                    Exit
                                </Button>
                            </>
                        )}
                        {asset && assetType && assetType === PDF && !readOnly && !assetPdfEditMode && (
                            <Button className='btn btn-white pdf-save-btn' onClick={handlePdfEdit} disabled={isLockedForEditing}>
                                Edit
                            </Button>
                        )}
                        {!assetPdfEditMode && (
                            <>
                                <Button className='btn-circle btn-white btn-icon share-btn' onClick={handleCopyUrl}>
                                    <Share />
                                </Button>
                                <Button className='btn-circle btn-white btn-icon download-btn' onClick={onDownload} disabled={isLockedForEditing}>
                                    <Download />
                                </Button>
                                <Button className='btn-circle btn-white btn-icon close-btn' onClick={handleOnClose}>
                                    <Close />
                                </Button>
                            </>
                        )}
                    </div>
                </div>
                <div className={classnames(
                    'preview-body material',
                    {[`${assetType && Object.values<string>(FILE_TYPES_FOR_CONVERSION).includes(assetType) ? 'pdf' : _.toLower(assetType)}-body`]: assetType}
                )}>
                    {asset && assetType === IMAGE && (
                        <div
                            className='draggable-image-area'
                            ref={imageAreaRef}>
                            <div className={classnames(
                                'draggable-image',
                                {'is-panning': isPanning}
                            )}
                            style={{marginLeft: boardOffsetX, marginTop: boardOffsetY}}
                            onMouseDown={handleClickImage}
                            onMouseMove={handleMouseMove}
                            onMouseUp={handleMouseUp}>
                                <img
                                    className='preview-image'
                                    src={assetFile}
                                    onLoad={() => {
                                        setIsLoading(false);
                                        handleCenterImage();
                                    }}
                                    draggable={false}
                                    ref={imageRef}
                                    style={{transform: `scale(${zoomFactor})`}} />
                            </div>
                        </div>
                    )}
                    {asset && assetType && (Object.values<string>(FILE_TYPES_FOR_CONVERSION).includes(assetType)) && (
                        <AssetPdf
                            pdfUrl={pdfUrl}
                            name={asset.originalFilename}
                            readOnly
                            ref={pdfRef}
                            onLoad={() => {
                                setIsLoading(false);
                            }} />
                    )}
                    {asset && assetType && assetType === PDF && (
                        <AssetPdf
                            readOnly={readOnly}
                            annotationAuthor={authNameFormatter(userInfo) || undefined}
                            pdfUrl={pdfUrl}
                            editMode={assetPdfEditMode}
                            name={asset.originalFilename}
                            ref={pdfRef}
                            onLoad={() => {
                                setIsLoading(false);
                            }} />
                    )}
                    {/* {asset && assetType === SPREADSHEET && (
                        //clean all this up when we do PDF annotation epic
                        <AssetSpreadsheet
                            file={assetFileBlob}
                            name={asset.originalFilename}
                            zoomFactor={zoomFactor}
                            onLoad={() => {
                                setIsLoading(false);
                            }} />
                    )} */}
                    {asset && assetType === VIDEO && (
                        <AssetVideo
                            video={assetFile}
                            videoType={assetFileType}
                            name={asset.originalFilename}
                            onLoad={() => {
                                setIsLoading(false);
                            }} />
                    )}
                    {asset && assetType === UNSUPPORTED && (
                        <div className='unsupported-asset'>
                            FILE TYPE IS UNSUPPORTED FOR PREVIEW
                        </div>
                    )}
                </div>
                {isLoading && <Loading fill size={56} loadingText='Please wait. This can take a few moments...' />}
                {showNewNameModal && (
                    <Modal
                        className='new-name-modal modal-prompt fullpage-pop-prompt-modal'
                        maskStyle={{zIndex: 2000}}
                        open={showNewNameModal}
                        title='Save as a copy'
                        footer={false}
                        onCancel={() => setShowNewNameModal(false)}
                        destroyOnClose>
                        <div className='modal-body'>
                            <Input label='Name of Copy' value={newFileName} onChange={handleSetFileName} />
                        </div>
                        <div className='modal-footer'>
                            <div
                                className='cancel-button'
                                onClick={() => {
                                    setShowNewNameModal(false);
                                    setNewFileName('');
                                }}>
                                Cancel
                            </div>
                            <Button
                                className='btn-secondary submit-button'
                                onClick={handleCopyPdf}
                                disabled={isSubmittingFiles}>
                                {isSubmittingFiles && (
                                    <Loading inline />
                                )}
                                Save Copy
                            </Button>
                        </div>
                    </Modal>
                )}
            </div>
        )
        : null;
};

export default AssetPreview;
