import {useDispatch} from 'react-redux';
import {signInApi} from 'src/api/Auth';
import {getUserStatus} from 'src/redux/auth/actions';
import {setTokens} from 'src/utils/token.utils';
import {FormValues} from 'src/Views/Login/Forms/ForgotPassForm/useValidationSchema';

const useSubmit = (): (values: FormValues) => Promise<void> => {
    const dispatch = useDispatch();
    return async(values: FormValues): Promise<void> => {
        const response = await signInApi({username: values.email, password: values.password});
        if (response.tokens) { // sign in final step
            setTokens(response.tokens.accessToken, response.tokens.refreshToken, response.username);
            dispatch(getUserStatus());
        }
    };
};

export default useSubmit;
