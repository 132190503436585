import Loading from 'src/Components/Loading';
import {useSelector} from 'react-redux';
import {Navigate, matchPath, useLocation} from 'react-router-dom';
import {NonAuthRoutes} from 'src/constants/userRoles';
import {RootState} from '../redux/rootReducer';
import {ID, genericFn} from 'src/Types/CommonTypes';
import {OnboardContext, onboardStatus as defaultOnboardStatus, OnboardStatus} from 'src/hooks/useOnboarding';
import React, {useState, useEffect} from 'react';
import {NewContentContext, NewContent} from 'src/hooks/useNewContent';
import _ from 'lodash';
import {getNewContentForUser} from 'src/api/User/user-api';
import Pings from 'src/lib/pings';

interface Props {
    children: JSX.Element
    locationId?: number
    showMenu?: boolean
    onHideMenu?: genericFn
}

const AuthRoute = ({children, locationId, ...componentProps}: Props): JSX.Element => {
    const location = useLocation();
    const auth = useSelector((state: RootState) => state.auth);
    const {pathname} = useLocation();
    const matchedPath = matchPath({path: '/store/:storeId/*'}, pathname);
    const storeId = _.get(matchedPath, 'params.storeId');
    const message = 'Please log in to view this page';
    const [onboardStatus, setOnboardStatus] = useState<OnboardStatus>();
    const [newContent, setNewContent] = useState<NewContent[]>();

    const handleNewContent = async(storeId: ID, init = false) => {
        try {
            const brandNewContent = await getNewContentForUser(storeId, init);
            setNewContent((newContent) => {
                const newContentUpdates: NewContent[] = _.cloneDeep(newContent || []);
                const brandNewContentById = _.keyBy(brandNewContent, 'id');
                const mergedSet = new Set();
                //replace current newContent asset with new one
                for (let asset of newContentUpdates) {
                    if (brandNewContentById[asset.id]) {
                        asset = brandNewContentById[asset.id];
                        if (brandNewContentById[asset.id].logAction !== 'pdf_annotations' && asset.promptLoad !== false) {
                            asset.promptLoad = true;
                        }
                        mergedSet.add(asset.id);
                    }
                }
                //a new asset has updated content, add to array
                for (const newAsset of brandNewContent) {
                    if (!mergedSet.has(newAsset.id)) {
                        if (newAsset.logAction !== 'pdf_annotations') {
                            newAsset.promptLoad = true;
                        }
                        newContentUpdates.push(newAsset);
                    }
                }
                return newContentUpdates;
            });
        } catch (e: any) {
            //suppress error
        }
    };

    useEffect(() => {
        if (auth.isLoading === false && auth.isSignedIn) {
            if (!onboardStatus) {
                setOnboardStatus(Object.assign({}, defaultOnboardStatus, auth.onboardStatus));
            }
            if (!newContent && storeId) {
                Pings.removePing('newContentPing');
                //begin ping'ing for lock updates
                Pings.addPing({
                    id: 'newContentPing',
                    pingOnInit: true,
                    frequency: 10,
                    unit: 'seconds',
                    task: ({init}) => {
                        handleNewContent(storeId, init);
                    },
                    onError: _.noop,
                });
            }
        }
    }, [auth, storeId]);

    if (auth.isLoading !== false) {
        return (<Loading fill size={56} />);
    }

    return auth.isSignedIn
        ? (
            <NewContentContext.Provider value={{newContent, setNewContent}}>
                <OnboardContext.Provider value={{onboardStatus, setOnboardStatus}}>
                    {/* <Onboarding onboardType='welcome' /> */}
                    {React.cloneElement(
                        children,
                        {
                            ...componentProps,
                            locationId,
                        }
                    )}
                </OnboardContext.Provider>
            </NewContentContext.Provider>
        )
        : <Navigate
            to={NonAuthRoutes.signin}
            state={{
                message,
                requestedPath: location && location.pathname,
            }} />;
};

export default AuthRoute;
