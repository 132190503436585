import apiHelper from '../api-helper';
import {ID} from 'src/Types/CommonTypes';

export interface WorkspaceLink {
    name?: string
    url?: string
    tags?: number[]
}

export const getWorkspaceLinks = (workspaceId: ID) => {
    return apiHelper.get(`/seasons/${workspaceId}/links`);
};

export const postWorkspaceLink = (workspaceId: ID, link: WorkspaceLink) => {
    return apiHelper.post(`/seasons/${workspaceId}/links`, link);
};

export const getWorkspaceLink = (linkId: ID) => {
    return apiHelper.get(`/seasons/links/${linkId}`);
};

export const updateWorkspaceLink = (linkId: ID, link: WorkspaceLink) => {
    return apiHelper.put(`/seasons/links/${linkId}`, link);
};

export const deleteWorkspaceLink = (linkId: ID) => {
    return apiHelper.delete(`/seasons/links/${linkId}`);
};
