import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useNavigate, useLocation, useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {nanoid} from 'nanoid';
import './styles.sass';
import _ from 'lodash';
import dayjs from 'dayjs';

//icons
import {Pen, Note} from 'src/lib/svg-icon-helper';
import {CheckOutlined, PlusOutlined} from '@ant-design/icons';
import tipIcon from 'src/assets/icons/tip-hex.svg';

//components
import {DatePicker, Divider, Space, Typography} from 'antd';
import BrandRenderer from 'src/Components/BrandRenderer';
import Button from 'src/Components/Button';
import ControlHeader from 'src/Components/ControlHeader';
import Input from 'src/Components/Input';
import Loading from 'src/Components/Loading';
import Modal from 'src/Components/Modal';
import PopIcon from 'src/Components/PopIcon';
import {Prompt} from 'src/Components/ModalPrompt';
import Select from 'src/Components/Select';
import Table, {SortBy} from 'src/Components/Table';
import NoResults from 'src/Components/NoResults';
import EmptyState from 'src/Components/EmptyState';

//apis
import {
    getTasks,
    markTask,
    createTask,
    updateTasks,
    deleteTasks,
    TaskForApi,
    Task,
} from 'src/api/Tasks/api-tasks';
import {getUsers} from 'src/api/User/user-api';
import {BrandCard, getBrandCards, ParamsForBrandCard} from 'src/api/BrandCard/api-brand-card';
import {getAllWorkspaces, ApiWorkspace} from 'src/api/Workspace/api-workspace';

//types
import {ID, ReactEvent, ReactTextareaEvent, ReactAnchorEvent, AlignType} from 'src/Types/CommonTypes';
import {RootState} from 'src/redux/rootReducer';
import {User} from 'src/Types/User/types';

//libs
import {Filter, handleFilterValidations, removeFilterFactory, GLOBAL_FILTER_DEBOUNCE_RATE} from 'src/lib/filter-helper';
import {classnames, debouncePromise, _get, invisibleCharacter, filterMap} from 'src/utils/general';
import {formatDate} from 'src/lib/date-helper';
import {getQueryParams, HistoryHelperFactory} from 'src/lib/url';
import workspaceFilter from 'src/lib/filters/workspace';
import brandFilter from 'src/lib/filters/brand';
import assigneeFilter from 'src/lib/filters/task-assignee';
import priorityFilter from 'src/lib/filters/task-priority';
import statusFilter from 'src/lib/filters/task-status';
import {authNameFormatter, nameFormatter} from 'src/lib/name-helper';
import useWindow from 'src/hooks/useWindow';
import {AuthState} from 'src/redux/auth/actionTypes';


const taskPriorityTypes = {
    high: 'High',
    medium: 'Medium',
    low: 'Low',
};
const taskPriorityTypesToColor = {
    high: 'red',
    medium: 'orange',
    low: 'yellow',
};
const taskPriorities = {
    low: 3,
    medium: 2,
    high: 1,
};

interface TaskForApiSubmit extends TaskForApi {
    workspaceTitle?: string | null
}

interface FilterDependencies {
    allUsers?: User[]
    allTasks?: Task[]
    allBrands?: BrandCard[]
    workspaces?: ApiWorkspace[]
    brandFilterHasEmptyFilterOption?: boolean
    authUser?: AuthState
}

interface PropTypes {
    brandCardId?: ID
    workspaceId?: ID
    locationId?: number
    readOnly: boolean
}
const unchangedMultiSelectId = `(Several)${invisibleCharacter}`;

const authNameFormatterForTasks = (auth: AuthState, isPrivate = false): string => {
    return `${authNameFormatter(auth)}${isPrivate ? ' (private)' : ''}`;
};

const TaskDashboard = ({brandCardId, workspaceId, locationId, readOnly}: PropTypes): JSX.Element => {
    //temporary until we allow brandCard task manager to use filters
    const DISABLE_QUERY_PARAM = Boolean(brandCardId);
    const location = useLocation();

    const queryParams = getQueryParams(location);
    const historyHelper = HistoryHelperFactory(useNavigate());
    const stores = useSelector((state: RootState) => state.stores.stores);
    const auth = useSelector((state: RootState) => state.auth);
    const {storeId} = useParams<{storeId: any}>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [textSearchValue, setTextSearchValue] = useState(
        (
            !DISABLE_QUERY_PARAM
            && (_.isArray(queryParams.textSearch) ? queryParams.textSearch.join(' ') : queryParams.textSearch)
        )
        || ''
    );
    const [filterData, setFilterData] = useState<any>();
    const [filterValues, setFilterValues] = useState<any>({});
    const [areFilterValuesDefault, setAreFilterValuesDefault] = useState(true);
    const [workspaces, setWorkspaces] = useState<ApiWorkspace[]>([]);
    const [allBrands, setAllBrands] = useState<BrandCard[]>([]);
    const [tasks, setTasks] = useState<Task[]>([]);
    const [allTasks, setAllTasks] = useState<Task[]>([]);
    const [showManageModal, setShowManageModal] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const [taskToEdit, setTaskToEdit] = useState<Task>();
    const [isSubmittingTasks, setIsSubmittingTasks] = useState<boolean>(false);
    const [isDeletingTask, setIsDeletingTask] = useState<boolean>(false);
    const [multiSelectItems, setMultiSelectItems] = useState<any[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<ID[]>([]);
    const [toggleTaskIdSet, setToggleTaskIdSet] = useState<Set<ID>>(new Set());
    const [taskName, setTaskName] = useState<string>('');
    const [taskNotes, setTaskNotes] = useState<string>();
    const [taskBrand, setTaskBrand] = useState<BrandCard>();
    const [taskWorkspace, setTaskWorkspace] = useState<Record<'title', string>>();
    const [taskDeadline, setTaskDeadline] = useState<any>();
    const [initialBrand, setInititalBrand] = useState<BrandCard>();
    const [taskAssignee, setTaskAssignee] = useState<User>();
    const [taskPriority, setTaskPriority] = useState<keyof typeof taskPriorityTypes | null | 'none'>();
    const [allUsers, setAllUsers] = useState<User[]>();
    const callId = useRef<string | null>(null);
    const [workspacesCompact, setWorkspacesCompact] = useState<Record<'title', string>[]>([]);
    const [newWorkspace, setNewWorkspace] = useState<string>('');
    const shouldMultiEdit = multiSelectItems.length > 1 && !taskToEdit;
    const {windowIsSmall, windowIsTiny} = useWindow();
    const [sortBy, setSortBy] = useState<SortBy>({
        key: 'dueDate',
        title: 'Deadline',
    });

    useEffect(() => {
        const handleWorkspacesForDropdown = () => {
            const workspacesByTitle = _.groupBy(workspaces, 'title');
            const combinedWorkspaces = _.map(workspacesByTitle, (workspaces: ApiWorkspace[]) => {
                return {
                    title: workspaces[0].title,
                };
            });
            setWorkspacesCompact(_.sortBy(combinedWorkspaces, (workspace) => _.toLower(workspace.title)));
        };

        handleWorkspacesForDropdown();
    }, [workspaces]);

    const initialFilters = [statusFilter, assigneeFilter, priorityFilter];
    if (workspaceId === 'ALL' || !workspaceId) {
        initialFilters.push(workspaceFilter);
    }

    if (!brandCardId) {
        initialFilters.push(brandFilter);
    }

    const hasAnyTasks = Boolean(allTasks && allTasks.length > 0);
    const hasTasks = Boolean(tasks && tasks.length > 0);

    const rawFilters = _.keyBy(initialFilters, 'key');

    //filter options is raw input data
    const filterDependencies = useRef<FilterDependencies>({
        allBrands,
        allUsers,
        allTasks,
        workspaces,
        brandFilterHasEmptyFilterOption: true,
        authUser: auth,
    });

    const [filters, setFilters] = useState<Record<string, Filter>>(
        _.omitBy(rawFilters, (filter) => {
            return !filter.enabled(filterDependencies.current) && !filter.show(filterDependencies.current);
        })
    );

    const initFilters = (filterDependenciesInit = filterDependencies.current, filtersInit = filters) => {
        if (!hasAnyTasks) {
            return;
        }

        const filterData: Record<string, unknown> = {};
        for (const [filterKey, filter] of Object.entries(filtersInit)) {
            if (!filter.loadAfter && filter.show(filterDependenciesInit) && filter.enabled(filterDependenciesInit)) {
                filterData[filterKey] = filter.dataBuilder(filterDependenciesInit);
            }
        }
        //filter data is the processed and assigned data from `dataBuilder`
        setFilterData(filterData);
    };

    const getFilters = (filterDependencies: FilterDependencies, workspaceId: ID) => {
        const initialFilters = [statusFilter, assigneeFilter, priorityFilter];
        if (workspaceId === 'ALL' || !workspaceId) {
            initialFilters.push(workspaceFilter);
        }

        if (!brandCardId) {
            initialFilters.push(brandFilter);
        }
        //filters here must still be key'ed to their respective 'key'
        const rawFilters = _.keyBy(initialFilters, 'key');
        const filters = _.omitBy(
            rawFilters,
            (filter) => !filter.enabled(filterDependencies) && !filter.show(filterDependencies)
        );
        setFilters(filters);
        initFilters(filterDependencies, filters);
        return filters;
    };

    if (!isLoading && filters && !filterData && _.every(Object.values(filterDependencies.current))) {
        initFilters(filterDependencies.current, filters);
    }

    type HandlerProps = {
        init?: boolean
        textSearchValue?: string
        filterValuesFromState: any
    };

    const getTasksHandler = useCallback(async(
        currentCallId: string,
        brandCardId: ID,
        workspaceId: ID,
        {
            init = false,
            textSearchValue = undefined,
            filterValuesFromState = filterValues,
        }: HandlerProps
    ) => {

        type SearchObject = {
            textSearch?: string
            brand?: string[]
            workspace?: string[]
        };

        if (callId.current === currentCallId) {
            setIsLoading(true);
            const searchObject: SearchObject = {};
            if (textSearchValue) {
                searchObject.textSearch = textSearchValue;
            }
            const filters = getFilters(filterDependencies.current, workspaceId);
            for (const [filterKey, filterValue] of Object.entries(filterValuesFromState)) {
                if (_.get(filters, [filterKey, 'transformForAPI'])) {
                    filters[filterKey].transformForAPI(filterValue, searchObject, filterKey, filterDependencies.current);
                }
            }
            const finalSearchObject = (init && !DISABLE_QUERY_PARAM) ? queryParams : searchObject;
            //brandcard
            if (brandCardId) {
                finalSearchObject.brand = [brandCardId.toString()];
            }
            if (workspaceId && workspaceId !== 'ALL') {
                finalSearchObject.workspace = [workspaceId.toString()];
            }

            const filteredTasks = await getTasks(
                storeId,
                finalSearchObject
            );
            if (init) {
                //coming from brandCard
                const specializedSearchObject: SearchObject = {};
                if (workspaceId && workspaceId !== 'ALL') {
                    specializedSearchObject.workspace = [workspaceId.toString()];
                }
                //coming from "ALL" workspace
                if (brandCardId) {
                    specializedSearchObject.brand = [brandCardId.toString()];
                }
                const [
                    allUsers,
                    workspaces,
                    {data: allBrands},
                    allTasks,
                ] = await Promise.all([
                    getUsers(storeId),
                    getAllWorkspaces(storeId),
                    getBrandCards(storeId, {
                        perpage: 5000,
                        [ParamsForBrandCard.Assets]: false,
                        [ParamsForBrandCard.Contacts]: false,
                        [ParamsForBrandCard.Workspaces]: false,
                    }),
                    getTasks(storeId, specializedSearchObject),
                ]);
                setWorkspaces(workspaces);
                setAllBrands(allBrands);
                setAllUsers(allUsers);
                setAllTasks(allTasks);

                filterDependencies.current = {
                    ...filterDependencies.current,
                    allUsers,
                    workspaces,
                    allBrands,
                    allTasks,
                };

                const filters = getFilters(filterDependencies.current, workspaceId);

                const {
                    updatedQueryParamObject,
                    urlFilterValues,
                    didFiltersObviouslyChange,
                } = handleFilterValidations(
                    filters,
                    filterDependencies.current,
                    DISABLE_QUERY_PARAM ? filterValuesFromState : queryParams
                );

                setFilterValues(DISABLE_QUERY_PARAM ? filterValuesFromState : urlFilterValues);
                if (!DISABLE_QUERY_PARAM) {
                    historyHelper.updateQueryParams(updatedQueryParamObject, false);
                }

                if (didFiltersObviouslyChange) {
                    //filters changed, short-circuit this request and fire new query
                    const currentCallId = nanoid();
                    // eslint-disable-next-line require-atomic-updates
                    callId.current = currentCallId;
                    getTasksHandler(
                        currentCallId,
                        brandCardId,
                        workspaceId,
                        {filterValuesFromState: DISABLE_QUERY_PARAM ? filterValuesFromState : urlFilterValues}
                    );
                    return;
                } else {
                    setTasks(filteredTasks);
                }
            } else {
                setTasks(filteredTasks);
                if (!DISABLE_QUERY_PARAM) {
                    historyHelper.updateQueryParams(searchObject);
                }
            }
            setIsLoading(false);
        }
    }, [storeId]);

    const refetchTasks = () => {
        const currentCallId = nanoid();
        callId.current = currentCallId;
        getTasksHandler(
            currentCallId,
            brandCardId,
            workspaceId,
            {init: true, textSearchValue, filterValuesFromState: filterValues}
        );
    };

    useEffect(() => {
        const currentCallId = nanoid();
        callId.current = currentCallId;
        getTasksHandler(
            currentCallId,
            brandCardId,
            workspaceId,
            {
                init: true,
                textSearchValue,
                filterValuesFromState: brandCardId ? {taskStatus: [{id: 'incomplete', title: 'Incomplete'}]} : undefined,
            }
        );
    }, [storeId, workspaceId, brandCardId]);

    useEffect(() => {
        if (stores && !isLoading && !areFilterValuesDefault) {
            const currentCallId = nanoid();
            callId.current = currentCallId;
            debouncePromise(
                GLOBAL_FILTER_DEBOUNCE_RATE,
                () => getTasksHandler(
                    currentCallId,
                    brandCardId,
                    workspaceId,
                    {textSearchValue, filterValuesFromState: filterValues}
                )
            )();
        }
        if (!_.isEmpty(filterValues)) {
            setAreFilterValuesDefault(false);
        }
    }, [filterValues, textSearchValue]);

    useEffect(() => {
        if (stores && !isLoading) {
            const currentCallId = nanoid();
            callId.current = currentCallId;
            debouncePromise(
                GLOBAL_FILTER_DEBOUNCE_RATE,
                () => getTasksHandler(
                    currentCallId,
                    brandCardId,
                    workspaceId,
                    {init: true, textSearchValue, filterValuesFromState: {taskStatus: ['incomplete']}}
                )
            )();
        }
    }, [locationId]);

    useEffect(() => {
        if (filters && allBrands && workspaces) {
            initFilters({
                allBrands,
                allTasks,
                workspaces,
                allUsers,
                brandFilterHasEmptyFilterOption: true,
                authUser: auth,
            }, filters);
        }
    }, [filters, auth, allBrands, allTasks, workspaces]);

    const hideManageModal = () => {
        setIsSubmittingTasks(false);
        setShowManageModal(false);
        setIsCreating(false);
        setTaskToEdit(undefined);
        setTaskName('');
        setTaskDeadline(undefined);
        setTaskBrand(undefined);
        setTaskNotes('');
        setInititalBrand(undefined);
        setTaskPriority(undefined);
        setTaskAssignee(undefined);
        setTaskWorkspace(undefined);
    };

    const toggleTaskStatus = async(task: Task) => {
        if (toggleTaskIdSet.has(task.id)) {
            return false;
        } else {
            const newTasks = _.cloneDeep(tasks);
            const selectedTask = _.find(newTasks, (stateTask) => stateTask.id === task.id);
            if (selectedTask) {
                toggleTaskIdSet.add(task.id);
                setToggleTaskIdSet(toggleTaskIdSet);
                let needDelay = false;
                if (selectedTask.status === 'complete') {
                    selectedTask.status = 'incomplete';
                    needDelay = _.find(filterValues.taskStatus, {id: 'complete'});
                } else {
                    selectedTask.status = 'complete';
                    needDelay = _.find(filterValues.taskStatus, {id: 'incomplete'});
                }
                setTasks(newTasks);
                //handle delay for completion animation
                if (needDelay) {
                    setTimeout(() => {
                        const cleanedTasks = _.cloneDeep(newTasks);
                        _.remove(cleanedTasks, (newTask) => newTask.id === task.id);
                        setTasks(cleanedTasks);
                    }, 700);
                }
                await markTask(storeId, task.id, selectedTask.status);
                toggleTaskIdSet.delete(task.id);
                setToggleTaskIdSet(toggleTaskIdSet);
            }
        }
    };

    const handleMarkTasksComplete = async() => {
        const newTasks = _.cloneDeep(tasks);
        const toggleTaskIdSet = new Set(_.map(multiSelectItems, 'id'));
        setToggleTaskIdSet(toggleTaskIdSet);
        for (const task of newTasks) {
            if (toggleTaskIdSet.has(task.id)) {
                task.status = 'complete';
            }
        }
        setTasks(newTasks);
        const taskPromises = [];
        for (const task of multiSelectItems) {
            taskPromises.push(markTask(storeId, task.id, 'complete'));
        }
        if (_.find(filterValues.taskStatus, {id: 'incomplete'})) {
            setTimeout(() => {
                const cleanedTasks = _.cloneDeep(newTasks);
                _.remove(cleanedTasks, (task) => {
                    return _.find(multiSelectItems, {id: task.id});
                });
                setTasks(cleanedTasks);
            }, 700);
        }
        await Promise.all(taskPromises);
        setToggleTaskIdSet(new Set());
    };

    const handleMarkTasksIncomplete = async() => {
        const newTasks = _.cloneDeep(tasks);
        const toggleTaskIdSet = new Set(_.map(multiSelectItems, 'id'));
        setToggleTaskIdSet(toggleTaskIdSet);
        for (const task of newTasks) {
            if (toggleTaskIdSet.has(task.id)) {
                task.status = 'incomplete';
            }
        }
        setTasks(newTasks);
        const taskPromises = [];
        for (const task of multiSelectItems) {
            taskPromises.push(markTask(storeId, task.id, 'incomplete'));
        }
        if (_.find(filterValues.taskStatus, {id: 'complete'})) {
            setTimeout(() => {
                const cleanedTasks = _.cloneDeep(newTasks);
                _.remove(cleanedTasks, (task) => {
                    return _.find(multiSelectItems, {id: task.id});
                });
                setTasks(cleanedTasks);
            }, 700);
        }
        await Promise.all(taskPromises);
        setToggleTaskIdSet(new Set());
    };

    const handleCreateTask = () => {
        setShowManageModal(true);
        setIsCreating(true);
        setTaskToEdit(undefined);
    };

    const handleEditTasks = () => {
        const testBrandCard = _.get(multiSelectItems, [0, 'workspace', 'brandCard']) || multiSelectItems[0].brand;
        if (_.every(multiSelectItems, (task) => _.get(task, 'workspace.brandCard.id') === _.get(testBrandCard, 'id') || _.get(task.brand, 'id') === _.get(testBrandCard, 'id'))) {
            setTaskBrand(testBrandCard);
            setInititalBrand(testBrandCard);
        } else if (multiSelectItems.length) {
            setTaskBrand({id: unchangedMultiSelectId});
        }
        const testWorkSpaceTitle = _.get(multiSelectItems, [0, 'workspace', 'title']);
        if (_.every(multiSelectItems, (task) => _.get(task, 'workspace.title') === testWorkSpaceTitle)) {
            setTaskWorkspace({title: testWorkSpaceTitle});
        } else {
            setTaskWorkspace({title: unchangedMultiSelectId});
        }
        const testPriority = _.get(multiSelectItems, [0, 'priority']);
        if (_.every(multiSelectItems, (task) => _.get(task, 'priority') === testPriority)) {
            setTaskPriority(testPriority);
        } else {
            setTaskPriority(unchangedMultiSelectId);
        }

        if (multiSelectItems.length === 1) {
            const task = multiSelectItems[0];
            setTaskName(task.name);
            setTaskNotes(task.notes || '');
            setTaskDeadline(task.dueDate && dayjs(task.dueDate));
            setTaskPriority(task.priority);
            setTaskAssignee(task.assignee && Object.assign({}, task.assignee, {
                title: task.isPrivate ? `${authNameFormatter(auth)} (private)` : nameFormatter(task.assignee),
                label: task.isPrivate ? `${authNameFormatter(auth)} (private)` : nameFormatter(task.assignee),
                isPrivate: task.isPrivate,
                key: task.isPrivate ? `${auth.id}-PRIVATE` : task.assignee.id,
            }));
        }

        setShowManageModal(true);
    };

    const handleSubmitTasks = async() => {
        setIsSubmittingTasks(true);
        //these ignores cover when multiSelectItems have several brands, but the user does not wish to remove them
        const ignoreBrandUpdate = Boolean(taskBrand && taskBrand.id === unchangedMultiSelectId);
        const ignoreWorkspaceUpdate = Boolean(taskWorkspace && taskWorkspace.title === unchangedMultiSelectId);
        const ignorePriorityUpdate = taskPriority === unchangedMultiSelectId;
        const masterTask: TaskForApiSubmit = {};

        const isWorkspaceTask = taskBrand && taskWorkspace && taskWorkspace.title && !ignoreWorkspaceUpdate;

        if (isWorkspaceTask) {
            //workspace-level task
            masterTask.storeId = null;
            masterTask.workspaceTitle = taskWorkspace.title;
            masterTask.brandCardId = _get(taskBrand, 'id');
        } else if (taskBrand) {
            if (!ignoreBrandUpdate) {
                //brand-level task
                masterTask.brandCardId = _get(taskBrand, 'id');
                masterTask.storeId = null;
            }
            if (!ignoreWorkspaceUpdate) {
                masterTask.workspaceTitle = null;
                masterTask.workspaceId = null;
            }
        } else if (!taskBrand) {
            //store-level task
            masterTask.storeId = storeId;
            masterTask.brandCardId = null;
            masterTask.workspaceTitle = null;
            masterTask.workspaceId = null;
        }

        //creating from inside a brandCard, over-ride and set to brand-level
        if (brandCardId) {
            if ((taskBrand && taskBrand.id) && !ignoreBrandUpdate) {
                masterTask.brandCardId = taskBrand.id;
            } else if (isCreating) {
                masterTask.brandCardId = brandCardId;
            }
            masterTask.storeId = null;
            if (!ignoreWorkspaceUpdate && taskWorkspace) {
                masterTask.workspaceTitle = taskWorkspace.title;
            }
            if (!isWorkspaceTask && !ignoreWorkspaceUpdate) {
                masterTask.workspaceTitle = null;
                masterTask.workspaceId = null;
            }
        }
        //creating from inside a workspace, over-ride and set to workspace-level
        if (workspaceId) {
            if ((taskWorkspace && taskWorkspace.title) && !ignoreWorkspaceUpdate) {
                masterTask.brandCardId = _get(taskBrand, 'id');
                masterTask.storeId = null;
                masterTask.workspaceTitle = taskWorkspace.title;
            } else if (isCreating) {
                const workspaceIndex = _.findIndex(workspaces, (workspace) => workspace.id === Number(workspaceId));
                const workspace = workspaceIndex >= 0 && workspaces[workspaceIndex];
                masterTask.brandCardId = brandCardId;
                if (workspace) {
                    masterTask.workspaceTitle = workspace.title;
                }
            }
        }


        if (taskName) {
            masterTask.name = taskName;
        }
        if (taskNotes) {
            masterTask.notes = taskNotes;
        }
        if (!ignorePriorityUpdate) {
            masterTask.priority = taskPriority === 'none' ? null : taskPriority;
        }
        if (taskDeadline) {
            masterTask.dueDate = taskDeadline.toISOString();
        }
        if (taskAssignee) {
            masterTask.assignedTo = taskAssignee.id;
        }
        masterTask.isPrivate = (taskAssignee && taskAssignee.isPrivate) || false;

        if (isCreating) {
            await createTask(
                storeId,
                masterTask
            );
        } else {

            const tasksToUpdate = taskToEdit ? [taskToEdit] : multiSelectItems;
            const updatedTasks = [];
            for (const task of tasksToUpdate) {
                if (tasksToUpdate.length === 1) {
                    delete task.name;
                    delete task.notes;
                    delete task.dueDate;
                    delete task.assignedTo;
                    delete task.isPrivate;
                }
                const updatedTask = Object.assign({}, task, masterTask);
                if (isWorkspaceTask && ignoreBrandUpdate && !ignoreWorkspaceUpdate) {
                    /*
                        * Brand wasnt updated as part of the multi-select, so here it can read "(Several)".
                        * That would already be blanked above, but we need it to determine which brandcard the 
                        * season belongs to (if any), so send it along here.
                    */
                    updatedTask.brand_card_id = _.get(task, 'brand.id');
                    if (task.workspaceId) {
                        updatedTask.workspaceTitle = taskWorkspace.title;
                    }
                }
                updatedTasks.push(updatedTask);
            }
            await updateTasks(
                storeId,
                updatedTasks
            );
        }
        refetchTasks();
        hideManageModal();
        setMultiSelectItems([]);
        setSelectedRowKeys([]);
    };

    const handleAddWorkspace = (event: ReactAnchorEvent) => {
        event.preventDefault();
        const newWorkspaceObject = {title: newWorkspace};

        setTaskWorkspace(newWorkspaceObject);
        const workspacesCompactUpdated = [newWorkspaceObject, ..._.cloneDeep(workspacesCompact)];

        setWorkspacesCompact(workspacesCompactUpdated);
        setNewWorkspace('');
    };

    const manageModalTitle = () => {
        let titleText = 'Create Task';
        if (!isCreating) {
            if (shouldMultiEdit) {
                titleText = `Edit Tasks (${multiSelectItems.length})`;
            } else {
                titleText = 'Edit Task';
            }
        }

        return (
            <div className='manage-task-modal-title'>
                {titleText}
            </div>
        );
    };

    const handleDeleteTasks = async() => {
        await new Prompt().yesNoDanger(
            {
                body: multiSelectItems.length > 1 ? 'Are you sure you want to delete these tasks?' : 'Are you sure you want to delete this task?',
                title: multiSelectItems.length > 1 ? 'Delete Tasks' : 'Delete Task',
                displayType: 'delete',
                onYes: async() => {
                    setIsDeletingTask(true);
                    await deleteTasks(storeId, _.map(multiSelectItems, 'id'));
                    setMultiSelectItems([]);
                    setSelectedRowKeys([]);
                    refetchTasks();
                    setIsDeletingTask(false);
                },
            }
        );
    };

    const handleDeleteTask = async() => {
        await new Prompt().yesNoDanger(
            {
                body: 'Are you sure you want to delete this task?',
                title: 'Delete Task',
                displayType: 'delete',
                onYes: async() => {
                    setIsDeletingTask(true);
                    await deleteTasks(storeId, [_.get(taskToEdit, 'id', '')]);
                    refetchTasks();
                    setIsDeletingTask(false);
                    hideManageModal();
                },
            }
        );
    };

    const handleSetTaskName = (event: ReactEvent) => {
        setTaskName(event.target.value);
    };

    const handleSetTaskBrand = (id: ID, selectedBrand: BrandCard) => {
        setTaskBrand(selectedBrand);
    };

    const handleSetTaskNotes = (event: ReactTextareaEvent) => {
        setTaskNotes(event.target.value);
    };

    const handleSetTaskAssignee = (id: ID, selectedUser: User) => {
        setTaskAssignee(selectedUser);
    };

    const handleSetTaskDeadline = (date: any) => {
        setTaskDeadline(date);
    };

    const handleSetTaskWorkspace = (id: ID, selectedWorkspace: Record<'title', string>) => {
        setTaskWorkspace(selectedWorkspace);
    };

    const handleWorkspaceSearchTextChange = (searchText: string) => {
        setNewWorkspace(searchText);
    };

    const handleOnKeyDownWorkspace = (e: any) => {
        if (e.key === 'Enter') {
            const values = _.filter(workspacesCompact, (workspace) => {
                return workspace.title.toLowerCase().includes(_.toLower(newWorkspace));
            });
            if (values.length === 0) {
                const newWorkspaceObject = {title: newWorkspace};

                setTaskWorkspace(newWorkspaceObject);
                const workspacesCompactUpdated = [newWorkspaceObject, ..._.cloneDeep(workspacesCompact)];

                setWorkspacesCompact(workspacesCompactUpdated);
                setNewWorkspace('');
            }
        }
    };

    const handleSetTaskPriority = (id: ID, selectedPriority?: Record<'value', keyof typeof taskPriorityTypes | 'none'>) => {
        if (selectedPriority) {
            setTaskPriority(selectedPriority.value);
        }
    };

    const handleSetFilter = (filterValues: Record<any, any>[]) => {
        setFilterValues(filterValues);
    };

    const handleMultiSelect = (ids: number[], selectedTasks: Task[], {type}: Record<'type', string>) => {
        if (type === 'all') {
            if (multiSelectItems.length && (multiSelectItems.length === tasks.length)) {
                setMultiSelectItems([]);
                setSelectedRowKeys([]);
            } else {
                setMultiSelectItems(tasks);
                setSelectedRowKeys(_.map(tasks, 'id'));
            }
        } else {
            setMultiSelectItems(selectedTasks);
            setSelectedRowKeys(_.map(selectedTasks, 'id'));
        }
    };

    const shouldDisableTaskSubmit = () => {
        return isSubmittingTasks
            || (
                !shouldMultiEdit
                && !taskName
            );
    };

    const priorityOptions = [
        ...Object.keys(taskPriorityTypes).map((priority: keyof typeof taskPriorityTypes) => ({
            title: (
                <div className='task-priority'>
                    <span className={`task-priority-ball task-priority--${taskPriorityTypesToColor[priority]}`} />
                    <span className='task-priority-label'>{taskPriorityTypes[priority]}</span>
                </div>
            ),
            value: priority,
            key: priority,
        })),
        {
            title: (
                <div className='task-priority'>
                    <span className={'task-priority-ball task-priority--none'} />
                    <span className='task-priority-label'>None</span>
                </div>
            ),
            value: 'none',
            key: 'none',
        },
    ];

    const windowIsSmallOnBrandCard = (windowIsSmall && Boolean(brandCardId));

    const computedClassName = classnames(
        'task-manager',
        {
            'dashboard-container': !brandCardId,
        }
    );

    const columns = [{
        title: 'Task Name',
        dataIndex: 'name',
        key: 'name',
        ellipsis: true,
        render: (UNUSED: any, task: Task) => {
            return (
                <div className='task-status'>
                    {!readOnly && (
                        <Button
                            className={classnames(
                                'task-status-btn btn-circle btn-icon',
                                {'btn-white': task && (task.status === 'incomplete')},
                                {'btn-secondary': task && (task.status === 'complete')}
                            )}
                            onClick={() => toggleTaskStatus(task)}>
                            <CheckOutlined />
                        </Button>
                    )}
                    <span className='task-status-name single-line-ellipsis'>{task.name}</span>
                </div>
            );
        },
    },
    {
        title: 'Assignee',
        dataIndex: 'assignee',
        key: 'assignedTo',
        width: windowIsSmallOnBrandCard ? '18%' : '16%',
        ellipsis: true,
        cellTitle: (task: Task) => task.assignee && nameFormatter(task.assignee),
        render: (UNUSED: any, task: Task) => {
            return task.assignee && (
                <span>{`${nameFormatter(task.assignee)}${task.isPrivate ? ' (private)' : ''}`}</span>
            );
        },
    },
    {
        title: 'Deadline',
        dataIndex: 'dueDate',
        key: 'dueDate',
        width: 105,
        ellipsis: true,
        render: (UNUSED: any, task: Task) => {
            if (task && task.dueDate) {
                const today = new Date().setHours(0, 0, 0, 0);
                const dueDate = new Date(task.dueDate);
                const isLate = today > dueDate.getTime();
                return task.dueDate && (
                    <span className={isLate ? 'deadline-is-late' : ''}>
                        {formatDate(task.dueDate)}
                    </span>
                );
            }
            return;
        },
    },
    {
        title: 'Brand Card',
        dataIndex: 'brandCard',
        width: '12%',
        key: 'brandCard',
        filtered: Boolean(brandCardId),
        ellipsis: true,
        render: (UNUSED: any, task: Task) => {
            const brandCard = task.brand || (task.workspace && task.workspace.brandCard);
            return (
                <BrandRenderer brandCard={brandCard} origin='tasks' />
            );
        },
    },
    {
        title: 'Workspace',
        width: '12%',
        ellipsis: true,
        dataIndex: ['workspace', 'title'],
        key: 'Workspace-title',
        filtered: Boolean(workspaceId && workspaceId !== 'ALL') || windowIsTiny,
        cellTitle: (workspaceName: string) => workspaceName,
        // eslint-disable-next-line react/display-name
        render: (workspaceName: string) => {
            return workspaceName && (
                <div className='tag-container'>
                    <span className='tag workspace-tag single-line-ellipsis'>{workspaceName}</span>
                </div>
            );
        },
    },
    {
        title: (windowIsSmallOnBrandCard || windowIsTiny) ? '' : 'Priority',
        dataIndex: 'priority',
        key: 'priority',
        width: (windowIsSmallOnBrandCard || windowIsTiny) ? 45 : 95,
        cellTitle: (task: Task) => _.get(taskPriorityTypes, task && task.priority),
        render: (UNUSED: any, task: Task) => {
            return task && task.priority
                ? (
                    <div className='task-priority'>
                        <span className={`task-priority-ball task-priority--${taskPriorityTypesToColor[task.priority]}`} />
                        {!(windowIsSmallOnBrandCard || windowIsTiny) && (
                            <span className='task-priority-label'>{taskPriorityTypes[task.priority]}</span>
                        )}
                    </div>
                )
                : (
                    <div className='task-priority'>
                        <span className={'task-priority-ball task-priority--none'} />
                    </div>
                );
        },
    },
    {
        title: 'Notes',
        key: 'notes',
        className: 'notes-td',
        align: AlignType.center,
        width: 60,
        // eslint-disable-next-line react/display-name
        render: (UNUSED: any, task: Task) => {
            return task && task.notes && (
                <PopIcon
                    className='action-button primary-color'
                    placement='bottomRight'
                    content={task.notes}
                    origin={<span><Note /></span>} />
            );
        },
    }];

    if (!readOnly) {
        columns.push({
            key: 'actions',
            className: 'actions-td',
            align: AlignType.center,
            title: '',
            width: 55,
            // eslint-disable-next-line react/display-name
            render: (UNUSED: any, task: Task) => (
                <div className='action-button primary-color' onClick={() => {
                    const brandCard = task.brand || (task.workspace && task.workspace.brandCard);
                    const taskAssignee = task && task.assignee;
                    setShowManageModal(true);
                    setTaskToEdit(task);
                    setTaskBrand(brandCard);
                    setTaskName(task.name);
                    setTaskDeadline(task && task.dueDate && dayjs(task.dueDate));
                    setTaskNotes(_get(task, 'notes', ''));
                    setInititalBrand(brandCard);
                    setTaskPriority(task.priority);
                    setTaskAssignee(taskAssignee && Object.assign({}, taskAssignee, {
                        title: taskAssignee.id === auth.id
                            ? authNameFormatterForTasks(auth, task.isPrivate)
                            : nameFormatter(taskAssignee),
                        label: taskAssignee.id === auth.id
                            ? authNameFormatterForTasks(auth, task.isPrivate)
                            : nameFormatter(taskAssignee),
                        isPrivate: task.isPrivate,
                        key: task.isPrivate ? `${auth.id}-PRIVATE` : auth.id,
                    }));
                    setTaskWorkspace({title: _get(task, 'workspace.title')});
                }}>
                    <Pen />
                </div>
            ),
        });
    }

    const limitedBrands = _.filter(allBrands, (brandCard) => !brandCard.isLimitedByPlan);

    return (
        <div className={computedClassName}>
            <div className='task-manager-header'>
                <ControlHeader
                    setSearch={setTextSearchValue}
                    search={textSearchValue}
                    disableSearch={isLoading}
                    filterButtonId='filter-task-button'
                    multiSelectActionsId='multi-select-task-button'
                    multiSelectActions={[
                        {
                            key: 'edit',
                            onClick: handleEditTasks,
                            text: 'Edit',
                            disabled: readOnly || !multiSelectItems.length,
                        },
                        {
                            key: 'complete',
                            onClick: handleMarkTasksComplete,
                            text: 'Mark as Complete',
                            disabled: readOnly || !multiSelectItems.length,
                        },
                        {
                            key: 'incomplete',
                            onClick: handleMarkTasksIncomplete,
                            text: 'Mark as Incomplete',
                            disabled: readOnly || !multiSelectItems.length,
                        },
                        {
                            key: 'delete',
                            onClick: handleDeleteTasks,
                            text: 'Delete',
                            disabled: readOnly || !multiSelectItems.length,
                        },
                    ]}
                    placeholder='Search by task name'
                    handleSetFilter={handleSetFilter}
                    handleRemoveFilter={removeFilterFactory(filterValues, handleSetFilter)}
                    filterValues={filterValues}
                    filterData={filterData}
                    filters={allTasks && filters}
                    showSort={hasTasks}
                    sortOptions={[
                        {
                            key: 'assignee',
                            title: 'Assignee',
                            func: (task) => task.assignee && `${nameFormatter(task.assignee)}${task.isPrivate ? ' (private)' : ''}`,
                        },
                        {
                            key: 'brandCard.name',
                            title: 'Brand Card',
                            func: (task) => _.get(task, 'workspace.brandCard.name') || _.get(task, 'brand.name'),
                        },
                        {
                            key: 'dueDate',
                            title: 'Deadline',
                        },
                        {
                            key: 'name',
                            title: 'Name',
                        },
                        {
                            key: 'priority',
                            title: 'Priority',
                            func: (task) => task.priority && taskPriorities[task.priority],
                        },
                    ]}
                    setSortOption={setSortBy}
                    activeSort={sortBy} />

                <Button
                    onClick={handleCreateTask}
                    disabled={readOnly}
                    id='create-task-button'
                    className='create-task-button btn-action'>
                    <PlusOutlined className='icon left-icon' />Task
                </Button>
            </div>
            {isLoading && (
                <Loading fill size={56} />
            )}
            {!isLoading && (
                <Table
                    locale={hasAnyTasks
                        ? {emptyText: (<NoResults />)}
                        : {emptyText: (<EmptyState objectName='Task' handleCreate={handleCreateTask} readOnly={readOnly} />)}}
                    columns={columns}
                    shouldScroll={true}
                    data={tasks}
                    rowClassName={(record: Task): string => {
                        const completeFiltered = _.find(filterValues.taskStatus, {id: 'incomplete'});

                        if (completeFiltered && record.status === 'complete') {
                            return 'task-row-complete-animate';
                        }
                        return record.status === 'complete' ? 'task-row-complete' : '';
                    }}
                    id={brandCardId ? 'BRAND_CARD_TASK_TABLE' : 'TASK_TABLE'}
                    useCheckboxes={true}
                    onCheckboxClick={handleMultiSelect}
                    selectedRowKeys={selectedRowKeys}
                    sortBy={sortBy}
                    scrollParentSelector='.task-table .ant-table-body'
                    className='task-table' />
            )}
            <Modal
                className='manage-task-modal'
                open={showManageModal}
                title={manageModalTitle()}
                footer={false}
                onCancel={hideManageModal}
                destroyOnClose>
                <div className='modal-body'>
                    {!shouldMultiEdit && (
                        <>
                            <Input
                                required
                                name='task-name'
                                label='Name'
                                onChange={handleSetTaskName}
                                value={taskName} />
                            <div>
                                <div className='pop-label'>
                                    <label className='form-label' htmlFor='task-assignee'>
                                        Assignee
                                    </label>
                                    <PopIcon
                                        className='btn-tip'
                                        type='tip'
                                        content='Use the assignee field to assign this task to yourself or another member of your team. You can select Your Name (private) to create a task that is only viewable to you; all other tasks are viewable within your team.'
                                        origin={<img src={tipIcon} />} />
                                </div>
                                <Select
                                    className='task-assignee-select'
                                    id='task-assignee'
                                    labelKey='label'
                                    onChange={handleSetTaskAssignee}
                                    options={allUsers && [
                                        //put yourself and private self up first
                                        {
                                            title: `${authNameFormatter(auth)} (private)`,
                                            label: `${authNameFormatter(auth)} (private)`,
                                            isPrivate: true,
                                            id: auth.id,
                                            key: `${auth.id}-PRIVATE`,
                                        },
                                        {
                                            title: authNameFormatter(auth),
                                            label: authNameFormatter(auth),
                                            isPrivate: false,
                                            id: auth.id,
                                            key: auth.id,
                                        },
                                        ...filterMap(
                                            allUsers,
                                            (user) => user.id !== auth.id,
                                            (user) =>
                                                Object.assign({}, user, {
                                                    title: nameFormatter(user),
                                                    label: nameFormatter(user),
                                                    key: user.id,
                                                })
                                        ),
                                    ]}
                                    valueKey='title'
                                    value={taskAssignee && Object.assign({}, taskAssignee, {
                                        title: taskAssignee.id === auth.id
                                            ? authNameFormatterForTasks(auth, taskAssignee.isPrivate)
                                            : nameFormatter(taskAssignee),
                                        label: taskAssignee.id === auth.id
                                            ? authNameFormatterForTasks(auth, taskAssignee.isPrivate)
                                            : nameFormatter(taskAssignee),
                                        isPrivate: taskAssignee.isPrivate,
                                        key: taskAssignee.isPrivate ? `${auth.id}-PRIVATE` : auth.id,
                                    })} />
                            </div>
                        </>
                    )}
                    <div className='manage-modal-form-row'>
                        {!shouldMultiEdit && (
                            <div>
                                <div className='pop-label'>
                                    <label className='form-label' htmlFor='task-deadline'>
                                        Deadline
                                    </label>
                                    <PopIcon
                                        className='btn-tip'
                                        type='tip'
                                        content='Use the deadline date picker to set a due date for this task.'
                                        origin={<img src={tipIcon} />} />
                                </div>
                                <DatePicker
                                    name='task-deadline'
                                    label='Deadline'
                                    onChange={handleSetTaskDeadline}
                                    format='M/D/YYYY'
                                    value={taskDeadline} />
                            </div>
                        )}
                        {!(isCreating && brandCardId) && (
                            <div>
                                <div className='pop-label'>
                                    <label className='form-label' htmlFor='task-brand'>
                                        Brand Card
                                    </label>
                                    <PopIcon
                                        className='btn-tip'
                                        type='tip'
                                        content='The Brand Card field is a dropdown menu with all of your created brand cards. Choosing a brand will associate this task to that particular brand and make it viewable in the Brand Card.'
                                        origin={<img src={tipIcon} />} />
                                </div>
                                <Select
                                    notFoundContent={allBrands?.length
                                        ? (<div>No results found</div>)
                                        : (<div>No Brands added yet</div>)}
                                    className='task-brand-select'
                                    id='task-brand'
                                    message={((_.get(initialBrand, 'id') === _.get(taskBrand, 'id')) || !brandCardId)
                                        ? undefined
                                        : `Once this action is confirmed, ${shouldMultiEdit ? 'these tasks' : 'this task'} will no longer be viewable in the ${_.get(initialBrand, 'name')} Brand Card.`}
                                    labelKey='name'
                                    dropdownMatchSelectWidth={225}
                                    onChange={handleSetTaskBrand}
                                    options={limitedBrands}
                                    expectScroll={!shouldMultiEdit}
                                    valueKey='id'
                                    value={taskBrand} />
                            </div>
                        )}
                        <div>
                            <div className='pop-label'>
                                <label className='form-label' htmlFor='task-workspace'>
                                    Workspace
                                </label>
                                <PopIcon
                                    className='btn-tip'
                                    type='tip'
                                    content='The workspace field is a dropdown menu with all of your created workspaces. You can use it to select an existing workspace or create a new one to associate this task with.'
                                    origin={<img src={tipIcon} />} />
                            </div>
                            <Select
                                notFoundContent={workspaces?.length || newWorkspace.length > 0
                                    ? (<div/>)
                                    : (<div>No Workspaces added yet</div>)}
                                className='task-workspace-select'
                                disabled={(isCreating && brandCardId) ? false : !taskBrand}
                                dropdownMatchSelectWidth={275}
                                id='task-workspace'
                                labelKey='title'
                                onChange={handleSetTaskWorkspace}
                                onSearch={handleWorkspaceSearchTextChange}
                                onKeyDown={handleOnKeyDownWorkspace}
                                options={workspacesCompact}
                                expectScroll={!shouldMultiEdit}
                                valueKey='title'
                                dropdownRender={(menu) => (
                                    <>
                                        {newWorkspace.length > 0 && (
                                            <Space align='center' style={{padding: '0 8px 4px'}}>
                                                <Typography.Link onClick={handleAddWorkspace} style={{whiteSpace: 'nowrap'}}>
                                                    <PlusOutlined /> Add new <b>{newWorkspace}</b>
                                                </Typography.Link>
                                            </Space>
                                        )}
                                        {menu}
                                        <Divider style={{margin: '8px 0'}} />
                                    </>
                                )}
                                value={taskWorkspace} />
                        </div>
                        <div>
                            <label className='form-label' htmlFor='task-priority'>
                                Priority
                            </label>
                            <Select
                                className='task-priority-select'
                                id='task-priority'
                                labelKey='title'
                                onChange={handleSetTaskPriority}
                                options={priorityOptions}
                                onClear={() => setTaskPriority(undefined)}
                                showSearch={false}
                                expectScroll={!shouldMultiEdit}
                                valueKey='value'
                                value={{value: taskPriority || 'none'}} />
                        </div>
                    </div>
                    {!shouldMultiEdit && (
                        <div>
                            <div className='pop-label'>
                                <label className='form-label' htmlFor='notes'>
                                    Notes
                                </label>
                                <PopIcon
                                    className='btn-tip'
                                    type='tip'
                                    content='Use notes to keep track of useful information that can be viewed by you and the rest of your team.'
                                    origin={<img src={tipIcon} />} />
                            </div>
                            <textarea
                                id='notes'
                                className='form-textarea'
                                maxLength={250}
                                value={taskNotes}
                                placeholder='Add Notes (250 characters max)'
                                onChange={handleSetTaskNotes} />
                        </div>
                    )}
                </div>
                <div className='modal-footer'>
                    {(!isCreating && !shouldMultiEdit) && (
                        <Button
                            onClick={handleDeleteTask}
                            className='btn-delete'>
                            {isDeletingTask && (
                                <Loading inline />
                            )}
                            Delete Task
                        </Button>
                    )}
                    <div
                        className='cancel-button'
                        onClick={hideManageModal}>
                        Cancel
                    </div>
                    <Button
                        disabled={shouldDisableTaskSubmit()}
                        className='btn-secondary'
                        onClick={handleSubmitTasks}>
                        {isSubmittingTasks && (
                            <Loading inline />
                        )}
                        Save
                    </Button>
                </div>
            </Modal>
        </div>
    );
};

export default TaskDashboard;
