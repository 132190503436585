import {AuthActions, AuthActionTypes, AuthState} from './actionTypes';

const initialState: AuthState = {
    isLoading: null,
    isSignedIn: false,
    signInUrl: '',
    signUpUrl: '',
    name: '',
    familyName: '',
    id: undefined,
    email: '',
    intercomUserId: undefined,
    onboardStatus: undefined,
    hasStores: false,
    isMainMenuPinned: false,
    brandView: 'table',
};

const AuthReducer = (state = initialState, action: AuthActions): AuthState => {
    switch (action.type) {
        case AuthActionTypes.GET_USER_STATUS: {
            return {...state, isLoading: true};
        }
        case AuthActionTypes.UPDATE_USER_STATUS: {
            return {
                ...state,
                ...action.payload,
            };
        }

        case AuthActionTypes.AUTH_GET_INFO_BY_CODE: {
            return {...state, isLoading: true};
        }

        case AuthActionTypes.GET_USER_STATUS_SUCCESS: {
            const newState = {
                ...state,
                isLoading: false,
                isSignedIn: action.payload.isSignedIn,
                name: action.payload.name,
                familyName: action.payload.familyName,
                id: action.payload.id,
                email: action.payload.email,
                intercomUserId: action.payload.intercomUserId,
                onboardStatus: action.payload.onboardStatus,
                hasStores: action.payload.hasStores,
                isMainMenuPinned: action.payload.isMainMenuPinned,
                brandView: action.payload.brandView,
            };

            if (action.payload.canUseAdminDashboard) {
                newState.canUseAdminDashboard = action.payload.canUseAdminDashboard;
            }
            return newState;
        }

        case AuthActionTypes.GET_USER_STATUS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                isSignedIn: action.payload.isSignedIn,
                signInUrl: action.payload.signInUrl,
                signUpUrl: action.payload.signUpUrl,
                name: '',
                familyName: '',
            };
        }

        case AuthActionTypes.LOGOUT: {
            if (ENV_VARS.intercomAppId) {
                window.Intercom('shutdown');
            }
            return {
                ...initialState,
                isLoading: false,
            };
        }

        default:
            return state;
    }
};

export default AuthReducer;
