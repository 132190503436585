import {useEffect} from 'react';
import {message} from 'antd';
import {clearServerErrors, markServerErrorRead} from 'src/redux/global/actions';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'src/redux/rootReducer';
import {ServerError} from 'src/redux/global/actionTypes';
import _ from 'lodash';

interface Props {
    className?: string
    fill?: boolean
    size?: number
    inline?: boolean
    loadingText?: string
}


const RootError: React.FC<Props> = () => {
    const serverErrors = useSelector((state: RootState) => state.global.serverErrors);
    const dispatch = useDispatch();

    useEffect(() => {
        if (serverErrors && serverErrors.length) {
            const serverErrorsToShow = _.filter(
                serverErrors,
                (error: ServerError) => Boolean(error.config.showDefaultError)
            );

            for (const serverError of serverErrorsToShow) {
                message.error(serverError.combinedMessage, 4, () => {
                    dispatch(markServerErrorRead(serverError.key));
                });
            }
            dispatch(clearServerErrors());
        }

    }, [dispatch, serverErrors]);

    return <span />;

};

export default RootError;

