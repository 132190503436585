import {Props as PromptModalProps} from 'src/Components/ModalPrompt';

export enum GlobalActionTypes {
    PROMPT_MODAL_OPTIONS = 'PROMPT_MODAL_OPTIONS',
    SERVER_ERRORS = 'SERVER_ERRORS',
    CLEAR_SERVER_ERRORS = 'CLEAR_SERVER_ERRORS',
    MARK_SERVER_ERROR_READ = 'MARK_SERVER_ERROR_READ'
}

export interface ServerError {
    config?: any,
    key?: string
    read?: boolean
    errors?: any[],
    combinedMessage?: string,
}

interface PromptModalOption {
    promptModalOptions: PromptModalProps
}

export interface ServerErrors {
    type: GlobalActionTypes.SERVER_ERRORS
    payload: ServerError
}

export interface ClearServerErrors {
    type: GlobalActionTypes.CLEAR_SERVER_ERRORS
    payload: ServerError
}

export interface MarkServerErrorRead {
    type: GlobalActionTypes.MARK_SERVER_ERROR_READ
    payload: ServerError
}

export interface PromptModalOptions {
    type: GlobalActionTypes.PROMPT_MODAL_OPTIONS
    payload: PromptModalOption
}

export interface GlobalState {
    serverErrors: ServerError[]
    promptModalOptions: PromptModalProps
}

export type GlobalActions = ServerErrors | ClearServerErrors | MarkServerErrorRead | PromptModalOptions;
