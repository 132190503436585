import React from 'react';
import {NormalizedTag} from 'src/Types/Tags/types';
import {Select} from 'src/Components/Select';
import {genericFn} from 'src/Types/CommonTypes';
import {Tag} from 'src/api/Tags/api-tags';

interface Props {
    tags: NormalizedTag[]
    values: NormalizedTag[] | Tag[]
    className?: string
    placeholder?: string
    name?: string
    onChange?: genericFn
    disabled?: boolean
    notFoundContent?: React.ReactNode
    multiple?: boolean
    expectScroll?: boolean
}

const TagSelect: React.FC<Props> = ({tags, values, disabled, notFoundContent, ...props}) => {
    const isDisabled = disabled || !tags;
    return (
        <Select
            {...props}
            notFoundContent={notFoundContent}
            labelKey='title'
            valueKey='id'
            mode='tags'
            options={tags}
            disabled={isDisabled}
            value={values} />
    );
};

export default TagSelect;
